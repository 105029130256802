import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  savingsAccount: {},
  checkingAccount: {},
  signUpAutomationId: "",
  maxValue: 0,
  isModifyFlow: false,
  modifyAutomationId: "",
  selectedAutomation: {},
  disabledAutomation: [],
};

export const sliceuserAutomation = createSlice({
  name: "userAutomation",
  initialState: initialState,
  reducers: {
    setCheckingAcc: (state, { payload }) => {
      state.checkingAccount = payload;
    },
    setSavingsAcc: (state, { payload }) => {
      state.savingsAccount = payload;
    },
    setBalanceMaxValue: (state, { payload }) => {
      state.maxValue = payload;
    },
    setDisabledAutomation: (state, { payload }) => {
      state.disabledAutomation = payload;
    },
    resetDisabledAutomation: (state) => {
      state.disabledAutomation = [];
    },
    resetUserAutomation: (state) => {
      (state.savingsAccount = {}),
        (state.checkingAccount = {}),
        (state.maxValue = 0),
        (state.signUpAutomationId = "");
    },
    setModifyFlow: (state) => {
      state.isModifyFlow = true;
    },
    resetModifyFlow: (state) => {
      state.isModifyFlow = false;
      state.modifyAutomationId = "";
    },
    setModifyAutomationId: (state, { payload }) => {
      state.modifyAutomationId = payload;
    },
    setSignUpAutomationId: (state, { payload }) => {
      state.signUpAutomationId = payload;
    },
    setSelectedAutomation: (state, { payload }) => {
      state.selectedAutomation = payload;
    },
    resetSelectedAutomation: (state) => {
      state.selectedAutomation = {};
    },
  },
});

export const {
  setSavingsAcc,
  setCheckingAcc,
  setBalanceMaxValue,
  resetUserAutomation,
  setModifyFlow,
  setModifyAutomationId,
  setSignUpAutomationId,
  resetModifyFlow,
  setSelectedAutomation,
  resetSelectedAutomation,
  setDisabledAutomation,
  resetDisabledAutomation,
} = sliceuserAutomation.actions;
export default sliceuserAutomation.reducer;
