import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyGetUserRegistrationStepQuery } from "../../../api/apiAuth";
import { AuthContext } from "../../../context/AuthContext";
// @ts-ignore
import { setFirstEnter } from "../../../redux/sliceNotifications";
import { useDispatch } from "react-redux";
import { setIsShowScannerLoader } from "../../../redux/sliceAppState";
import { REGISTRATION_STEPS } from "../../../helpers/redirectToRegistrationStep";
import { api } from "../../../api/api";
import {useMixpanel} from "../../../helpers/mixpanel";

const SignUpVerifyAccount = () => {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanel();
  const [getRegistrationStep, registrationStepData] =
    useLazyGetUserRegistrationStepQuery();

  const shouldStopRequestsRef = useRef(false);

  const USER_MESSAGES = {
    VERIFICATION_MESSAGE_ONE:
      "Verifying your account is taking longer than expected. Please do not refresh, go back, or close this window.",
    VERIFICATION_MESSAGE_TWO:
      "We’re still gathering information to verify your account. We’ll email you once we have an update. Feel free to navigate or to close this window.",
  };

  const timerConfigurations = [
    { durationSec: 7, attempts: 9, message: null },
    { durationSec: 10, attempts: 6, message: null },
    {
      durationSec: 15,
      attempts: 8,
      message: USER_MESSAGES.VERIFICATION_MESSAGE_ONE,
    },
    {
      durationSec: 25,
      attempts: 7,
      message: USER_MESSAGES.VERIFICATION_MESSAGE_TWO,
    },
  ];

  useEffect(() => {
    dispatch(api.util.resetApiState());
  }, []);

  useEffect(() => {
    if (registrationStepData?.data?.step === REGISTRATION_STEPS.COMPLETED) {
      mixpanel("KYC_passed");
      dispatch(setFirstEnter(true));
      shouldStopRequestsRef.current = true;
      navigation("/automations");
      return;
    }
    if (
      registrationStepData?.data?.step ===
      REGISTRATION_STEPS.VERIFY_DWOLLA_RETRY
    ) {
      mixpanel("KYC_first_fail");
      shouldStopRequestsRef.current = true;
      navigation("/signup-retry-verify");
      return;
    }
    if (
      registrationStepData?.data?.step ===
      REGISTRATION_STEPS.VERIFY_DWOLLA_FAILED
    ) {
      mixpanel("KYC_second_fail");
      shouldStopRequestsRef.current = true;
      navigation("/signup-unable-verify");
      return;
    }
    if (
      registrationStepData?.data?.step ===
      REGISTRATION_STEPS.VERIFY_DWOLLA_PENDING
    ) {
      return;
    }
    if (registrationStepData?.isError || registrationStepData.data) {
      shouldStopRequestsRef.current = true;
      navigation("/signup");
      return;
    }
  }, [registrationStepData]);

  useEffect(() => {
    dispatch(setIsShowScannerLoader(true));

    const checkVerification = async () => {
      const { user } = context;
      const idToken = await user?.getIdToken();

      for (const { durationSec, attempts, message } of timerConfigurations) {
        if (shouldStopRequestsRef.current) {
          console.log("Condition met. Terminating loop and clearing timer.");
          break;
        }
        for (let i = 0; i < attempts; i++) {
          if (shouldStopRequestsRef.current) {
            console.log("Condition met. Terminating loop and clearing timer.");
            break;
          }

          await new Promise(async (resolve) => {
            setTimeout(resolve, durationSec * 1000);
            await getRegistrationStep({ idToken }, false);
          });

          console.log(`Checking for updates: Attempt ${i + 1}/${attempts}`);
        }

        if (message) {
          console.log(message);
        }
      }
    };

    checkVerification();

    return () => {
      shouldStopRequestsRef.current = true;
    };
  }, []);

  return <></>;
};

export default SignUpVerifyAccount;
