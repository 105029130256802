import { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from "../Button/Button";
import closeBtn from "../../assets/closeBtn.png";
// @ts-ignore
import PasswordInput from "../PasswordInput/PasswordInput";
import {useMixpanel} from "../../helpers/mixpanel";

function AuthPopUp({ handleClose, open, reAuthUser, callBack }: any) {
    const [userPassword, setUserPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState("");
    const mixpanel = useMixpanel();
    const [formError, setFormError] = useState({
        password: false
    });
    const resetErrorOnChange = () => {
        if (errorMsg) {
            setFormError({
                password: false
            });
            setErrorMsg("");
        }
    }

    const onAuthClick = async () => {
        try {
            const result = await reAuthUser(userPassword);
            setErrorMsg('');
            if (result) {
                handleClose();
                callBack();
            }
        } catch (err: any) {
            if (['auth/wrong-password', 'auth/missing-password'].includes(err.code)) {
                console.log("Auth popup: The password that you entered is incorrect ", err);
                mixpanel('Error', {'message': err?.message});
                setFormError({
                    password: true
                });
                setErrorMsg('The password that you entered is incorrect.');
            } else if (err.code === "auth/too-many-requests") {
                console.log("Auth popup: Too many attempts, please try later ", err);
                mixpanel('Error', {'message': err?.message});
                setFormError({
                    password: true
                });
                setErrorMsg("Too many attempts, please try later.");
            }
        }
    };

    return (
        <Dialog onClose={handleClose} open={open} classes={{root: "reauth-dialog", paper: "reauth-dialog-paper"}}>
            <DialogContent>
                <div className="closeBtn">
                    <img src={closeBtn} alt="close button" onClick={handleClose} />
                </div>
                <div className="auth-title">Password Confirmation.</div>
                <div className="auth-text">Enter your password to continue with this action.</div>
                <div className={`customInput__holder`}>
                    <PasswordInput
                        userPassword={userPassword}
                        onChange={(e: any) => {
                            setUserPassword(e.target.value);
                            resetErrorOnChange();
                        }}
                        passwordError={formError.password}
                        showBottomValidation={false}
                    />
                </div>
                {errorMsg ? <div className="auth-error">{errorMsg}</div> : null}
                <DialogActions className="auth-actions">
                    <Button
                        onClick={onAuthClick}
                        type={"white-btn"}
                        style={{
                            color: "var(--gray40)",
                            backgroundColor: "var(--primary-color)",
                            border: "1px solid var(--primary-color)",
                        }}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </DialogContent>

        </Dialog>
    );
}

export default AuthPopUp;