import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import "./input.css";

const TextInput = styled(({style, ...rest}: TextFieldProps) => {
    return (
        <TextField
            type="text"
            variant="filled"
            InputProps={{
                disableUnderline: true,
            }}
            style={{
                height: 52,
                ...style
            }}
            {...rest}
        />
    )
})(({ theme }) => ({
    '& .MuiFilledInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#303030',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        border: '1px solid #303030',
        '& .MuiInputBase-input': {
            paddingLeft: 16,
            paddingBottom: 16
        },
        '&:hover': {
            backgroundColor: '#303030',
        },
        '&.Mui-focused': {
            backgroundColor: '#303030',
            border: '1px solid #39848a'
        },
        '&.Mui-disabled': {
            backgroundColor: '#303030'
        },
        '&.Mui-disabled input': {
            color: '#707070'
        },
        '&.Mui-error': {
            backgroundColor: '#303030',
            border: '1px solid #F66'
        },
        '& input::placeholder': {
            opacity: 1,
            color: '#FAFAFA'
        }
    },
    '& .MuiInputLabel-root': {
        color: '#eeeeee',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        left: 4,
        '&.MuiInputLabel-shrink': {
            fontSize: '8px',
            transform: 'translate(12px, 7px) scale(1)'
        },
        '&.Mui-focused': {
            color: '#eeeeee',
        },
        '&.Mui-error': {
            color: '#eeeeee',
        },
        '&.Mui-disabled': {
            color: '#707070'
        },
    },
}));

export default TextInput;
