const getFormattedTransferDate = (isoDateString: string) => {
  if (!isoDateString) {
    return "-";
  }
  const inputDate = new Date(isoDateString);
  const inputDateString = inputDate.toISOString().split("T")[0];

  const currentUTCDate = new Date(new Date().toUTCString());
  const currentUTCDateString = currentUTCDate.toISOString().split("T")[0];

  const isToday = inputDateString === currentUTCDateString;

  if (isToday) {
    return "Today";
  }

  const month = String(inputDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(inputDate.getUTCDate()).padStart(2, "0");
  const year = String(inputDate.getUTCFullYear()).slice(-2);

  return `${month}/${day}/${year}`;
};

const getTransferDirectionInfo = ({
  direction,
}: {
  direction: 0 | 1;
}): { from: string; to: string } => {
  if (direction === 1) {
    return {
      from: "Checking",
      to: "Savings",
    };
  }

  return {
    from: "Savings",
    to: "Checking",
  };
};

export { getFormattedTransferDate, getTransferDirectionInfo };
