import "./TransfersTable.css";
import status_done from "../../assets/status_done.svg";
import status_pending from "../../assets/status_pending.svg";
import status_error from "../../assets/status_error.svg";
import {
  getFormattedTransferDate,
  getTransferDirectionInfo,
} from "../../helpers/transfersUtils";
import { Tooltip } from "@mui/material";

function TransfersTable({ transfersData }: any) {
  function getStatusIcon(status: string) {
    let statusIcon;
    switch (status) {
      case "canceled":
        statusIcon = status_error;
        break;
      case "failed":
        statusIcon = status_error;
        break;
      case "completed":
        statusIcon = status_done;
        break;
      case "pending":
        statusIcon = status_pending;
        break;
      default:
        statusIcon = status_pending;
    }
    return statusIcon;
  }

  function getToolTipText(status: string) {
    switch (status) {
      case "canceled":
        return "Transfer incomplete. A member of our team will contact you shortly.";
      case "failed":
        return "Transfer incomplete. A member of our team will contact you shortly.";
      case "completed":
        return "Transfer complete.";
      case "draft":
      case "pending":
        return "Transfer in progress.";
      case "pending_incoming":
        return "Transfer in progress.";
    }
    return "";
  }

  function getToolTipWidth(status: string) {
    switch (status) {
      case "canceled":
      case "failed":
        return 288;
      case "completed":
        return 124;
      case "pending":
      case "draft":
        return 134;
    }
    return 288;
  }

  return (
    <>
      {transfersData?.length !== 0 && (
        <div className="table__container">
          <div className="title">Latest Transfers</div>
          <div className="tableBlock">
            <div className="table__holder">
              <div className="table__data">
                <div className="header__row__table">
                  <span>Date</span>
                  <span>From</span>
                  <span>To</span>
                  <span>Amount</span>
                </div>
                {transfersData.map(
                  ({
                    status,
                    createdAt,
                    amount,
                    direction,
                  }: {
                    status: string;
                    createdAt: string;
                    amount: number;
                    direction: 0 | 1;
                  }) => {
                    const { from, to } = getTransferDirectionInfo({
                      direction,
                    });
                    return (
                      <div className="row__table" key={createdAt}>
                        <span className="row_icon">
                          <Tooltip
                            title={getToolTipText(status)}
                            arrow
                            enterTouchDelay={0}
                            placement="bottom"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  textAlign: "left",
                                  width: `${getToolTipWidth(status)}px`,
                                  color: "#FAFAFA",
                                },
                              },
                            }}
                          >
                            <img
                              src={getStatusIcon(status)}
                              className="status__icon"
                              alt="Done Icon"
                            />
                          </Tooltip>
                          {getFormattedTransferDate(createdAt)}
                        </span>
                        <span>{from}</span>
                        <span>{to}</span>
                        <span>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(amount)}
                        </span>
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TransfersTable;
