import Button from "../../../elements/Button/Button";
import "./signUpVerifyAccountForm.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  updateInfo,
  changeSsnSix,
  changeSsnSeven,
  changeSsnEight,
  changeSsnNine,
} from "../../../redux/sliceAuth";
import { useNavigate } from "react-router-dom";
import { StepWizardChildProps } from "react-step-wizard";
import error_icon from "../../../assets/error_icon.png";
import Address from "../../Address/Address";
import {
  useRetryVerificationUserMutation,
} from "../../../api/apiAuth";
import Wrapper from "../../../elements/Wrapper";
import { AuthContext } from "../../../context/AuthContext";
// @ts-ignore
import DateBirthField from "../SignUpDateBirth/DateBirthField";
import dayjs, { Dayjs } from "dayjs";
import AnimationScan from "../../../elements/AnimationScan/AnimationScan";
import TextInput from "../../../elements/Input/TextInput";

interface VerificationForm {
  firstName: string;
  lastName: string;
  date: Dayjs | null;
  address: string;
  apartment: string;
  city: string;
  zipCode: string;
  state: string;
}

const SignUpVerifyAccountForm: React.FC<Partial<StepWizardChildProps>> = () => {
  const context = useContext(AuthContext);
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const ssnOneRef = useRef<any>(null);
  const ssnTwoRef = useRef<any>(null);
  const ssnThreeRef = useRef<any>(null);
  const ssnFourRef = useRef<any>(null);
  const ssnFiveRef = useRef<any>(null);
  const ssnSixRef = useRef<any>(null);
  const ssnSevenRef = useRef<any>(null);
  const ssnEightRef = useRef<any>(null);
  const ssnNineRef = useRef<any>(null);

  const [ssnOne, setSsnOne] = useState<string | null>("");
  const [ssnTwo, setSsnTwo] = useState<string | null>("");
  const [ssnThree, setSsnThree] = useState<string | null>("");
  const [ssnFour, setSsnFour] = useState<string | null>("");
  const [ssnFive, setSsnFive] = useState<string | null>("");
  const [ssnSix, setSsnSix] = useState<string | null>("");
  const [ssnSeven, setSsnSeven] = useState<string | null>("");
  const [ssnEight, setSsnEight] = useState<string | null>("");
  const [ssnNine, setSsnNine] = useState<string | null>("");

  const [formError, setFormError] = useState({
    firstName: false,
    lastName: false,
    date: false,
    ssn: false,
    address: false,
    apartment: false,
    city: false,
    zipCode: false,
    state: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [isStreet, setIsStreet] = useState(!!authInfo?.street);
  const [formData, setFormData] = useState<VerificationForm>({
    firstName: "",
    lastName: "",
    date: null,
    address: "",
    apartment: "",
    city: "",
    zipCode: "",
    state: "",
  });
  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  const stateRegex =
    /^(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])$/;
  const nameRegex = /^(?![\uD800-\uDFFF].*$)[a-zA-Z-' ]+[a-zA-Z']?$/;

  const [retryVerification, retryVerificationResult] =
    useRetryVerificationUserMutation();

  const userData: any = useSelector<RootState>(
    (state) => state?.userNotifications?.userData,
  );

  const isSsnValid =
    ssnOne &&
    ssnTwo &&
    ssnThree &&
    ssnFour &&
    ssnFive &&
    ssnSix &&
    ssnSeven &&
    ssnEight &&
    ssnNine;

  const resetSsn = () => {
    dispatch(changeSsnSix(""));
    dispatch(changeSsnSeven(""));
    dispatch(changeSsnEight(""));
    dispatch(changeSsnNine(""));
  };

  const resetErrorOnChange = () => {
    if (errorMsg) {
      setFormError({
        firstName: false,
        lastName: false,
        date: false,
        ssn: false,
        address: false,
        apartment: false,
        city: false,
        zipCode: false,
        state: false,
      });
      setErrorMsg("");
    }
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    const dateValue = authInfo.birthday ? dayjs(authInfo.birthday) : null;
    setFormData({
      firstName: authInfo?.firstName,
      lastName: authInfo?.lastName,
      date: dateValue,
      address: authInfo?.address || authInfo?.street,
      apartment: authInfo?.apartment || "",
      city: authInfo?.city,
      zipCode: authInfo?.zipCode,
      state: authInfo?.state,
    });
  }, [authInfo]);

  useEffect(() => {
    resetSsn();
  }, []);

  useEffect(() => {
    if (!authInfo.email) {
      if (userData) {
        const {
          address,
          street,
          apartment,
          birthday,
          city,
          email,
          firstName,
          lastName,
          state,
          zipCode,
        } = userData;

        const birthdayDate = birthday ? new Date(birthday) : "";

        dispatch(
          updateInfo({
            email,
            address,
            street,
            apartment,
            birthday,
            birthdayDay: birthdayDate ? birthdayDate.getDate() : "",
            birthdayMonth: birthdayDate ? birthdayDate.getMonth() + 1 : "",
            birthdayYear: birthdayDate ? birthdayDate.getFullYear() : "",
            city,
            firstName,
            lastName,
            state,
            zipCode,
          }),
        );
      }
    }
  }, [userData]);

  const getDateValidationObject = (value: any) => {
    const validationObject = {
      valid: false,
      msg: "Please complete all required fields.",
    };
    const birthDate = new Date(value);
    const currentDate = new Date();
    const birthYear = birthDate.getFullYear();
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();
    let age = currentYear - birthYear;
    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth && currentDay < birthDay)
    ) {
      age--;
    }
    if (age < 18) {
      validationObject.msg = "You must be at least 18 years old to use Cache.";
    } else if (birthYear <= 1900 || !dayjs(value).isValid()) {
      validationObject.msg = "Please complete all required fields.";
    } else {
      validationObject.valid = true;
      validationObject.msg = "";
    }
    return validationObject;
  };

  const getInvalidFields = (formData: any) => {
    const fields = [];
    for (let key in formData) {
      if (["apartment", "ssn", "date"].includes(key)) {
        continue;
      }
      if (!formData[key]) {
        fields.push(key);
      } else if (
        (key === "zipCode" && !zipRegex.test(formData["zipCode"])) ||
        (key === "state" && !stateRegex.test(formData["state"])) ||
        (key === "firstName" && formData["firstName"].length > 40) ||
        (key === "lastName" && formData["lastName"].length > 40)
      ) {
        fields.push(key);
      } else if (key === "date") {
        const dateValidationObject = getDateValidationObject(formData["date"]);
        if (!dateValidationObject.valid) {
          fields.push(key);
        }
      }
    }
    return fields;
  };

  const onNext = () => {
    const invalidFields = getInvalidFields(formData);
    if (invalidFields.length > 0 || !isSsnValid) {
      const dateValidationObject = getDateValidationObject(formData["date"]);
      setErrorMsg(
        dateValidationObject.msg || "Please complete all required fields.",
      );
      setFormError({
        firstName: invalidFields.includes("firstName"),
        lastName: invalidFields.includes("lastName"),
        date: !dateValidationObject.valid,
        ssn: !isSsnValid,
        address: invalidFields.includes("address"),
        apartment: false,
        city: invalidFields.includes("city"),
        zipCode: invalidFields.includes("zipCode"),
        state: invalidFields.includes("state"),
      });
    } else {
      const { user } = context;
      const dateF = dayjs(formData.date).format("MM/DD/YYYY");
      const [birthdayMonth, birthdayDay, birthdayYear] = dateF.split("/");
      const birthdayFormat = new Date(
        Number(birthdayYear),
        Number(birthdayMonth) - 1,
        Number(birthdayDay),
      ).toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      const addressFieldName = isStreet ? "street" : "address";

      const body = {
        ssn: `${ssnOne}${ssnTwo}${ssnThree}${ssnFour}${ssnFive}${ssnSix}${ssnSeven}${ssnEight}${ssnNine}`,
        birthday: birthdayFormat,
        [addressFieldName]: formData.address,
        apartment: formData.apartment,
        city: formData.city,
        zipCode: formData.zipCode,
        state: formData.state,
        firstName: formData.firstName,
        lastName: formData.lastName,
      };

      user
        ?.getIdToken()
        .then(async (tkn) => {
          await retryVerification({ tkn, body })
            .then((res: any) => {
              if (res.error) {
                console.log(
                  "Error in retryVerification SignUpVerifyAccountForm.tsx: ",
                  res.error,
                );
              } else {
                dispatch(changeSsnSix(ssnSix));
                dispatch(changeSsnSeven(ssnSeven));
                dispatch(changeSsnEight(ssnEight));
                dispatch(changeSsnNine(ssnNine));
                navigation("/verify-account");
              }
            })
            .catch((err) => {
              console.log("Error while verification data", err);
            });
        })
        .catch((err) => {
          console.log("Error while getting token", err);
        });
    }
  };

  const re = /\b([0-9])\b/;

  return (
    <Wrapper>
      <>
        <div className="errorIcon">
          <img src={error_icon} alt="error icon" />
        </div>
        <div className="verifyAccount__title">
          We couldn’t verify your information. Please review.
        </div>

        <div className="verifyAccount__subtitle">
          Please enter your full social security number and confirm that all
          other information is entered correctly.
        </div>
        <div className="verifyAccount__form">
          <div className={`ssnContainer ${formError.ssn ? "error" : ""}`}>
            <div
              className={`ssnContainer__label ${formError.ssn ? "error" : ""}`}
            >
              SSN
            </div>
            <div className="ssnContainer__left">
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                maxLength={1}
                value={ssnOne || ""}
                ref={ssnOneRef}
                pattern="[0-9]"
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnOne(e.target.value);
                    resetErrorOnChange();
                    if (e.target.value.length) ssnTwoRef?.current?.focus();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                maxLength={1}
                value={ssnTwo || ""}
                ref={ssnTwoRef}
                pattern="[0-9]"
                onKeyDown={(e) => {
                  if (e.key == "Backspace" && !ssnTwo?.length) {
                    ssnOneRef?.current?.focus();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnTwo(e.target.value);
                    resetErrorOnChange();
                    if (e.target.value.length) ssnThreeRef?.current?.focus();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                maxLength={1}
                value={ssnThree || ""}
                ref={ssnThreeRef}
                pattern="[0-9]"
                onKeyDown={(e) => {
                  if (e.key == "Backspace" && !ssnThree?.length) {
                    ssnTwoRef?.current?.focus();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnThree(e.target.value);
                    resetErrorOnChange();
                    if (e.target.value.length) ssnFourRef?.current?.focus();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
            </div>
            <div className="ssnContainer__center">
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                maxLength={1}
                value={ssnFour || ""}
                ref={ssnFourRef}
                pattern="[0-9]"
                onKeyDown={(e) => {
                  if (e.key == "Backspace" && !ssnFour?.length) {
                    ssnThreeRef?.current?.focus();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnFour(e.target.value);
                    resetErrorOnChange();
                    if (e.target.value.length) ssnFiveRef?.current?.focus();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                maxLength={1}
                value={ssnFive || ""}
                ref={ssnFiveRef}
                pattern="[0-9]"
                onKeyDown={(e) => {
                  if (e.key == "Backspace" && !ssnFive?.length) {
                    ssnFourRef?.current?.focus();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnFive(e.target.value);
                    resetErrorOnChange();
                    if (e.target.value.length) ssnSixRef?.current?.focus();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
            </div>
            <div className="ssnContainer__right">
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                maxLength={1}
                value={ssnSix || ""}
                ref={ssnSixRef}
                pattern="[0-9]"
                onKeyDown={(e) => {
                  if (e.key == "Backspace" && !ssnSix?.length) {
                    ssnFiveRef?.current?.focus();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnSix(e.target.value);
                    resetErrorOnChange();
                    if (e.target.value.length) ssnSevenRef?.current?.focus();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                maxLength={1}
                value={ssnSeven || ""}
                ref={ssnSevenRef}
                pattern="[0-9]*"
                onKeyDown={(e) => {
                  if (e.key == "Backspace" && !ssnSeven?.length) {
                    ssnSixRef?.current?.focus();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnSeven(e.target.value);
                    resetErrorOnChange();
                    if (e.target.value.length) ssnEightRef?.current?.focus();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                maxLength={1}
                value={ssnEight || ""}
                ref={ssnEightRef}
                pattern="[0-9]*"
                onKeyDown={(e) => {
                  if (e.key == "Backspace" && !ssnEight?.length) {
                    ssnSevenRef?.current?.focus();
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnEight(e.target.value);
                    resetErrorOnChange();
                    if (e.target.value.length) ssnNineRef?.current?.focus();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
              <input
                className={`ssnHiddenInput`}
                placeholder="-"
                value={ssnNine || ""}
                maxLength={1}
                ref={ssnNineRef}
                pattern="[0-9]*"
                onKeyDown={(e) => {
                  if (e.key == "Backspace" && !ssnNine?.length) {
                    ssnEightRef?.current?.focus();
                    setSsnNine("");
                  }
                }}
                onChange={(e) => {
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setSsnNine(e.target.value);
                    resetErrorOnChange();
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
            </div>
          </div>
          <div className={`customInput__holder`}>
            <TextInput
              fullWidth
              label="First Name"
              name="userFirstName"
              error={formError.firstName}
              value={formData.firstName}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "" || nameRegex.test(value)) {
                  setFormData((prevState: any) => {
                    return {
                      ...prevState,
                      firstName: value,
                    };
                  });
                }
                resetErrorOnChange();
              }}
            />
          </div>
          <div className={`customInput__holder`}>
            <TextInput
              fullWidth
              label="Last Name"
              name="userLastName"
              error={formError.lastName}
              value={formData.lastName}
              onChange={(e) => {
                const value = e.target.value;
                if (value === "" || nameRegex.test(value)) {
                  setFormData((prevState: any) => {
                    return {
                      ...prevState,
                      lastName: value,
                    };
                  });
                }
                resetErrorOnChange();
              }}
            />
          </div>
          <div className=" customInput__holder">
            <DateBirthField
              fullWidth
              variant="filled"
              name="dateOfBirth"
              label="Date of birth"
              onChange={(value: any) => {
                setFormData((prevState: any) => {
                  return {
                    ...prevState,
                    date: value,
                  };
                });
                resetErrorOnChange();
              }}
              value={formData.date}
              error={formError.date}
              placeholder="Date of birth"
            />
          </div>
          <Address
            showFormAlways={true}
            isStreet={isStreet}
            setIsStreet={setIsStreet}
            formError={formError}
            formData={formData}
            setFormData={setFormData}
            resetErrorOnChange={resetErrorOnChange}
          />
          {errorMsg ? (
            <div className="email__errorMassage error__message">
              {errorMsg || "Please complete all required fields."}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="verifyAccount__form-buttonHolder">
          <Button
            type="primary-btn"
            onClick={() => onNext()}
            disabled={retryVerificationResult.isLoading}
          >
            Next
          </Button>
        </div>
      </>
      <input type="hidden" id="abandonedOnboardingStage" value={"kyc_fail1"} />
    </Wrapper>
  );
};

export default SignUpVerifyAccountForm;
