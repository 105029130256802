import { Box, Container, Grid, Typography } from "@mui/material";
import { ReactElement } from "react";

type SubHeaderProp = {
  title_1: string;
  title_2: string;
  subtitle: ReactElement | string;
};
const SubHeader = ({ title_1 = "", title_2 = "", subtitle }: SubHeaderProp) => {
  return (
    <>
      <Box className="landing">
        <Box className="blackBox">
          <Container>
            <Grid container alignItems="center" columnSpacing={0}></Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h1" className="landing_title2">
                {title_1}
                <Box component="span" className="primaryTextColor">
                  {title_2}
                </Box>
              </Typography>
              {typeof subtitle === "string" ? <p>{subtitle}</p> : subtitle}
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
};
export default SubHeader;
