import { useEffect } from "react";
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkStableEvent,
  usePlaidLink,
} from "react-plaid-link";

function PlaidPopup({
  linkToken,
  onPlaidSuccess,
  onPlaidExit,
  onPlaidEvent,
  onPlaidLoad,
}: {
  linkToken: string;
  onPlaidSuccess: (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata,
  ) => void;
  onPlaidExit?: (
    error: PlaidLinkError | null,
    metadata: PlaidLinkOnExitMetadata,
  ) => void;
  onPlaidEvent?: (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata,
  ) => void;
  onPlaidLoad?: () => void;
}) {
  //PLAID-LINK HOOK
  const { open, ready, exit } = usePlaidLink({
    token: linkToken,
    onSuccess: (...args) => {
      exit();
      onPlaidSuccess(...args);
    },
    onExit: (...args) => {
      exit();
      if (onPlaidExit) {
        onPlaidExit(...args);
      }
    },
    onEvent: onPlaidEvent,
    onLoad: onPlaidLoad,
  });

  useEffect(() => {
    if (ready && linkToken) {
      open();
    }
  }, [ready, open, linkToken]);

  return <></>;
}

export default PlaidPopup;
