import {Box, Container, Grid, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import SimpleWrapper from "../../elements/SimpleWrapper";

function TermsOfUse() {
    return (
        <SimpleWrapper>
            <Container sx={{ px: '0!important' }}>
                <Grid container marginTop='30px' className="article">
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h2">Cache – Terms of Service</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="justify">
                        <p>
                            PLEASE READ THE FOLLOWING CAREFULLY BEFORE ACCEPTING THESE TERMS,
                            AND/OR ACCESSING AND/OR USING THE SERVICE (DEFINED BELOW). IF YOU
                            ARE ACCEPTING THESE TERMS, AND/OR ACCESSING AND/OR USING THE SERVICE
                            (DEFINED BELOW) AS AN AGENT OR ON BEHALF OF ANY THIRD PARTY, YOU REPRESENT
                            AND WARRANT THAT YOU ARE AUTHORIZED TO DO SO AND THAT BOTH YOU AND THE PARTY
                            WHO YOU ACT ON BEHALF OF AGREE TO BE BOUND JOINTLY AND SEVERALLY BY THESE
                            TERMS OF SERVICE. IF YOU DO NOT ACCEPT THESE TERMS OF SERVICE IN THEIR ENTIRETY,
                            YOU SHOULD NOT USE THE SERVICES.

                        </p>
                        <p>
                            By accepting these terms, or otherwise accessing and using the Service (as defined below),
                            you acknowledge that you (collectively <b>“you”</b> or <b>“your”</b>), have read and
                            understood and agree to comply with the terms and conditions below
                            (<b>“Terms”</b>), and are entering into a binding legal agreement with Cache Inc.
                            (<b>“Cache”</b>, <b>“us”</b>, <b>“we”</b> or <b>“our”</b>). You hereby waive any applicable rights to
                            require an original (non-electronic) signature or delivery or retention of
                            non-electronic records, to the extent not prohibited under applicable law.
                        </p>

                        <p>
                            <ol style={{paddingLeft: '15px'}}>
                                <li>
                                    <p>
                                        <b>Description of the Services.</b> Cache operates a platform which is made available
                                        to you on a software-as-a-service (SaaS) basis through a web portal on
                                        our website: <Link to="https://mycache.ai">mycache.ai</Link>  (<b>“Site”</b>) and includes: (i) the software
                                        that you access via the Site; (ii) the products, services, and features
                                        made available or provided to you by Cache in connection with the Site;
                                        and (iii) the content, text, documents, descriptions, products, graphics,
                                        photos, sounds, videos, interactive features, and the trademarks, service
                                        marks and logos (<b>“Marks”</b>), contained in or made available through the Site
                                        (collectively, with the Pay Services and Free Services, the <b>“Services”</b>).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Eligibility.</b> The Services are intended solely for persons who are 18 or older
                                        and who reside in the United States. Any access to or use of the Services by anyone
                                        under 18 or who does not reside in the United States is unauthorized, unlicensed,
                                        and in violation of these Terms.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Representations and Warranties.</b> By accessing or using the Services, you
                                        represent and warrant that: (i) You are 18 or older, a resident of the
                                        United States; (ii) Your agreement to these Terms will not violate
                                        any other agreement or understanding to which you are a party; and (iii)
                                        You are not currently restricted from using the Services; and (iv) You are
                                        not a competitor of Cache and are not using the Services for reasons that
                                        are in competition with Cache.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Subscription to the Services.</b> Subject to your compliance with these Terms,
                                        Cache hereby grants you, and you accept, a non-exclusive, non-transferable,
                                        non-sublicensable, and fully revocable right to access and use the Services
                                        during the Term (as defined below) for your own purposes or for the
                                        purpose of the person you are acting on behalf of, on a non-commercial basis only.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Linked Accounts and Consent to Certain Services.</b>
                                    </p>
                                    <p>
                                        <ul style={{paddingLeft: 0}}>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>5.1.</Box>
                                                    <Box>
                                                        <u>Linked Accounts</u>. By using the Services and connecting your
                                                        deposit accounts (including checking and savings accounts)
                                                        at third-party financial institutions, such as banks
                                                        (<b>“Linked Accounts”</b>), you consent to Cache storing,
                                                        processing and analyzing information about you and your
                                                        accounts for the purpose of fulfilling the Services
                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>5.2.</Box>
                                                    <Box>
                                                        <u>Cash Transfers</u>. By using the Services, you understand and
                                                        agree that Cache  may send cash transfer instructions to your
                                                        financial institutions (such as banks) on your own behalf for
                                                        the purpose of implementing your preference regarding cash
                                                        allocation across your Linked Accounts. Furthermore, by using
                                                        the Services, you grant Cache permission to automatically send
                                                        such cash transfer instructions on your behalf, based on the
                                                        preference that you have set, and understand that Cache will
                                                        not request specific consent for each transfer.

                                                    </Box>
                                                </Box>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Disclaimer.</b> Cache does not act in any type of financial advisory or
                                        fiduciary capacity for you. By using the Services, you assume all risk related
                                        to your use of the Services and acknowledge that Cache does not and cannot
                                        guarantee or promise any specific results from your use of the Services.
                                        Furthermore, the Services may include information that Cache obtains from
                                        third-party sources, such as financial data providers, and Cache does not
                                        review, endorse or analyze any such third-party information. Cache does not
                                        guarantee the accuracy, completeness, or timeliness of any information displayed
                                        via the Services, and none of the information presented constitutes a solicitation
                                        or offer to open or maintain any financial accounts nor does it constitute an
                                        endorsement of or affiliation with any specific financial institutions.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Non-Banking Status.</b> By using the Services, you understand and acknowledge
                                        that: (i) Cache is not a bank; (ii) Cache is not affiliated with any bank
                                        or other financial institution; (iii) Cache has no legal authority,
                                        contractual or otherwise, to open or close a financial account; and
                                        (iv) Cache is not involved in negotiating or setting rates, fees, terms
                                        or conditions for any financial account.

                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>User Data.</b>
                                    </p>
                                    <p>
                                        <ul style={{paddingLeft: 0}}>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>8.1.</Box>
                                                    <Box>
                                                        <u>User Data</u>. You own and retain all right, title and interest
                                                        (including all intellectual property rights) in and to any
                                                        data or information that you submit, upload, provide, or
                                                        otherwise make available to Cache, either directly or via
                                                        third-party providers, or that originates, resides on, is
                                                        otherwise processed through or derived from your systems or
                                                        submissions and processed by Cache in the provision of the
                                                        Services (<b>“User Data”</b>). You shall be solely responsible for
                                                        the legality, reliability, integrity, accuracy and quality of
                                                        all User Data. You hereby grant to us and to any third party
                                                        service providers acting on our behalf (including but not
                                                        limited to Plaid and Dwolla) a non-exclusive, worldwide,
                                                        royalty-free, sublicensable, transferable, perpetual, and
                                                        irrevocable right and license to use, store, reproduce,
                                                        modify, adapt, and otherwise exploit such User Data in any
                                                        form, or medium, in connection with the provision of the
                                                        Services, and for Cache’s internal business purposes,
                                                        including for providing support, prevention of system
                                                        abuse, maintaining or developing the Service, and statistical
                                                        purposes, as well as to fulfill any legal obligations; provided
                                                        that your personally identifiable information will be used as
                                                        described in the Privacy Policy (defined below).

                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>8.2.</Box>
                                                    <Box>
                                                        <u>Representations and Warranties of the User Data</u>. You represent,
                                                        warrant and covenant that you own the User Data or have all of
                                                        the necessary licenses, rights, consents, and permissions to
                                                        grant the foregoing licenses to Cache.
                                                    </Box>
                                                </Box>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Anonymized Analytics Information.</b> You hereby acknowledge and agree that
                                        Cache may collect and process information regarding the configuration, performance,
                                        security, access to and use of the Services (<b>“Usage Data”</b>).  Any anonymized and/or
                                        aggregated information, which is derived from Usage Data (e.g., metadata,
                                        aggregated, statistics, analytics and/or anonymized information) which does
                                        not in any way identify and cannot be  reasonably associated with you or any
                                        individuals connected to you (<b>“Anonymized Analytics Information”</b>) may be
                                        used by us for providing the Service, and/or for our internal business purposes,
                                        including for providing support, prevention of system abuse, maintaining or
                                        developing the Service, statistical purposes and to fulfill any legal obligations.
                                        Notwithstanding anything to the contrary, Anonymized Analytics Information is our
                                        exclusive property. Such Usage Data does not contain or constitute personal data,
                                        and you hereby agrees that Cache may make Usage Data available in a form that does
                                        not identify you or any individual.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Restrictions on Use.</b> You must not and shall not allow any third party to:
                                        (i) give, sell, provide access, rent, lease, timeshare, sublicense,
                                        disclose, publish, assign, market, transfer or distribute any portion of
                                        the Service to any third party, except as expressly specified under these
                                        Terms; (ii) register to the Services on behalf of any third party without
                                        its consent; (iii) circumvent, disable or otherwise interfere with security-related
                                        features of the Services or features that prevent or restrict use or copying of
                                        any content or that enforce limitations on use of the Services; (iv) reverse
                                        engineer, decompile or disassemble the Services or any components thereof;
                                        (v) modify, translate, patch, alter, change or create any derivative works
                                        of the Services, or any part thereof; (vi) use any robot, spider, scraper,
                                        or other automated means to access the Services for any purpose; (vii) take
                                        any action that imposes or may impose (at Cache’s sole discretion) an unreasonable
                                        or disproportionately large load on Cache’s infrastructure; (viii) interfere or
                                        attempt to interfere with the integrity or proper working of the Services, or
                                        any related activities; (ix) remove, deface, obscure, or alter Cache’s or any
                                        third party’s copyright notices, trademarks, or other proprietary rights affixed
                                        to or provided as part of the Services; (x) use the Services to develop a
                                        competing service or product; (xi) use the information provided on the Services
                                        by, or distribution to, any person or entity in any jurisdiction or country
                                        where such use or distribution would be contrary to law or regulation, and
                                        any such unlawful use or distribution by you shall be a violation of these
                                        Terms; (xii) copy in any form, by any means, or redistributed, published,
                                        circulated or commercially exploited in any manner the information on the
                                        Services without Cache’s prior written consent; (xiii) impersonate or
                                        misrepresent your affiliation with any person or entity, through pretexting
                                        or some other form of social engineering, or commit fraud; (xiv) attempt
                                        to probe, scan, or test the vulnerability of any Cache system or network;
                                        and/or (xv) use the Services in any unlawful manner or in breach of these
                                        Terms, or in breach of any applicable law.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Account.</b>
                                    </p>
                                    <p>
                                        <ul style={{paddingLeft: 0}}>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>11.1.</Box>
                                                    <Box>
                                                        <u>Account and Security</u>. In order to use the Service, you have
                                                        to create an account (<b>“Account”</b>). You are solely responsible for
                                                        determining the complexity of and safeguarding your passwords and
                                                        login details for the Services. If you share or otherwise disclose
                                                        any such password to any third party, they may be able to access
                                                        your Account or Linked Accounts, and you will be solely responsible
                                                        for any consequences of such sharing or disclosure. By using the
                                                        Services, you acknowledge and agree that internet transmissions
                                                        cannot be assured of complete privacy or security. You understand
                                                        that any message or other User Data you send to the Services may
                                                        be read or intercepted by others, even if there is a special
                                                        notice that a particular transmission is encrypted.
                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>11.2.</Box>
                                                    <Box>
                                                        <u>Account Responsibility</u>. You agree that you shall be solely
                                                        responsible for any activities or actions under your Account,
                                                        whether or not you have authorized such activities or actions.
                                                        Although Cache will not be liable for any of your losses that are
                                                        caused by any unauthorized use of your Account, you may be liable
                                                        for the losses of Cache or others due to such unauthorized use.
                                                        You shall immediately notify Cache of any unauthorized use of your
                                                        Account. You agree that the User Data that you provide to us on
                                                        registration and at all other times, and the information you provided
                                                        to any website through which you access the Services or parts
                                                        thereof, will be true, accurate, current, and complete. Cache
                                                        reserves the right to suspend or terminate your Account if any
                                                        User Data provided during the registration process or thereafter
                                                        proves to be inaccurate, not current, or incomplete. You may
                                                        delete your Account at any time via the settings page in the
                                                        Services.
                                                    </Box>
                                                </Box>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Electronic Communications and Marketing Emails.</b> By creating an Account
                                        and/or using the Services, you agree that Cache may electronically provide you
                                        (via email or postings or links on the Services) with documents, notices and
                                        other communications regarding the Services and/or your use thereof, as well
                                        as special offers, promotions, commercial advertisements, marketing materials,
                                        and similar communications. You agree that Cache may send the foregoing
                                        communications to you via your Account or any email address(es) which you
                                        provide to Cache as part of your User Data or otherwise. You will have the
                                        option to opt-out or modify your preferences regarding the acceptance of the
                                        special offers, promotions, commercial advertisements, marketing materials,
                                        and similar communications.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Description of Information.</b> We attempt to be as accurate as possible.
                                        However, we cannot and do not warrant that the content available on the Services
                                        is accurate, complete, reliable, current, or error-free. We reserve the right
                                        to make changes in or to such content, or any part thereof, in our sole judgment,
                                        without the requirement of giving any notice prior to or after making such changes
                                        to such content. Your use of such content, or any part thereof, is made solely at
                                        your own risk and responsibility.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Our Privacy Policy.</b> You acknowledge and agree that your use of the
                                        Services, including, without limitation, any information transmitted
                                        to or stored by Cache, is governed by the Cache Privacy Policy
                                        at <Link to="/privacy-policy">mycache.ai/privacy-policy</Link> (<b>“Privacy Policy”</b>).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Third Party Services.</b>
                                    </p>
                                    <p>
                                        <ul style={{paddingLeft: 0}}>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>15.1.</Box>
                                                    <Box>
                                                        <u>General</u>. The Services may integrate and/or interact with
                                                        third party services, from time to time, such as via APIs
                                                        or browser extensions in order to provide the Services,
                                                        including but not limited to the services provided by Plaid
                                                        and Dwolla  (collectively, <b>“Third Party Services”</b>). Cache has
                                                        no affiliation, association, endorsement, or sponsorship by
                                                        any Third Party Services. Cache makes no claim, representation
                                                        or warranty of any kind, type or nature concerning Cache’s
                                                        compliance with the terms and conditions of the Third Party
                                                        Services, and it shall be your sole responsibility to analyze
                                                        and interpret any applicable third party terms of service
                                                        (such as, without limitation, Plaid and Dwolla’s terms of service)
                                                        (collectively, <b>“Third Party Terms”</b>). You are solely responsible
                                                        for your interpretation of Third Party Terms and your actions
                                                        relevant to compliance thereof. By using the Services, you hereby
                                                        release Cache and waive any and all claims or claim rights that
                                                        you may have against Cache, and release and indemnify Cache against
                                                        any claims that any third party may have against you, including
                                                        with respect to your use of any Third Party Services, including
                                                        if accessed or used via our Services, and with respect to Third
                                                        Party Terms, applicable privacy policies or any other rules or
                                                        regulations of such Third Party Services.

                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>15.2.</Box>
                                                    <Box>
                                                        <u>Dwolla</u>. In order to use the payment functionality of our
                                                        application, you must open a “Dwolla Account” provided by Dwolla,
                                                        Inc. and you must accept the Dwolla Terms of Service
                                                        and Privacy Policy. Any funds held in or transferred
                                                        through your Dwolla Account are held or transferred by Dwolla's
                                                        financial institution partners. You must be at least 18 years
                                                        old to create a Dwolla Account. You authorize us to collect
                                                        and share with Dwolla your personal information including
                                                        full name, date of birth, social security number, physical
                                                        address, email address and financial information, and you
                                                        are responsible for the accuracy and completeness of that
                                                        data. You understand that you will access and manage your
                                                        Dwolla Account through our application, and Dwolla account
                                                        notifications will be sent by us, not Dwolla. We will provide
                                                        customer support for your Dwolla Account activity, and can be
                                                        reached at <Link to="https://www.mycache.ai">mycache.ai</Link> and/or <Link to="mailto:support@mycache.ai">support@mycache.ai.</Link>
                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>15.3.</Box>
                                                    <Box>
                                                        <u>Authentication Services</u>. Without limiting the generality of
                                                        the foregoing, we may elect, in our discretion, to utilize social
                                                        logins, allowing you to login to the Services via other third party
                                                        authentication services, such as (without limitation) your Apple,
                                                        Google, Facebook, Twitter, LinkedIn, or other account credentials.
                                                        You understand that these are Third Party Services, and this in no
                                                        way creates and endorsement of, by or from us to them or vice versa,
                                                        that we are not responsible for their logins, systems or data, and
                                                        that by using such third party logins, you may be subject to their
                                                        respective privacy policies and other terms of service.
                                                    </Box>
                                                </Box>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Ownership.</b> The title and full and exclusive ownership rights of the
                                        Services (and all parts thereof, including without limitation the Site),
                                        and all reproductions, corrections, modifications, enhancements and improvements,
                                        and all related patent rights, copyrights, trade secrets, trademarks, service
                                        marks, related goodwill, including data related to your usage thereof, and
                                        Cache’s intellectual property, and any rights therein not explicitly granted
                                        to you hereunder, are reserved to and shall remain solely and exclusively
                                        proprietary of Cache (or its third party licensors). “Cache”, the Cache Logo,
                                        and other marks are Marks of Cache or its affiliates.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Feedback.</b> It is anticipated that you may provide suggestions, comments or
                                        other feedback to Cache regarding the Services and Cache’s business, including
                                        but not limited to, new features, functionality, techniques or business methods
                                        (<b>“Feedback”</b>). Feedback will not create any confidentiality obligation for
                                        Cache and shall be deemed the sole property of Cache. You hereby assign to
                                        Cache any right to the Feedback and hereby waive any right to the Feedback
                                        including moral rights or the right for royalties.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Term.</b> These Terms shall become effective on the date that you commence
                                        access to or use of the Services, and shall continue to be in effect until
                                        terminated as specified under these Terms (<b>“Term”</b>).
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Termination.</b> You may terminate these Terms at any time by deleting your
                                        Account. We may stop to provide the Services to you, and/or terminate these
                                        Terms at any time at our sole discretion, including without limitation in the
                                        event that you breach these Terms.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Effect of Termination.</b> Upon termination of these Terms, you will lose all
                                        access to the Services and to any data that we may be storing on your behalf
                                        (including User Data). We reserve the right to permanently delete from our
                                        (or our third party service provider’s) servers any data that may be contained
                                        in your Account (including the User Data) at any time for any reason, without
                                        notice, or liability to you. We do not accept any liability for any termination
                                        of the Services or data that is deleted in connection thereto. Sections 6, 8, 10,
                                        11, 12, 13, 16 to 29, shall survive termination of these Terms.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Payment.</b>
                                    </p>
                                    <p>
                                        <ul style={{paddingLeft: 0}}>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>21.1.</Box>
                                                    <Box>
                                                        <u>Free Services and Pay Services</u>. The Services are currently
                                                        accessible free of charge as a trial period (<b>“Free Services”</b>) until
                                                        Cache issues an update amending this provision as specified
                                                        under Section 27. Whether the Services remain Free Services or
                                                        become subject to a payment of fees (<b>“Pay Services”</b>) is  within
                                                        the sole discretion of Cache and Cache may reclassify the
                                                        Free Services as Pay Services at any time.
                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>21.2.</Box>
                                                    <Box>
                                                        <u>Transition to Pay Services</u>. In the event of a transition
                                                        from Free Services to Pay Services, you will be notified as
                                                        specified under Section 27. If you prefer not to transition Free
                                                        Services to Pay Services, your sole and exclusive remedy is to
                                                        cease using the Services, and delete your Account as specified
                                                        under these Terms.
                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>21.3.</Box>
                                                    <Box>
                                                        <u>Additional Terms for the Pay Services</u>. Pay Services may be
                                                        subject to additional terms and conditions that will be provided
                                                        (including by email) to you, prior to the transition to the Pay
                                                        Services (which will include but not limited to payment of fees).
                                                        Your use of such Pay Services will be subject to the Terms and
                                                        any additional terms and conditions to which you agree.
                                                    </Box>
                                                </Box>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Warranty Disclaimer.</b> The Service is provided on an “as is” and “as
                                        available” basis, and without warranties of any kind either express or implied.
                                        You assume all responsibility for the selection of the Services to achieve
                                        your intended results. CACHE HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                                        INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS
                                        FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. CACHE DOES NOT WARRANT THAT THE
                                        SERVICES WILL BE UNINTERRUPTED, ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED.
                                        CACHE DOES NOT OFFER A WARRANTY OR MAKE ANY REPRESENTATION REGARDING ANY CONTENT,
                                        INFORMATION, OR RESULTS THAT YOU OBTAIN THROUGH THE SERVICES. YOUR USE OF AND
                                        RELIANCE ON THE SERVICES, CONTENT, AND YOUR DATA ARE ENTIRELY AT YOUR SOLE
                                        DISCRETION AND RISK, AND CACHE SHALL HAVE NO RESPONSIBILITY OR LIABILITY
                                        WHATSOEVER TO YOU IN CONNECTION WITH ANY OF THE FOREGOING. YOU AGREE THAT WE
                                        WILL NOT BE HELD RESPONSIBLE FOR ANY CONSEQUENCES THAT MAY RESULT FROM TECHNICAL
                                        PROBLEMS, INCLUDING WITHOUT LIMITATION IN CONNECTION WITH THE INTERNET (SUCH AS
                                        SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER SERVERS) OR ANY
                                        TELECOMMUNICATIONS OR INTERNET PROVIDERS. Applicable law may not allow the
                                        exclusion of certain warranties, so to that extent such exclusions may not apply.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Limitation of Liability.</b> TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE
                                        LAW AND NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS: (I) CACHE SHALL
                                        NOT BE LIABLE FOR ANY INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, OR INCIDENTAL
                                        DAMAGES OF ANY KIND (INCLUDING WITHOUT LIMITATION LOST PROFITS OR REVENUE) ARISING
                                        OUT OF THESE TERMS OR THE PRIVACY POLICY OR IN CONNECTION WITH THE USE OF OR
                                        INABILITY TO USE THE SERVICES, OR THE USER DATA, EVEN IF CACHE HAS BEEN ADVISED
                                        OF THE POSSIBILITY OF SUCH DAMAGES; (II) IN NO EVENT SHALL THE AGGREGATE LIABILITY
                                        OF CACHE FOR ANY DAMAGES RESULTING FROM OR IN CONNECTION WITH ANY CAUSE WHATEVER
                                        (INCLUDING NEGLIGENCE), UNDER THESE TERMS, THE PRIVACY POLICY OR IN CONNECTION
                                        WITH THE USE OF OR THE INABILITY TO USE THE SERVICES OR THE USER DATA, EXCEED
                                        THE HIGHER OF: (A) ONE HUNDRED US DOLLARS ($100); AND (B) THE TOTAL AMOUNT OF
                                        SUBSCRIPTION FEES PAID (IF ANY) BY YOU FOR THE SERVICES DURING THE 12 MONTHS
                                        PRIOR TO BRINGING THE CLAIM.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Indemnification.</b> You agree to defend, indemnify and hold harmless Cache
                                        and its affiliates and its respective officers, directors, agents, consultants
                                        and employees from any third party claims, damages, liabilities, costs, and
                                        expenses (including reasonable attorney’s fees) arising from: (i) your use
                                        of the Services; and/or (ii) your breach of these Terms; (iii) your violation
                                        of any third-party right, including without limitation any intellectual
                                        property right, property, or privacy right; and (iv) any claim that your
                                        User Data caused damage to a third-party. Cache reserves the right to assume
                                        the exclusive defense and control of any matter otherwise subject to
                                        indemnification by you, in which event you will assist and cooperate with
                                        Cache in asserting any available defenses. You agree not to settle any such
                                        matter without the prior written consent of Cache. We will use reasonable
                                        efforts to notify you of any such claim, action or proceeding upon becoming
                                        aware of it.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Disclosure.</b> We reserve the right to access, store, read, preserve, and
                                        disclose any information that we obtain in connection with the Services as we
                                        reasonably believe necessary to: (i) satisfy any applicable law, regulation,
                                        legal process, subpoena or governmental request; (ii) enforce these Terms,
                                        including to investigate potential violations hereof; (iii) detect, prevent
                                        or otherwise address fraud, security or technical issues; (iv) respond to
                                        your support requests; and/or (v) protect the rights, property or safety of
                                        Cache, its users or the public.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Assignment.</b> These Terms, and any rights granted hereunder, may not
                                        be transferred or assigned by you but may be assigned by Cache without any
                                        restriction.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Modifications.</b> We reserve the right, at our discretion, to change these
                                        Terms at any time. All changes will be effective upon the date when the change
                                        takes effect, unless there is a material change (including but not limited to
                                        the transition from Free Services to Pay Services) determined in good faith, by
                                        the reasonable discretion of Cache, which will be effective 10 days (unless a
                                        longer notice period is required by applicable law) following our sending a notice
                                        to you or posting the revised Terms on the Site. If any provision of the modified
                                        Terms are not acceptable to you, your sole and exclusive remedy is to cease using
                                        the Services, and delete your Account as specified under these Terms. Your
                                        continued use of the Services thereafter (or after expiration of the notice
                                        period, if applicable) means that you accept those changes. Each version of the
                                        Terms will be identified at the top of the page by the last updated date.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>Governing Law and Jurisdiction.</b> These Terms shall be governed by the laws
                                        of the State of New-York, without reference to its conflict of laws rules.
                                        The exclusive jurisdiction and venue for all disputes hereunder shall be the
                                        courts located in New-York County, New-York, and each party hereby irrevocably
                                        consents to the jurisdiction of such courts. Application of the United Nations
                                        Convention on Contracts for the International Sale of Goods and the Uniform
                                        Computer Information Transaction Act are excluded from these Terms. Notwithstanding
                                        the foregoing, Cache reserves the right to seek injunctive relief in any court of
                                        competent jurisdiction.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b>General.</b>
                                    </p>
                                    <p>
                                        <ul style={{paddingLeft: 0}}>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>29.1.</Box>
                                                    <Box>
                                                        <u>Entire Agreement</u>. These Terms and the Privacy Policy,
                                                        represent the complete and entire agreement concerning the
                                                        Services between you and Cache and supersede all prior agreements
                                                        and representations related to the subject matter hereof. We will
                                                        not be bound by, and specifically object to, any provision that is
                                                        different from or in addition to the provisions of these Terms.
                                                        Section headings are provided for convenience only and have no
                                                        substantive effect on construction.
                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>29.2.</Box>
                                                    <Box>
                                                        <u>Invalidity</u>. If any provision of these Terms is held to
                                                        be unenforceable, these Terms shall be construed without such
                                                        provision, and the invalidity of such provision shall not
                                                        affect the validity of the remaining provisions of these Terms.
                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>29.3.</Box>
                                                    <Box>
                                                        <u>Waiver</u>. No waiver of any term of these Terms shall be
                                                        deemed a further or continuing waiver of such term or any other
                                                        term, and a party's failure to assert any right or provision
                                                        under these Terms shall not constitute a waiver of such right
                                                        or provision.
                                                    </Box>
                                                </Box>
                                            </li>
                                            <li>
                                                <Box display="flex">
                                                    <Box paddingRight={1} paddingLeft={1}>29.4.</Box>
                                                    <Box>
                                                        <u>Notice</u>. Any notice or other communication to be given
                                                        hereunder will be in writing and given by (i) Cache via email
                                                        (to the address that you provide at Account registration or
                                                        otherwise update via the Site); (ii) by Cache via posting on
                                                        the Site or the Services; or (c) by you via email
                                                        to <Link to="mailto:support@mycache.ai">support@mycache.ai</Link> or
                                                        to such other addresses as Cache may expressly specify in writing.
                                                        The date of receipt shall be deemed the date on which such notice
                                                        is transmitted.

                                                    </Box>
                                                </Box>
                                            </li>
                                        </ul>
                                    </p>
                                </li>
                            </ol>
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </SimpleWrapper>
    );
}

export default TermsOfUse;
