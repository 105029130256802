import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

export const ABANDONED_ONBOARDING_DATA: any = {
  name: {
    title: "Name",
    stage: "Name",
  },
  email: {
    title: "Email",
    stage: "Email",
  },
  password: {
    title: "Password",
    stage: "Password",
  },
  consent: {
    title: "Consent",
    stage: "Automation explanation",
  },
  checkingConnect: {
    title: "Connect checking",
    stage: "Bank connection",
  },
  savingsConnect: {
    title: "Savings checking",
    stage: "Bank connection",
  },
  fixedBalance: {
    title: "Set fixed balance",
    stage: "Fixed balance",
  },
  balanceDeficit: {
    title: "Automation set - deficit",
    stage: "Automation set",
  },
  balanceExcess: {
    title: "Automation set - excess",
    stage: "Automation set",
  },
  balanceEqual: {
    title: "Automation set-equal",
    stage: "Automation set",
  },
  kyc_dob: {
    title: "KYC - DOB",
    stage: "KYC",
  },
  kyc_address: {
    title: "KYC-Address",
    stage: "KYC",
  },
  kyc_ssn: {
    title: "KYC-SSN",
    stage: "KYC",
  },
  changeEmail: {
    title: "KYC-CHANGE EMAIL",
    stage: "KYC",
  },
  verify: {
    title: "Verification email",
    stage: "Verification email",
  },
  kyc_fail1: {
    title: "KYC-fail 1",
    stage: "KYC",
  },
  kyc_fail2: {
    title: "KYC-fail 2",
    stage: "KYC",
  },
};

const APRIL_MONTH_NUMBER = 4; // changed for MAY
const LAST_DAY = 31;

export function countdownRemainingDays() {
  // Set timezone to EST
  dayjs.tz.setDefault("America/New_York");

  // Get current date in EST
  const currentDateEST = dayjs().tz();

  const endOfAprilEST = dayjs()
    .month(APRIL_MONTH_NUMBER)
    .date(LAST_DAY)
    .endOf("day");

  // Calculate the difference in days
  return endOfAprilEST.diff(currentDateEST, "day");
}

export const findLastObjByCreatedAt = (objectsList: any): any | null => {
  if (objectsList.length === 0) {
    return null;
  }

  let lastObject = objectsList[0];
  for (const obj of objectsList) {
    if (obj.createdAt > lastObject.createdAt) {
      lastObject = obj;
    }
  }
  return lastObject;
};

export const parseTextWithBold = (text: string) => {
  if (!text) {
    return;
  }
  const boldRegex = /\*\*(.*?)\*\*/g;
  const parts = text.split(boldRegex);
  return parts.map((part, index) => {
    if (index % 2 === 1) {
      return <strong key={index}>{part}</strong>;
    }
    return <span key={index}>{part}</span>;
  });
};

export const parseTextWithLinks = (text: string) => {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const parts = text.split(linkRegex);

  return parts.map((part, index) => {
    if (index % 3 === 0) {
      return <span key={index}>{part}</span>;
    } else if (index % 3 === 1) {
      const url = parts[index + 1];
      return (
        <a key={index} href={url} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return null;
  });
};

export const createSlug = (text: string) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars except -
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};
