import logo from "../../assets/cache_logo.svg";
import "./loader.css";

type LoaderProp = {
  loading: boolean;
  message?: string;
};
function Loader({ loading, message = '' }: LoaderProp) {
  if(!loading) {
    return null
  }

  return (
    <div className="container">
      <div className={loading ? "loader" : "hide"}>
        {/*<div className="loader__logo">*/}
        {/*  <img src={logo} alt="Logo" />*/}
        {/*</div>*/}
        <div className="loader__svgHolder">
          <svg>
            <circle cx="35" cy="35" r="35"></circle>
          </svg>
        <p className="loader-title">
          {message}
        </p>
        </div>
      </div>
    </div>
  );
}

export default Loader;
