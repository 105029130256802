import { api } from "./api";

const apiBlog = api.injectEndpoints({
  endpoints: (build) => ({
    createSubscription: build.mutation({
      query: (body) => ({
        url: "/subscription",
        method: "POST",
        body: body,
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useCreateSubscriptionMutation } = apiBlog;
