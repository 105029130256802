import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import "./Settings.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  changeAddress,
  changeApartment,
  changeCity,
  changeEmail,
  changeState,
  changeStreet,
  changeZipCode,
} from "../../redux/sliceAuth";
import notification from "../../elements/ToastNotifications/ToastNotifications";
import { useUpdateUserSettingsMutation } from "../../api/apiAuth";
import {
  getAuth,
  updateEmail,
  updatePassword,
  User,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithCustomToken,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import errorIcon from "../../assets/passsword_errorIcon.svg";
import successIcon from "../../assets/passsword_successIcon.svg";
import DisplayModePersonalInfo from "./DisplayModePersonalInfo";
import Address from "../Address/Address";
import AuthPopUp from "../../elements/Firebase/AuthPopUp";
import { AuthContext } from "../../context/AuthContext";
// @ts-ignore
import TextInput from "../../elements/Input/TextInput";
import PasswordInput from "../../elements/PasswordInput/PasswordInput";
import { auth } from "../../config/firebase";
import {useMixpanel} from "../../helpers/mixpanel";

interface SettingsForm {
  address: string;
  apartment: string;
  city: string;
  zipCode: string;
  state: string;
  email: string;
  password: string;
}

function EditablePersonalInfo(props: any) {
  const context = useContext(AuthContext);
  const { editableMode, setEditableMode } = props;
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const digitRegex = /\d/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const [authPopUp, setAuthPopUp] = useState(false);
  const mixpanel = useMixpanel();
  const [isStreet, setIsStreet] = useState(!authInfo?.street);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState({
    address: false,
    apartment: false,
    city: false,
    zipCode: false,
    state: false,
    email: false,
    password: false,
  });
  const [addressError, setAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState<SettingsForm>({
    address: "",
    apartment: "",
    city: "",
    zipCode: "",
    state: "",
    email: "",
    password: "",
  });
  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  const stateRegex =
    /^(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isPasswordFieldValid = (password: string) => {
    return (
      password === "" ||
      (password.length >= 12 &&
        digitRegex.test(password) &&
        uppercaseRegex.test(password) &&
        lowercaseRegex.test(password) &&
        specialCharRegex.test(password))
    );
  };
  const passwordValid = isPasswordFieldValid(formData.password);

  const resetErrorOnChange = () => {
    if (addressError || emailError || !passwordValid) {
      setFormError({
        address: false,
        apartment: false,
        city: false,
        zipCode: false,
        state: false,
        email: false,
        password: false,
      });
      setAddressError("");
      setEmailError("");
    }
  };

  useEffect(() => {
    if (!editableMode) {
      setFormData({
        address: authInfo?.address || authInfo?.street,
        apartment: authInfo?.apartment || "",
        city: authInfo?.city || "",
        zipCode: authInfo?.zipCode || "",
        state: authInfo?.state || "",
        email: authInfo?.email || "",
        password: "",
      });
      setIsStreet(!!authInfo?.street);
    }
  }, [editableMode]);

  useEffect(() => {
    setFormData({
      address: authInfo?.address || authInfo?.street,
      apartment: authInfo?.apartment || "",
      city: authInfo?.city || "",
      zipCode: authInfo?.zipCode || "",
      state: authInfo?.state || "",
      email: authInfo?.email || "",
      password: "",
    });
  }, [authInfo]);

  const [updateSettings, result] = useUpdateUserSettingsMutation();

  const editableCallback = (success: boolean) => {
    if (success) {
      if (isStreet) {
        dispatch(changeAddress(""));
        dispatch(changeStreet(formData.address));
      } else {
        dispatch(changeStreet(""));
        dispatch(changeAddress(formData.address));
      }
      dispatch(changeApartment(formData.apartment));
      dispatch(changeCity(formData.city));
      dispatch(changeZipCode(formData.zipCode));
      dispatch(changeState(formData.state));
      dispatch(changeEmail(formData.email));
      setEditableMode(false);
      navigation("/settings");
      notification({
        message: "Personal information updated successfully.",
        type: "success",
      });
    } else {
      mixpanel("Error", {
        message: "Settings: Error while updating personal information",
      });
      console.log("Settings: Error while updating personal information");
    }
  };

  const reAuthUser = (password: string) => {
    const auth = getAuth();
    const currentUser: any = auth?.currentUser;
    const email = currentUser?.email || authInfo.email;
    const credential = EmailAuthProvider.credential(email, password);
    return reauthenticateWithCredential(currentUser, credential);
  };

  const updateUserPassword = (user: User, password: string) => {
    return updatePassword(user, password)
      .then((res) => {
        return "SUCCESS";
      })
      .catch((err) => {
        if (err.code == "auth/requires-recent-login") {
          setAuthPopUp(true);
        }
        console.log("Settings: Error updating passwords: ", err);
        mixpanel("Error", { message: err?.message });
        return "ERROR";
      });
  };

  const updateUserEmail = (user: User, email: string) => {
    return updateEmail(user, email)
      .then((res) => {
        return "SUCCESS";
      })
      .catch((err) => {
        if (err.code == "auth/requires-recent-login") {
          setAuthPopUp(true);
        } else if (err.code == "auth/email-already-in-use") {
          setFormError((prevState) => {
            return {
              ...prevState,
              email: true,
            };
          });
          setEmailError("This email is associated with another Cache account.");
        }
        console.log("Settings: Error updating email: ", err);
        mixpanel("Error", { message: err?.message });
        return "ERROR";
      });
  };

  const updatePersonalData = (tkn: string, body: any) => {
    return updateSettings({ idToken: tkn, body })
      .then((res: any) => {
        console.log("res", res);
        if (res?.data?.status === 200) {
          const customToken = res?.data?.customToken;
          customToken && signInWithCustomToken(auth, customToken);
          return "SUCCESS";
        }

        if (res?.error?.data?.message === "EMAIL_DUPLICATION_ERROR") {
          setEmailError("This email is associated with another Cache account.");
        }
        console.log("Settings: Error personal data");
        mixpanel("Error", { message: "Settings: Error personal data" }, tkn);
        return "ERROR";
      })
      .catch((err) => {
        mixpanel("Error", { message: err?.message });
        console.log("Settings: Error personal data: ", err);
        return "ERROR";
      });
  };

  const getInvalidFields = (formData: any) => {
    const fields = [];
    for (let key in formData) {
      if (["apartment", "password"].includes(key)) {
        continue;
      }
      if (!formData[key]) {
        fields.push(key);
      } else if (
        (key === "zipCode" && !zipRegex.test(formData["zipCode"])) ||
        (key === "state" && !stateRegex.test(formData["state"])) ||
        (key === "email" && !emailRegex.test(formData["email"]))
      ) {
        fields.push(key);
      } else if (key === "password") {
        if (
          formData["password"].length < 12 ||
          !digitRegex.test(formData["password"]) ||
          !uppercaseRegex.test(formData["password"]) ||
          !lowercaseRegex.test(formData["password"]) ||
          !specialCharRegex.test(formData["password"])
        ) {
          fields.push(key);
        }
      }
    }
    return fields;
  };

  const getFormErrors = (formData: any) => {
    const errors = {
      address: "",
      email: "",
    };
    if (
      !formData.address ||
      !formData.city ||
      !formData.zipCode ||
      !formData.state ||
      (formData.state && !stateRegex.test(formData.state)) ||
      (formData.zipCode && !zipRegex.test(formData.zipCode))
    ) {
      errors.address = "Please complete all required fields.";
    }
    if (!formData.email) {
      errors.email = "Please enter your email.";
    } else if (!emailRegex.test(formData.email) || formData.email.length > 40) {
      errors.email = "Invalid email address. Please try again.";
    }
    return errors;
  };

  const saveUserSettings = () => {
    const invalidFields = getInvalidFields(formData);
    if (invalidFields.length > 0 || !passwordValid) {
      setFormError({
        address: invalidFields.includes("address"),
        apartment: false,
        city: invalidFields.includes("city"),
        zipCode: invalidFields.includes("zipCode"),
        state: invalidFields.includes("state"),
        email: invalidFields.includes("email"),
        password: !passwordValid,
      });
      const formErrors = getFormErrors(formData);
      setAddressError(formErrors.address);
      setEmailError(formErrors.email);
    } else {
      const { user } = context;
      if (!user) {
        navigation("/login");
      } else {
        setIsLoading(true);
        user.getIdToken().then(async (idToken: string) => {
          let passwordRes = "SUCCESS";
          let settingsRes = "SUCCESS";
          // const emailRes = await updateUserEmail(user, formData.email);
          if (formData.password) {
            passwordRes = await updateUserPassword(user, formData.password);
          }
          if (![passwordRes].includes("ERROR")) {
            const addressFieldName = isStreet ? "street" : "address";
            settingsRes = await updatePersonalData(idToken, {
              [addressFieldName]: formData.address,
              apartment: formData.apartment,
              city: formData.city,
              zipCode: formData.zipCode,
              state: formData.state,
              email: formData.email,
            });
          }
          const success = ![settingsRes, passwordRes].includes("ERROR");
          editableCallback(success);
          setIsLoading(false);
        });
      }
    }
  };

  return (
    <>
      <div className="profile__title__container">
        <div className="settings__title">Personal Information</div>
        {editableMode ? (
          <button
            disabled={isLoading}
            className="profile__edit_save"
            onClick={() => saveUserSettings()}
          >
            Save
          </button>
        ) : (
          <button
            className="profile__edit_save"
            onClick={(e) => setEditableMode(!editableMode)}
          >
            Edit
          </button>
        )}
      </div>
      <div className="profile__blockInfo">
        {editableMode ? (
          <>
            <div className={`customInput__holder`}>
              <TextInput
                fullWidth
                disabled
                label="Name"
                name="userName"
                value={`${authInfo.firstName} ${authInfo.lastName}`}
                onChange={() => {}}
                inputProps={{ readOnly: true }}
              />
            </div>
            <div className={`customInput__holder`}>
              <TextInput
                fullWidth
                disabled
                label="Date of birth"
                name="userDateOfBirth"
                value={`${moment(authInfo.birthday).format("MM/DD/YYYY")}`}
                onChange={() => {}}
                inputProps={{ readOnly: true }}
              />
            </div>
            <div className={`customInput__holder`}>
              <PasswordInput
                label="SSN"
                disabled
                userPassword={
                  authInfo.ssnOne +
                  authInfo.ssnTwo +
                  authInfo.ssnThree +
                  authInfo.ssnFour +
                  authInfo.ssnFive +
                  authInfo.ssnSix +
                  authInfo.ssnSeven +
                  authInfo.ssnEight +
                  authInfo.ssnNine
                }
                onChange={() => {}}
                showBottomValidation={false}
                inputProps={{ readOnly: true }}
              />
            </div>
            <Address
              showFormAlways={true}
              isStreet={isStreet}
              setIsStreet={setIsStreet}
              formError={formError}
              formData={formData}
              setFormData={setFormData}
              resetErrorOnChange={resetErrorOnChange}
            />
            {addressError ? (
              <p className="email-error-msg">{addressError}</p>
            ) : null}
            <div className={`customInput__holder`}>
              <TextInput
                fullWidth
                label="Email address"
                name="settingsNewEmail"
                error={formError.email}
                value={formData.email}
                onChange={(e) => {
                  setFormData((prevState: any) => {
                    return {
                      ...prevState,
                      email: e.target.value,
                    };
                  });
                  resetErrorOnChange();
                }}
              />
            </div>
            {emailError ? (
              <p className="email-error-msg">{emailError}</p>
            ) : null}
            <div className={`customInput__holder`}>
              <PasswordInput
                name="settingsNewPassword"
                autoComplete={"new-password"}
                userPassword={formData.password}
                onChange={(e: any) => {
                  setFormData((prevState: any) => {
                    return {
                      ...prevState,
                      password: e.target.value,
                    };
                  });
                  resetErrorOnChange();
                }}
                passwordError={formError.password}
                showBottomValidation={false}
              />
            </div>
            {formData.password && (
              <div className="password__conditions-container">
                <div className="password__conditions-title">
                  Your password must contain at least:
                </div>
                <div className="password__condition-item">
                  {!formData.password?.length ||
                  formData.password?.length < 12 ? (
                    <img src={errorIcon} alt="error" />
                  ) : (
                    <img src={successIcon} alt="success" />
                  )}

                  <div className="condition-description">12 characters</div>
                </div>
                <div className="password__condition-item">
                  {uppercaseRegex.test(formData.password) ? (
                    <img src={successIcon} alt="success" />
                  ) : (
                    <img src={errorIcon} alt="error" />
                  )}
                  <div className="condition-description">
                    1 upper case letter
                  </div>
                </div>
                <div className="password__condition-item">
                  {lowercaseRegex.test(formData.password) ? (
                    <img src={successIcon} alt="success" />
                  ) : (
                    <img src={errorIcon} alt="error" />
                  )}
                  <div className="condition-description">
                    1 lower case letter
                  </div>
                </div>
                <div className="password__condition-item">
                  {digitRegex.test(formData.password) ? (
                    <img src={successIcon} alt="success" />
                  ) : (
                    <img src={errorIcon} alt="error" />
                  )}

                  <div className="condition-description">1 number</div>
                </div>
                <div className="password__condition-item">
                  {specialCharRegex.test(formData.password) ? (
                    <img src={successIcon} alt="success" />
                  ) : (
                    <img src={errorIcon} alt="error" />
                  )}
                  <div className="condition-description">
                    1 special character
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <DisplayModePersonalInfo />
        )}
      </div>
      {authPopUp ? (
        <AuthPopUp
          open={authPopUp}
          handleClose={() => setAuthPopUp(false)}
          reAuthUser={reAuthUser}
          callBack={saveUserSettings}
        />
      ) : null}
    </>
  );
}

export default EditablePersonalInfo;
