import "./connectionButton.css";
import plade_cache from "../../assets/plaid_cache.svg"
import plus_btn_icon from "../../assets/plus-btn-icon.svg";
import disable_plus_icon from "../../assets/disable_plus-icon.svg";


const ConnectionButton = ({ accountType, isActive, onClickHandler, isWrongChoice = false, errorCard = false }: any) => {
  const onPress = () => {
    if (!isActive) {
      return;
    }
    onClickHandler();
  };

  const disableSavingsButton = (
    <div className="current__account">
      <img src={disable_plus_icon} alt="plus icon" />
      <div className="current__account-title disabledBtn">
        Connect Savings Account
      </div>
    </div>
  );

  return (
    <button
      onClick={onPress}
      style={ isWrongChoice ? {border: '1px solid var(--error)'} : {}}
      className={
        `${!isActive ? "connection__button connection__button-disable" : "connection__button"} ${errorCard ? "card-with-error" : ""}`
      }
    >
      {isActive && (
        <div className="connection__button-logo">
          <img src={plade_cache} alt=" add account logo" />
        </div>
      )}
      {isActive ? (
        <div className="current__account">
          <img src={plus_btn_icon} alt="plus icon" />
          <div className="current__account-title">{`Connect ${accountType}`}</div>
        </div>
      ) : (
        <>{disableSavingsButton}</>
      )}
      {isActive && (
        <div className="current__account-discription text__content">
          To securely connect to your bank accounts, Cache utilizes{" "}
          <span>Plaid</span>, which supports over 11,000 banking institutions.
        </div>
      )}
    </button>
  );
};

export default ConnectionButton;
