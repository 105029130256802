import { useEffect, useState } from "react";
import Button from "../../../elements/Button/Button";
import PasswordInput from "../../../elements/PasswordInput/PasswordInput";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, confirmPasswordReset } from "firebase/auth";

import "./confirmNewPassword.css";
import notification from "../../../elements/ToastNotifications/ToastNotifications";
import Wrapper from "../../../elements/Wrapper";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ConfirmNewPassword() {
  const query = useQuery();
  const auth = getAuth();
  const navigation = useNavigate();
  const [userPassword, setUserPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const resetErrorOnChange = () => {
    setPasswordError(false);
  }

  useEffect(() => {
    if (auth.currentUser) navigation("/automations");
  }, []);
  const onResetPasswordHandler = () => {
    if (userPassword && passwordValid) {
      const auth = getAuth();
      const oobCode = query.get("oobCode") || "";
      setIsCheck(true);
      confirmPasswordReset(auth, oobCode, userPassword)
        .then(() => {
          setIsCheck(false);
          navigation("/login");
        })
        .catch((error) => {
          setIsCheck(false);
          console.log('Error in onResetPasswordHandler ConfirmNewPassword.tsx:', error?.message);
        });
    } else {
      setPasswordError(true);
    }
  };

  return (
    <Wrapper>
      <div className="confirm__infoContainer">
        <div className="page__title">Set new password.</div>
        <div className="password__description">
          For your security, your new password must be different from previously
          used passwords. It must include a combination of letters, numbers,
          symbols, and at least 12 characters.
        </div>
        <div className="passwordInput">
          <PasswordInput
            userPassword={userPassword}
            onChange={(e: any) => {
              setUserPassword(e.target.value);
              resetErrorOnChange();
            }}
            setPasswordValid={setPasswordValid}
            passwordError={passwordError}
          />
        </div>
        {passwordError && !userPassword && (
          <div className="passwod__errorMessage error__message">
            Please create a password.
          </div>
        )}
      </div>
      <div className="button__holder">
        <Button disabled={isCheck} onClick={onResetPasswordHandler} type={"primary-btn"}>
          Reset Password
        </Button>
      </div>
    </Wrapper>
  );
}

export default ConfirmNewPassword;
