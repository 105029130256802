import "./multiInput.css";
import OtpInput from 'react-otp-input';

function MultiInput({error, ...rest} :any) {
    return (
        <OtpInput
            numInputs={4}
            inputType="tel"
            placeholder="----"
            shouldAutoFocus={true}
            renderSeparator={false}
            renderInput={(props) => <input {...props} />}
            skipDefaultStyles={true}
            containerStyle="otp-input-container"
            inputStyle={`${error ? "hasError " : ""}otp-input-input`}
            {...rest}
        />
    )
}

export default MultiInput;