import defaulBank_icon from "../assets/defaultBnak_logo.svg";
import citibankLogo from "../assets/insitutionIcons/citi.svg";
import chaseLogo from "../assets/insitutionIcons/chase.svg";
import wellsFargoLogo from "../assets/insitutionIcons/wells.svg";

export default function getBankLogo(
  base64String: any,
  institutionName?: string,
) {
  if (base64String) {
    return "data:image/png;base64, " + base64String;
  }
  switch (institutionName) {
    case "ins_5":
      //Citibank Online
      return citibankLogo;
    case "ins_56":
      //Chase
      return chaseLogo;
    case "ins_127991":
      //Wells Fargo
      return wellsFargoLogo;
    default:
      return defaulBank_icon;
  }
}
