import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  isShowScannerLoader: false,
  sessionId: null,
};

export const sliceAppState = createSlice({
  name: "appState",
  initialState: initialState,
  reducers: {
    setIsShowScannerLoader: (state, { payload = false }) => {
      state.isShowScannerLoader = payload;
    },
    setSessionId: (state, { payload = null }) => {
      state.sessionId = payload;
    },
  },
});
export const { setIsShowScannerLoader, setSessionId } = sliceAppState.actions;

export default sliceAppState.reducer;
