import React, { useEffect, useState } from "react";
import { auth } from "../config/firebase";
import { User, setPersistence, indexedDBLocalPersistence } from "firebase/auth";

type ContextProps = {
  user: User | null;
};

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState(null as User | null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      await setPersistence(auth, indexedDBLocalPersistence);

      auth.onAuthStateChanged((user: any) => {
        setUser(user);
        setLoading(false);
      });
    };

    init();
  }, [auth.currentUser]);

  return (
    <AuthContext.Provider value={{ user }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
