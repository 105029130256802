import { Link, useNavigate } from "react-router-dom";
import "./signUpVerifyEmail.css";
import { sendEmailVerification, signInWithCustomToken } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import { StepWizardChildProps } from "react-step-wizard";
import Wrapper from "../../../elements/Wrapper";
import { AuthContext } from "../../../context/AuthContext";
// @ts-ignore
import notification from "../../../elements/ToastNotifications/ToastNotifications";
import { useSendVerificationEmailMutation } from "../../../api/apiAuth";
import Button from "../../../elements/Button/Button";
import { useVerifyCodeMutation } from "../../../api/apiAuth";
import { auth } from "../../../config/firebase";
import MultiInput from "../../../elements/MultiInput/MultiInput";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useMixpanel } from "../../../helpers/mixpanel";

const SignUpVerifyEmail: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  previousStep,
}) => {
  const [sendVerificationEmailViaBackend, verificationResult] =
    useSendVerificationEmailMutation();
  const [verifyCode, verifyCodeResult] = useVerifyCodeMutation();
  const context = useContext(AuthContext);
  const mixpanel = useMixpanel();
  const navigation = useNavigate();
  const [code, setCode] = useState("");
  const [formError, setFormError] = useState({
    code: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [isEnabledNextBtn, setIsEnabledNextBtn] = useState(true);
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const isCodeValid = (code: string) => {
    return code && code.length === 6;
  };

  const resetErrorOnChange = () => {
    setFormError({
      code: false,
    });
    setErrorMsg("");
  };

  const onBackHandler = () => {
    previousStep?.();
  };

  const sendEmail = async (showSuccessMsg: boolean = false) => {
    const { user } = context;
    if (currentStep === 10) {
      if (user) {
        const tkn = await user.getIdToken();
        // sendEmailVerification(user)

        sendVerificationEmailViaBackend({ idToken: tkn })
          .then((res) => {
            if (showSuccessMsg) {
              notification({
                message: "Verification email sent.",
                type: "success",
              });
            }
          })
          .catch((error) => {
            mixpanel("Error", { message: error?.message }, tkn);
            console.log(
              "Error in sendEmail SignUpVerifyEmail.tsx: ",
              error?.message,
            );
          });
      }
    }
  };

  useEffect(() => {
    if (currentStep === 10) {
      sendEmail();
    }
  }, [currentStep]);

  if (currentStep !== 10) {
    return null;
  }
  const onNextHandler = async () => {
    try {
      const { user } = context;
      if (!user) {
        navigation("/login");
        return;
      }

      if (!isCodeValid(code)) {
        setFormError({
          code: true,
        });
        setErrorMsg("Wrong code entered. Please try again.");
      } else {
        const body = {
          code: code,
        };
        const idToken = await user.getIdToken();

        setIsEnabledNextBtn(false);

        const res = (await verifyCode({
          idToken,
          body,
        })) as any;

        if (res?.error) {
          setIsEnabledNextBtn(true);

          const { message } = res?.error?.data ?? {};
          let error = "";

          switch (message) {
            case "CODE_EXPIRED":
              error = "Code expired. Please request another code. ";
              break;
            case "INCORRECT_CODE":
              error = "Wrong code entered. Please try again.";
              break;
            default:
              error = "Can't verify code.";
              break;
          }
          setFormError({
            code: true,
          });
          setErrorMsg(error);
          return;
        }
        const customToken = res?.data?.customToken;
        await signInWithCustomToken(auth, customToken);

        setIsEnabledNextBtn(true);

        navigation("/verify-account");
      }
    } catch (error) {
      console.log("Error in onNextHandler, SignUpVerifyEmail.tsx: ", error);
    }
  };

  return (
    <Wrapper>
      <SignupNavbar
        step={currentStep}
        handler={onBackHandler}
        showBackButton={false}
      />
      <div className="verifyEmail__infoContainer">
        <div className="page__title smaller-size">
          And lastly, please verify your email.
        </div>
        <div className="page__subtitle smaller-size">
          We sent an email to {authInfo.email} containing a 6-digit code. Enter
          it below to start using Cache.
        </div>
        <div className="customInput__holder">
          <MultiInput
            placeholder="------"
            numInputs={6}
            value={code}
            error={formError.code}
            onChange={(value: any) => {
              setCode(value);
              resetErrorOnChange();
            }}
          />
        </div>
        {errorMsg ? (
          <div className="info__subtitle error__msg">{errorMsg}</div>
        ) : (
          ""
        )}
        <div className="verifyEmail__resendEmail">
          Didn’t receive it?{" "}
          <Link
            to="#"
            onClick={() => {
              if (!verificationResult.isLoading) {
                sendEmail(true);
                mixpanel("Requested_resend_email");
              }
            }}
          >
            Resend email.
          </Link>
        </div>
        <div className="verifyEmail__resendEmail">
          You can also{" "}
          <Link
            to="#"
            onClick={() => {
              nextStep?.();
              mixpanel("Requested_change_email");
            }}
          >
            change your email address,
          </Link>{" "}
          if necessary.
        </div>
      </div>
      <div className="button__holder">
        <Button
          onClick={() => onNextHandler()}
          type={"primary-btn"}
          disabled={!isEnabledNextBtn}
          style={{ opacity: 1 }}
        >
          Next
        </Button>
      </div>
      <input type="hidden" id="abandonedOnboardingStage" value={"verify"} />
    </Wrapper>
  );
};

export default SignUpVerifyEmail;
