import "./Settings.css";
import "react-toggle/style.css";
import eye_off from "../../assets/eye-off.svg";
import { AiOutlineEye } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import "rc-dropdown/assets/index.css";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import moment from "moment";

function DisplayModePersonalInfo() {
  const [hideSNN, setHideSNN] = useState(true);
  const profile: any = useSelector<RootState>((state) => state.auth);
  const dateOfBirth = moment(profile.birthday).format("MM/DD/YYYY");

  return (
    <>
      <div className="account__infoRow">
        <div className="account__infoContainer">
          <div className="account__cardName">Name</div>
          <div className="account__infoRow__Data">
            {profile.firstName + " " + profile.lastName}
          </div>
        </div>
      </div>
      <div className="account__infoRow">
        <div className="account__infoContainer">
          <div className="account__cardName">Date of birth</div>
          <div className="account__infoRow__Data">{dateOfBirth}</div>
        </div>
      </div>
      <div className="account__infoRow">
        <div className="account__infoContainer">
          <div className="account__cardName">SSN</div>
          <div className="account__infoRow__Data">
            <input
              className="profile__input"
              type={hideSNN ? "password" : "text"}
              value={
                profile.ssnOne +
                profile.ssnTwo +
                profile.ssnThree +
                profile.ssnFour +
                profile.ssnFive +
                profile.ssnSix +
                profile.ssnSeven +
                profile.ssnEight +
                profile.ssnNine
              }
              name="ssn"
              readOnly
            />
          </div>
        </div>
        <div className="eye_icon_settings" onClick={() => setHideSNN(!hideSNN)}>
          {hideSNN ? (
            <img src={eye_off} alt="Eye Off Icon" />
          ) : (
            <AiOutlineEye />
          )}
        </div>
      </div>
      <div className="account__infoRow">
        <div className="account__infoContainer">
          <div className="account__cardName">Home address</div>
          <div className="account__infoRow__Data">
            {profile.apartment || ""} {profile.address || profile.street} <br />
            {profile.city ? profile.city + "," : ""} {profile.state || ""}{" "}
            {profile.zipCode || ""}
          </div>
        </div>
      </div>
      <div className="account__infoRow">
        <div className="account__infoContainer">
          <div className="account__cardName">Email</div>
          <div className="account__infoRow__Data">{profile.email}</div>
        </div>
      </div>
      <div className="account__infoRow">
        <div className="account__infoContainer">
          <div className="account__cardName">Password</div>
          <div className="account__infoRow__Data">
            <input
              className="profile__input"
              type="password"
              value={"************"}
              readOnly
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DisplayModePersonalInfo;
