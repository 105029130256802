import {Link} from "react-router-dom";
import SimpleWrapper from "../elements/SimpleWrapper";
import {Box, Button} from "@mui/material";
import React from "react";

const Error = () => {
	return (
		<SimpleWrapper>
			<div className="landing" style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: 'calc(100vh - 100px)',
			}}>
				<Box textAlign="center">
					<h1 style={{
						fontSize: '40px',
						lineHeight: '60px',
						textAlign: 'center',
						marginBottom: '30px'
					}}>Oops! Page doesn't exist</h1>
					<Button component={Link} to="/" variant="contained">Home page</Button>
				</Box>
			</div>
		</SimpleWrapper>
	);
};

export default Error;