import Button from "../../../elements/Button/Button";
import { getAuth } from "firebase/auth";
import notification from "../../../elements/ToastNotifications/ToastNotifications";
import React, { useEffect, useState } from "react";
// @ts-ignore
import "./recoveryPasswordEmail.css";
import Wrapper from "../../../elements/Wrapper";
import { useNavigate } from "react-router-dom";
import TextInput from "../../../elements/Input/TextInput";
import { useSendResetPwEmailMutation } from "../../../api/apiAuth";
import BackButton from "../../../elements/BackButton/BackButton";
import { useMixpanel } from "../../../helpers/mixpanel";

function RecoveryPasswordEmail() {
  const auth = getAuth();
  const mixpanel = useMixpanel();
  const [sendResetPwEmail, sendResetPwEmailResult] = useSendResetPwEmailMutation();
  const [emailSent, setEmailSent] = useState(false);
  const navigation = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [formError, setFormError] = useState({
    email: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmailValid = (email: string) => {
    return email && emailRegex.test(email);
  };
  const resetErrorOnChange = () => {
    if (errorMsg) {
      setFormError({
        email: false,
      });
      setErrorMsg("");
    }
  };

  useEffect(() => {
    if (auth.currentUser) navigation("/automations");
  }, []);

  const onNextHandler = (resend: boolean = false) => {
    const auth = getAuth();
    if (!isEmailValid(userEmail)) {
      setFormError({
        email: true,
      });
      if (userEmail && !emailRegex.test(userEmail)) {
        setErrorMsg("Invalid email address. Please try again.");
      } else {
        setErrorMsg("Please enter your email.");
      }
    } else {
      sendResetPwEmail({ email: userEmail })
        .then((res: any) => {
          if (res?.error?.data?.message === "USER_NOT_FOUND") {
            setFormError({
              email: true,
            });
            setErrorMsg("This email is not associated with a Cache account.");
          } else {
            setEmailSent(true);
            if (resend) {
              notification({
                message: "Password reset email sent.",
                type: "success",
              });
            }
          }
        })
        .catch((error) => {
          if (error.message == "Firebase: Error (auth/user-not-found).") {
            setFormError({
              email: true,
            });
            setErrorMsg("This email is not associated with a Cache account.");
          }
          console.log(
            "Error in sendPasswordResetEmail RecoveryPasswordEmail:",
            error,
          );
        });
    }
  };

  const onBackHandler = () => {
    navigation('/login')
  };

  return (
    <Wrapper>
      {!emailSent ? <div className="recovery-password-back-btn">
        <div className="backBtn__holder">
          <BackButton onBack={onBackHandler} />
        </div>
      </div> : ""}
      <div className="recovery__infoContainer">
        {emailSent ? (
          <>
            <div className="page__title">We’ve sent you an email.</div>
            <div className="page__subtitle">
              Please follow the instructions in the email to reset your
              password.
            </div>
            <div className="page__subtitle">
              Didn’t receive the email?{" "}
              <button
                className="resend"
                disabled={sendResetPwEmailResult.isLoading}
                onClick={() => {
                  mixpanel("Requested_resend_email");
                  onNextHandler(true);
                }}
              >
                Resend.
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="page__title">Forgot password?</div>
            <div className="page__subtitle">
              Please enter your email address and we’ll send you a link to reset
              your password.
            </div>
            <div className="recovery__inputHolder">
              <TextInput
                fullWidth
                label="Email"
                name="signUpEmail"
                error={formError.email}
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                  resetErrorOnChange();
                }}
              />
            </div>
            {errorMsg ? (
              <div className="recovery__errorMassage error__message">{errorMsg}</div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <div className="button__holder">
        {!emailSent && (
          <Button disabled={sendResetPwEmailResult.isLoading} onClick={() => onNextHandler()} type={"primary-btn"}>
            {"Send Email"}
          </Button>
        )}
      </div>
    </Wrapper>
  );
}

export default RecoveryPasswordEmail;
