import "./savingTimeGraph.css";
import equal from "../../assets/equal.svg";
import more from "../../assets/more.svg";
import less from "../../assets/less.svg";
import { NumericFormat } from "react-number-format";

const SavingTimeGraph = ({ balanceData }: any) => {
    const { amountSaved, oneMonthAgo, threeMonthAgo, oneYearAgo } = balanceData;
    const isValidValue = (value: any) => value || value == 0;
    const hideInfo = !isValidValue(oneMonthAgo?.value) && !isValidValue(threeMonthAgo?.value) && !isValidValue(oneYearAgo?.value);
    const getIcon = (condition: string) => {
        switch (condition) {
            case "EQUAL":
                return equal;
            case "MORE":
                return more;
            case "LESS":
                return less;
            default:
                return "";
        }
    };
    const AmountValue = ({ value }: any) => {
        return (
            <NumericFormat
                value={value || 0}
                decimalScale={0}
                thousandSeparator=","
                displayType="text"
                prefix={'$'}
            />
        )
    };

    const PeriodValue = ({ amount, condition, period }: any) => {
        return (
            <NumericFormat
                value={amount}
                decimalScale={0}
                thousandSeparator=","
                displayType="text"
                prefix={'$'}
                renderText={(value) => {
                    if (condition === "EQUAL") {
                        return "Equal to " + period;
                    } else if (condition === "MORE") {
                        return value + " more than " + period;
                    } else if (condition === "LESS") {
                        return value + " less than " + period;
                    }
                    return ""
                }}
            />
        )
    };

    const getParsedData = (value: any) => {
        let result: any = {
            show: false,
            icon: "",
            amount: null,
            condition: ""
        };
        if (isValidValue(value) && isValidValue(amountSaved)) {
            result.show = true;
            if (value === amountSaved) {
                result.icon = getIcon("EQUAL");
                result.amount = null;
                result.condition = "EQUAL";
            } else if (value < amountSaved) {
                result.icon = getIcon("MORE");
                result.amount = Math.abs(value - amountSaved);
                result.condition = "MORE";
            } else if (value > amountSaved) {
                result.icon = getIcon("LESS");
                result.amount = Math.abs(value - amountSaved);
                result.condition = "LESS";
            }
        }
        return result;
    };
    const oneMonth = getParsedData(oneMonthAgo?.value);
    const threeMonth = getParsedData(threeMonthAgo?.value);
    const oneYear = getParsedData(oneYearAgo?.value);

    return (<div className="saving_graph__container">
        <div className="title">Savings Over Time</div>
        <div className="circle_graph">
            <div className={`graph ${hideInfo ? "no-graph-info" : ""}`}>
                <div className="graph_amount">
                    <AmountValue value={amountSaved} />
                </div>
                <div className="graph_helper">Amount Saved</div>
            </div>
            {!hideInfo ? <div className="graph_info">
                {oneMonth.show ? <div className="row_info equal">
                    <img className={oneMonth.condition.toLowerCase()} src={oneMonth.icon} />
                    <span>
                        <PeriodValue amount={oneMonth.amount} condition={oneMonth.condition} period="30 days ago" />
                    </span>
                </div> : ""}
                {threeMonth.show ? <div className="row_info more">
                    <img className={threeMonth.condition.toLowerCase()} src={threeMonth.icon} />
                    <span>
                        <PeriodValue amount={threeMonth.amount} condition={threeMonth.condition} period="90 days ago" />
                    </span>
                </div> : ""}
                {oneYear.show ? <div className="row_info less">
                    <img className={oneYear.condition.toLowerCase()} src={oneYear.icon} />
                    <span>
                        <PeriodValue amount={oneYear.amount} condition={oneYear.condition} period="1 year ago" />
                    </span>
                </div> : ""}
            </div> : ""}
        </div>
    </div>)
};

export default SavingTimeGraph;