import { NavigateFunction } from "react-router-dom";

export const REGISTRATION_STEPS = {
  INTRO: "INTRO",
  CACHE_PRIVATE_POLICY: "CACHE_PRIVATE_POLICY",
  SET_AUTOMATIONS: "SET_AUTOMATIONS",
  PERSONAL_DATA_ADDRESS: "PERSONAL_DATA_ADDRESS",
  PERSONAL_DATA_BIRTHDAY: "PERSONAL_DATA_BIRTHDAY",
  PERSONAL_DATA_SSN: "PERSONAL_DATA_SSN",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_DWOLLA_PENDING: "VERIFY_DWOLLA_PENDING",
  VERIFY_DWOLLA_RETRY: "VERIFY_DWOLLA_RETRY",
  VERIFY_DWOLLA_FAILED: "VERIFY_DWOLLA_FAILED",
  COMPLETED: "COMPLETED",
  REMOVED: "REMOVED",
  NOT_DEFINED: "STEP_NOT_DEFINED",
};

const getStepNumberForRegistration = (step: string): number => {
  switch (step) {
    case REGISTRATION_STEPS.INTRO:
      return 1;
    case REGISTRATION_STEPS.CACHE_PRIVATE_POLICY:
      return 4;
    case REGISTRATION_STEPS.SET_AUTOMATIONS:
      return 5;
    case REGISTRATION_STEPS.PERSONAL_DATA_BIRTHDAY:
      return 7;
    case REGISTRATION_STEPS.PERSONAL_DATA_ADDRESS:
      return 8;
    case REGISTRATION_STEPS.PERSONAL_DATA_SSN:
      return 9;
    case REGISTRATION_STEPS.VERIFY_EMAIL:
      return 10;
    // case REGISTRATION_STEPS.COMPLETED:
    //   return 7;
    // case REGISTRATION_STEPS.NOT_DEFINED:
    //   return 7;
    default:
      return 0;
  }
};

const redirectToRegistrationStep = (
  step: string,
  navigation: NavigateFunction,
): void => {
  switch (step) {
    case REGISTRATION_STEPS.PERSONAL_DATA_SSN:
      navigation("/signup-user-name");
      break;
    case REGISTRATION_STEPS.SET_AUTOMATIONS:
      navigation("/link-accounts-list");
      break;
    case REGISTRATION_STEPS.COMPLETED:
      navigation("/automations");
      break;
    default:
      navigation("/automations");
      //navigation("/login-email");
      break;
  }
};

export { redirectToRegistrationStep, getStepNumberForRegistration };
