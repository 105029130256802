import { useContext, useEffect, useRef, useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import {
  useLazyGetAutomationsQuery,
  useLazyGetUserAccountsQuery,
  useModifyAutomationMutation,
  usePostAutomationMutation,
} from "../../../api/apiPlaid";
import { useDispatch, useSelector } from "react-redux";
import {
  setSavingsAccountList,
  setCheckingAccountList,
  updateAllIsUsedAutomationCheckingAccount,
} from "../../../redux/sliceAccounts";
import {
  setBalanceMaxValue,
  setCheckingAcc,
  setSavingsAcc,
  setSignUpAutomationId,
} from "../../../redux/sliceUserAutomation";
import { RootState } from "../../../redux/store";
import PlaidAuth from "../../Plaid/PlaidAuth";
// @ts-ignore
import ConnectionButton from "../../../elements/ConnectionButton/ConnectionButton";
import BalanceInput from "../../../elements/BalanceInput/BalanceInput";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import AccountCard from "../../../elements/AccountCard/AccountCard";
import Button from "../../../elements/Button/Button";
import arrows from "../../../assets/arrows_vertical.png";
import "./addAutomation.css";
import Wrapper from "../../../elements/Wrapper";
import { AuthContext } from "../../../context/AuthContext";
import { Timeout } from "react-number-format/types/types";
import { useMixpanel } from "../../../helpers/mixpanel";
import { AccountType } from "../../../helpers/types";
import AccountLoadingCard from "../../../elements/AccountLoadingCard/AccountLoadingCard";
import { getAccountSubType } from "../../../helpers/account";
import { ModalAccountsList } from "../../../elements/Modals/ModalAccountList";
import { PlaidInstitution } from "react-plaid-link";

const AddAutomation: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  previousStep,
}) => {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const [getAccountsList, accountsListResult] = useLazyGetUserAccountsQuery();
  const [createAutomation, createAutomationResult] =
    usePostAutomationMutation();
  const [modifyAutomation, modifyResult] = useModifyAutomationMutation();
  const [getAutomations, getAutomationsResult] = useLazyGetAutomationsQuery();
  const [selectCheckingAcc, setSelectCheckingAcc] = useState<any>(null);
  const [selectSavingsAcc, setSelectSavingsAcc] = useState<any>(null);

  const [isCheckingWrong, setIsCheckingWrong] = useState<any>(null);
  const [isSavingsWrong, setIsSavingsWrong] = useState<any>(null);

  const [showCheckingAccountsModal, setShowCheckingAccountsModal] =
    useState(false);
  const [showSavingsAccountsModal, setShowSavingsAccountsModal] =
    useState(false);
  const [isPlaidAuthLoading, setIsPlaidAuthLoading] = useState(false);
  const [isPlaidAuthOpen, setIsPlaidAuthOpen] = useState(false);
  const [isCheckingBtnActive, setIsCheckingBtnActive] = useState(false);
  const [isSavingsBtnActive, setIsSavingsBtnActive] = useState(false);
  const [typeAcc, setTypeAcc] = useState("");

  const checkingAccounts: any = useSelector<RootState>(
    (state) => state.accounts.checkingAccountList,
  );
  const savingsAccounts: any = useSelector<RootState>(
    (state) => state.accounts.savingsAccountList,
  );
  const signUpAutomationId = useSelector<RootState>(
    (state) => state.userAutomation.signUpAutomationId,
  );
  const [value, setValue] = useState<null | number>(null);
  const [error, setError] = useState(false);
  const [accountLoadMsg, setAccountLoadMsg] = useState("");
  const [accountErrorType, setAccountErrorType] = useState("");
  const [accountType, setAccountType] = useState({
    type: AccountType.CHECKING,
    clicked: false,
  });

  const [isAutomationSavingToDB, setIsAutomationSavingToDB] = useState(false);
  const [isAutomationDraftSavingToDB, setIsAutomationDraftSavingToDB] =
    useState(false);
  const [isSavedAutomationRecovered, setIsSavedAutomationRecovered] =
    useState(false);

  const saveAutomationTimerIdRef = useRef<Timeout | null>(null);
  const savingsCardRef = useRef(null);

  const accountLoadErrorCallback = (errorMsg: string, type: string) => {
    setAccountLoadMsg(errorMsg);
    setAccountErrorType(type);
    setIsPlaidAuthOpen(false);
  };
  const recoverSavedAutomation = async () => {
    try {
      const { user } = context;
      if (!user) {
        return;
      }
      const idToken = await user.getIdToken();
      const automationsResponse = await getAutomations({ idToken });

      const automation = automationsResponse?.data?.data[0];
      if (automation) {
        const savedCheckingAccount = automation?.checkingAccount;
        const savedSavingsAccount = automation?.savingAccount;
        const savedFixedValue = automation?.fixedValue;
        const savedAutomationID = automation?.id;

        if (savedAutomationID) {
          dispatch(setSignUpAutomationId(savedAutomationID));
        }

        if (
          savedCheckingAccount &&
          Object.keys(savedCheckingAccount).length !== 0
        ) {
          dispatch(setCheckingAcc(savedCheckingAccount));
          setSelectCheckingAcc(savedCheckingAccount);
        }

        if (
          savedSavingsAccount &&
          Object.keys(savedSavingsAccount).length !== 0
        ) {
          dispatch(setSavingsAcc(savedSavingsAccount));
          setSelectSavingsAcc(savedSavingsAccount);
        }

        if (typeof savedFixedValue === "number") {
          dispatch(setBalanceMaxValue(savedFixedValue));
          setValue(Number(automation?.fixedValue));
        }
      }

      setIsSavedAutomationRecovered(true);
    } catch (error) {
      console.log("Error in recoverSavedAutomation, AddAutomation.tsx");
    }
  };

  //get all User Accounts(savings, checking)
  const getAccounts = async (accountToUpdate?: {
    [field: string]: any | null;
  }) => {
    //accountToUpdate is account object that consist account that we chosen to selectCheckingAcc or selectSavingsAcc but need update with fresh data from DB
    if (currentStep === 5) {
      try {
        const { user } = context;
        if (!user) {
          return;
        } else {
          await user.getIdToken().then(async (idToken: string) => {
            const result = await getAccountsList({ idToken });
            //replace chosen accounts with same account from DB (because they includes required for creating automation fields)
            const accountsResult = result?.data?.data;
            if (accountsResult && accountToUpdate) {
              const extendedAccount = accountsResult.find(
                ({ plaidAccountId }: { plaidAccountId: string }) =>
                  plaidAccountId === accountToUpdate.plaidAccountId,
              );
              if (extendedAccount.cacheType === AccountType.CHECKING) {
                setSelectCheckingAcc(extendedAccount);
              } else if (extendedAccount?.cacheType === "savings") {
                setSelectSavingsAcc(extendedAccount);
              }
            }
          });
        }
      } catch (error) {
        console.log("error in getAccounts AddAutomation.tsx: ", error);
      }
    }
  };

  const handleModalOpen = (type: string) => {
    document.body.classList.remove("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(true);
      mixpanel("Opened_Checking_List", {});
    } else if (type === AccountType.SAVING) {
      setShowSavingsAccountsModal(true);
      mixpanel("Opened_Savings_List", {});
    }
    setAccountLoadMsg("");
    setAccountErrorType("");
  };

  const handleModalClose = (type: string) => {
    document.body.classList.add("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(false);
    } else if (type === AccountType.SAVING) {
      setShowSavingsAccountsModal(false);
    }
  };

  const applyDefaultAccounts = async (accounts: any[]) => {
    try {
      if (accounts.length === 0) {
        return null;
      }

      if (typeAcc === AccountType.CHECKING) {
        handleModalClose(AccountType.CHECKING);

        const existsCheckingAccount = accounts.some(
          (account) => account.cacheType === AccountType.CHECKING,
        );
        if (!existsCheckingAccount) {
          setIsCheckingWrong(true);
        }

        const connectedCheckingAccounts = accounts?.filter(
          ({ cacheType }) => cacheType === AccountType.CHECKING,
        );

        if (connectedCheckingAccounts.length > 1) {
          setCheckingAcc(null);
          await getAccounts();
          handleModalOpen(AccountType.CHECKING);
          return;
        }

        if (connectedCheckingAccounts.length === 1) {
          const defaultCheckingAccount = connectedCheckingAccounts[0];
          if (defaultCheckingAccount.cacheType !== "savings") {
            setSelectCheckingAcc(defaultCheckingAccount);
            mixpanel("Connected_checking_account", {
              Banks: defaultCheckingAccount,
            });
            getAccounts(defaultCheckingAccount);
            return;
          }
        }

        if (connectedCheckingAccounts.length === 0) {
          getAccounts();
          return;
        }
        // do we really need ||? maybe we need &&
      } else if (typeAcc === "savings" || accounts[0].cacheType === "savings") {
        handleModalClose(AccountType.SAVING);

        const connectedSavingsAccounts = accounts?.filter(
          ({ cacheType }) => cacheType === "savings",
        );

        if (connectedSavingsAccounts.length > 1) {
          setSavingsAcc(null);
          await getAccounts();
          handleModalOpen(AccountType.SAVING);
          return;
        }

        if (connectedSavingsAccounts.length === 1) {
          const defaultSavingsAccount = connectedSavingsAccounts[0];
          setSelectSavingsAcc(defaultSavingsAccount);
          mixpanel("Connected_savings_account", {
            Banks: defaultSavingsAccount,
          });
          getAccounts(defaultSavingsAccount);
          return;
        }
        if (connectedSavingsAccounts.length === 0) {
          getAccounts();
          return;
        }
      }
    } catch (error) {
      console.log("Error in applyDefaultAccounts AddAutomation.tsx: ", error);
    }
  };

  const verifyAccountsSubtype = (
    accounts: any[],
    institution: PlaidInstitution | null,
  ) => {
    if (!Array.isArray(accounts)) {
      return;
    }

    const isAccountTypeMatch = accounts.some((account) => {
      let cacheType = account.subtype;
      if (institution) {
        cacheType = getAccountSubType(
          account.subtype,
          institution?.institution_id,
        );
      }
      return cacheType === typeAcc;
    });

    if (isAccountTypeMatch) {
      return;
    }

    //banner that user didnt connect suitable account
    if (typeAcc === AccountType.CHECKING) {
      setCheckingAcc(null);
      setIsCheckingWrong(true);
      setAccountErrorType(AccountType.CHECKING);
    }
    if (typeAcc === "savings") {
      setSavingsAcc(null);
      setIsSavingsWrong(true);
      setAccountErrorType("savings");
    }
  };

  useEffect(() => {
    if (isPlaidAuthLoading) {
      handleModalClose(AccountType.CHECKING);
      handleModalClose(AccountType.SAVING);
    }
  }, [isPlaidAuthLoading]);

  useEffect(() => {
    if (currentStep !== 5) {
      return;
    }
    recoverSavedAutomation();
    getAccounts();
  }, [currentStep]);

  useEffect(() => {
    if (createAutomationResult?.data?.status === "SUCCESS") {
      const automationId = createAutomationResult.data.automationId;
      dispatch(setSignUpAutomationId(automationId));
    }
  }, [createAutomationResult]);

  //add accounts to redux state
  useEffect(() => {
    if (accountsListResult.data) {
      let checkingAccounts: Array<string> = [];
      let savingsAccounts: Array<string> = [];

      accountsListResult?.data?.data.forEach((item: any) => {
        if (item.cacheType === "savings") {
          savingsAccounts.push(item);
        } else if (item.cacheType === AccountType.CHECKING) {
          checkingAccounts.push(item);
        }
      });
      dispatch(setCheckingAccountList(checkingAccounts));
      dispatch(setSavingsAccountList(savingsAccounts));
    } else {
      return;
    }
  }, [accountsListResult, currentStep]);

  useEffect(() => {
    if (!selectCheckingAcc || !savingsCardRef.current) {
      return;
    }

    (savingsCardRef.current as any).scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [selectCheckingAcc]);

  const onBackHandler = async () => {
    if (!selectCheckingAcc && !selectCheckingAcc) {
      previousStep?.();
      setSelectCheckingAcc(null);
      setSelectSavingsAcc(null);
      setIsCheckingBtnActive(false);
      setIsSavingsBtnActive(false);
      updateAllIsUsedAutomationCheckingAccount(false);
    }

    if (selectCheckingAcc && !selectSavingsAcc) {
      setIsCheckingBtnActive(true);
      setSelectCheckingAcc(null);
      setAccountType({ type: AccountType.CHECKING, clicked: false });
      updateAllIsUsedAutomationCheckingAccount(false);
    }

    if (selectCheckingAcc && selectSavingsAcc) {
      setSelectSavingsAcc(null);
      setValue(null);
      setAccountType({ type: AccountType.SAVING, clicked: false });
    }
  };

  const onChangeHandler = (e: any) => {
    const val = e.target.value;
    setError(false);
    if (!val && typeof val !== "number") {
      return;
    }
    setValue(Number(val));
  };

  const saveAutomationDraftToDB = async () => {
    const automationData = {
      checkingAccountId: selectCheckingAcc?.id ?? null,
      savingAccountId: selectSavingsAcc?.id ?? null,
      fixedValue: value ?? null,
      isDraft: true,
    };
    const { user } = context;
    if (!user || isAutomationSavingToDB || !isSavedAutomationRecovered) {
      return;
    }

    await user.getIdToken().then(async (idToken: string) => {
      if (signUpAutomationId) {
        await modifyAutomation({
          body: automationData,
          idToken,
          automationId: signUpAutomationId,
          isOnboarding: true,
        });
      } else {
        await createAutomation({
          body: automationData,
          idToken,
          isOnboarding: true,
        });
      }
    });
  };

  useEffect(() => {
    const saveAutomationTimerId = saveAutomationTimerIdRef.current;
    if (saveAutomationTimerId) {
      clearTimeout(saveAutomationTimerId);
    }

    setIsAutomationDraftSavingToDB(true);

    saveAutomationDraftToDB().finally(() => {
      setIsAutomationDraftSavingToDB(false);
    });
  }, [selectCheckingAcc?.id, selectSavingsAcc?.id]);

  useEffect(() => {
    const saveAutomationTimerId = saveAutomationTimerIdRef.current;

    if (saveAutomationTimerId) {
      clearTimeout(saveAutomationTimerId);
    }

    const newTimeoutId = setTimeout(() => {
      setIsAutomationDraftSavingToDB(true);
      saveAutomationDraftToDB().finally(() => {
        setIsAutomationDraftSavingToDB(false);
      });
    }, 1500);

    saveAutomationTimerIdRef.current = newTimeoutId;

    return () => {
      if (saveAutomationTimerId) {
        clearTimeout(saveAutomationTimerId);
      }
    };
  }, [value]);

  //change automation status from DRAFT TO DISABLED
  const confirmAutomationInDB = () => {
    const saveAutomationTimerId = saveAutomationTimerIdRef.current;
    if (saveAutomationTimerId) {
      clearTimeout(saveAutomationTimerId);
    }

    const automationData = {
      checkingAccountId: selectCheckingAcc?.id ?? null,
      savingAccountId: selectSavingsAcc?.id ?? null,
      fixedValue: value ?? "",
      isDraft: false,
    };

    const { user } = context;
    if (!user) {
      return;
    }

    setIsAutomationSavingToDB(true);
    user.getIdToken().then(async (idToken: string) => {
      await modifyAutomation({
        body: automationData,
        idToken,
        automationId: signUpAutomationId,
        isOnboarding: true,
      }).then(() => {
        setIsAutomationSavingToDB(false);
      });
    });
  };

  const onNextHandler = () => {
    if (!value) {
      setError(true);
      return;
    }

    if (isAutomationDraftSavingToDB || !signUpAutomationId) {
      return;
    }

    dispatch(setBalanceMaxValue(value));
    dispatch(setCheckingAcc(selectCheckingAcc));
    dispatch(setSavingsAcc(selectSavingsAcc));

    confirmAutomationInDB();

    mixpanel("Set_fixed_balance", { "Fixed balance": value });
    nextStep?.();
  };

  if (currentStep !== 5) {
    return null;
  }

  const SelectAccText = (
    <>
      <div className="page__title">
        Connect your preferred checking account.
      </div>
      <div className="page__subtitle automation__description">
        Cache automatically transfers excess funds from this account to your
        savings, and back when needed.
      </div>
    </>
  );

  const ConnectSavingsAccText = (
    <>
      <div className="page__title">Connect your preferred savings account.</div>
      <div className="page__subtitle automation__description">
        Cache automatically transfers excess funds to this savings account from
        your checking, and back when needed.
      </div>
    </>
  );

  const FixedBalanceText = (
    <>
      <div className="page__title">Set your fixed balance.</div>
      <div className="page__subtitle automation__description">
        Determine the balance that you’d like to maintain in your checking
        account. Cache will use it to automatically transfer funds from your
        checking to your savings and vice versa.
      </div>
    </>
  );

  const onPlaidAuthSuccess = async (justConnectedAccounts: any[]) => {
    applyDefaultAccounts(justConnectedAccounts);
    setIsPlaidAuthOpen(false);
  };
  const onPlaidAuthExit = () => {
    setIsPlaidAuthOpen(false);
  };

  const isAccountListResultLoading = accountsListResult.isFetching;
  const hiddenFieldValue =
    !selectCheckingAcc && !selectSavingsAcc
      ? "checkingConnect"
      : selectCheckingAcc && !selectSavingsAcc
      ? "savingsConnect"
      : "fixedBalance";

  const isCheckingCardClicked =
    accountType.type === AccountType.CHECKING && accountType.clicked;
  const isSavingCardClicked =
    accountType.type === AccountType.SAVING && accountType.clicked;

  return (
    <Wrapper>
      <SignupNavbar
        step={currentStep}
        handler={onBackHandler}
        showBackButton={true}
      />
      <div className="page__infoContainer">
        {!selectCheckingAcc && !selectSavingsAcc && SelectAccText}
        {selectCheckingAcc && !selectSavingsAcc && ConnectSavingsAccText}
        {selectCheckingAcc && selectSavingsAcc && FixedBalanceText}

        <div className="automation__container">
          <div className="connectionButton__holder">
            <div className="automations__checkingCards">
              {isAccountListResultLoading || isPlaidAuthLoading ? (
                <AccountLoadingCard />
              ) : selectCheckingAcc ? (
                <AccountCard
                  active={isCheckingBtnActive}
                  errorCard={
                    accountErrorType === AccountType.CHECKING &&
                    isCheckingCardClicked
                  }
                  item={selectCheckingAcc}
                  type="Checking account"
                  selectHandler={() => {
                    handleModalOpen(AccountType.CHECKING);
                    setTypeAcc(AccountType.CHECKING);
                  }}
                />
              ) : (
                <ConnectionButton
                  accountType={"Checking Account"}
                  isActive={true}
                  errorCard={
                    accountErrorType === AccountType.CHECKING &&
                    isCheckingCardClicked
                  }
                  isWrongChoice={isCheckingWrong}
                  onClickHandler={() => {
                    setAccountLoadMsg("");
                    setAccountType({
                      type: AccountType.CHECKING,
                      clicked: true,
                    });
                    setAccountErrorType("");
                    setIsCheckingWrong(false);
                    setTypeAcc(AccountType.CHECKING);
                    if (checkingAccounts.length === 0) {
                      setIsPlaidAuthOpen(true);
                    } else {
                      handleModalOpen(AccountType.CHECKING);
                    }
                  }}
                />
              )}
              <div className="balanceInput__holder">
                <BalanceInput
                  error={error}
                  isdisabled={!(selectCheckingAcc && selectSavingsAcc)}
                  value={value ?? ""}
                  onChangeHandler={onChangeHandler}
                />
              </div>
              {error && (
                <div className="userName__errorMassage error__message">
                  Please set your fixed balance.
                </div>
              )}
            </div>

            <div className="arrows__icon">
              <img src={arrows} alt="arrows icon" />
            </div>

            <div className="automations__savingsCards" ref={savingsCardRef}>
              {isAccountListResultLoading || isPlaidAuthLoading ? (
                <AccountLoadingCard />
              ) : selectSavingsAcc ? (
                <>
                  <AccountCard
                    active={isSavingsBtnActive}
                    errorCard={
                      accountErrorType === "savings" && isSavingCardClicked
                    }
                    item={selectSavingsAcc}
                    type="Savings account"
                    selectHandler={() => {
                      handleModalOpen(AccountType.SAVING);
                      setIsSavingsWrong(false);
                      setTypeAcc("savings");
                    }}
                  />
                </>
              ) : (
                <ConnectionButton
                  accountType={"Savings Account"}
                  isActive={selectCheckingAcc}
                  errorCard={
                    accountErrorType === "savings" && isSavingCardClicked
                  }
                  onClickHandler={() => {
                    setAccountType({ type: AccountType.SAVING, clicked: true });
                    setAccountLoadMsg("");
                    setAccountErrorType("");
                    setIsSavingsWrong(false);
                    setTypeAcc("savings");
                    if (savingsAccounts.length === 0) {
                      setIsPlaidAuthOpen(true);
                    } else {
                      handleModalOpen(AccountType.SAVING);
                    }
                  }}
                />
              )}
              {isSavingsWrong && isSavingCardClicked && (
                <div className="wrong__choise-hide text__content">
                  The account that you selected is not a savings account. Please
                  try again. If necessary, connect to another institution.
                </div>
              )}
              {accountLoadMsg === "STRANGER_ACCOUNT" &&
                accountErrorType === "savings" &&
                isSavingCardClicked && (
                  <div className="userName__errorMassage error__message account-exist mobile-error">
                    The selected account is already associated with another
                    Cache user. Please select a different account to continue.
                  </div>
                )}
            </div>
          </div>
          {isCheckingWrong && isCheckingCardClicked && (
            <div className="wrong__choise text__content">
              The account that you selected is not a checking account. Please
              try again. If necessary, connect to another institution.
            </div>
          )}
          {isSavingsWrong && isSavingCardClicked && (
            <div className="wrong__choise text__content">
              The account that you selected is not a savings account. Please try
              again. If necessary, connect to another institution.
            </div>
          )}
          {accountLoadMsg === "STRANGER_ACCOUNT" &&
            [AccountType.CHECKING, "savings"].includes(accountErrorType) && (
              <div className="userName__errorMassage error__message account-exist desktop-error">
                The selected account is already associated with another Cache
                user. Please select a different account to continue.
              </div>
            )}
        </div>
      </div>
      {selectCheckingAcc && selectSavingsAcc && (
        <div className="addAutomation__nextButton">
          <Button
            disabled={isAutomationSavingToDB}
            onClick={onNextHandler}
            type={"primary-btn"}
          >
            Set Automation
          </Button>
        </div>
      )}

      {showCheckingAccountsModal && (
        <ModalAccountsList
          title="Checking Account"
          accountsList={checkingAccounts}
          open={showCheckingAccountsModal && !isAccountListResultLoading}
          relationType={AccountType.CHECKING}
          onClose={() => {
            handleModalClose(AccountType.CHECKING);
            setIsCheckingBtnActive(false);
            setIsSavingsBtnActive(true);
          }}
          setAccount={setSelectCheckingAcc}
          onPlaidAuthSuccess={onPlaidAuthSuccess}
          setIsPlaidAuthLoading={setIsPlaidAuthLoading}
          verifyAccountsSubtype={verifyAccountsSubtype}
        />
      )}
      {showSavingsAccountsModal && (
        <ModalAccountsList
          title="Savings Account"
          accountsList={savingsAccounts}
          open={showSavingsAccountsModal && !isAccountListResultLoading}
          relationType="savings"
          onClose={() => {
            handleModalClose(AccountType.SAVING);
            setIsSavingsBtnActive(false);
          }}
          setAccount={setSelectSavingsAcc}
          onPlaidAuthSuccess={onPlaidAuthSuccess}
          setIsPlaidAuthLoading={setIsPlaidAuthLoading}
          verifyAccountsSubtype={verifyAccountsSubtype}
        />
      )}
      <PlaidAuth
        isOpen={isPlaidAuthOpen && !isAccountListResultLoading}
        success={onPlaidAuthSuccess}
        onExit={onPlaidAuthExit}
        withoutMarkup={true}
        setIsLoading={setIsPlaidAuthLoading}
        accountLoadErrorCallback={accountLoadErrorCallback}
        preConnectedAccountsCB={verifyAccountsSubtype}
        relationType={typeAcc}
      />
      <input
        type="hidden"
        id="abandonedOnboardingStage"
        value={hiddenFieldValue}
      />
    </Wrapper>
  );
};

export default AddAutomation;
