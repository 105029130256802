import React from "react";
import SignupHeader from "./SignupHeader/SignupHeader";
import Footer from "./Footer";

function SimpleWrapper({ children }: any) {
  return (
    <div>
      <SignupHeader position="sticky"/>
      <div>{children}</div>
      <Footer/>
    </div>
  );
}

export default SimpleWrapper;
