import "./accountCard.css";
import getBankLogo from "../../helpers/getBankIcon";
import disconnected_icon from "../../assets/disconnect_acc_icon.svg";
import connected_icon from "../../assets/connected_icon.svg";

function AccountCard({
  item,
  type,
  styles = {},
  selectHandler = () => {},
  active = true,
  disconnected = false,
  reconnectionFlow = false,
  showOnlyDisconnectedIcon = false,
  editFlow = false,
  errorCard = false
}: any) {
  const bankLogo = getBankLogo(item?.institution?.logo, item?.institution?.id);
  const mask = item?.mask;
  const availableBalance = item?.availableBalance;

  return (
    <button
      style={editFlow ? { cursor: "pointer" } : { cursor: "default" }}
      className={
        `${!active
          ? "accountCard accountCard-disabled"
          : disconnected
          ? "accountCard disconnected_button"
          : "accountCard"} ${errorCard ? "card-with-error" : ""}`
      }
      onClick={() => selectHandler()}
      disabled={!active}
    >
      <div className="accountCard__info">
        <div className="accountCard__type-holder">
          <div className="accountCard__type">{type}</div>
          {reconnectionFlow ? (
            disconnected ? (
              <img src={disconnected_icon} alt="Disconnected Icon" />
            ) : !showOnlyDisconnectedIcon ? (
              <img src={connected_icon} alt="Connected Icon" />
            ) : (
              ""
            )
          ) : (
            <></>
          )}
        </div>
        <div className="accountCard__data">
          <div className="accountCard__data-bank">
            <div className="accountCard__number">Account {mask}</div>
            <div className="accountCard__value">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(availableBalance ?? 0)}
            </div>
          </div>
          <div className="accountCard__bankLogo">
            <img src={bankLogo} alt="Bank Logo" />
          </div>
        </div>
      </div>
    </button>
  );
}

export default AccountCard;
