import "./backButton.css";
import { MdArrowBackIosNew } from "react-icons/md";
// @ts-ignore
import back_icon from "../../assets/back_icon.svg";
import {useMixpanel} from "../../helpers/mixpanel";

type OnBack = {
  onBack: () => void;
};

function BackButton({ onBack }: OnBack) {
  const mixpanel = useMixpanel();

  return (
    <>
      <button
        className="btn-back"
        onClick={() => {
          onBack();
          mixpanel("Clicked_Back");
        }}
      >
        <img src={back_icon} alt="Back Icon" />
        Back
      </button>
    </>
  );
}

export default BackButton;
