import "./BalanceInput.css";
import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}
const MAX_LIMIT = 999999;
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values:any) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                decimalScale={0}
                isAllowed={(values) => {
                    const { floatValue } = values;
                    return floatValue !== undefined ? floatValue > 0 && floatValue <= MAX_LIMIT : true;
                }}
            />
        );
    },
);

function BalanceInput({ error, isdisabled, value, onChangeHandler }: any) {
  const [showAdornment, setShowAdornment] = useState(false);
  return (
    <div className= {`balanceInput__container ${isdisabled ? "disabled" : ""}`}>
      <TextField
        fullWidth
        label="Fixed Balance"
        variant="filled"
        disabled={isdisabled}
        error={error}
        onFocus={() => setShowAdornment(true)}
        onBlur={(e:any) => setShowAdornment(!!e.target.value)}
        value={value}
        onChange={onChangeHandler}
        InputProps={{
            inputComponent: NumericFormatCustom as any,
            startAdornment: showAdornment || value ? <InputAdornment position="start">$</InputAdornment> : null,
            sx: {
                "&.MuiInputBase-root": {
                    border: "1px solid #39848A",
                    height: 52,
                    backgroundColor: "#303030",
                    borderRadius: "8px",
                },
                "&.MuiInputBase-root.Mui-error": {
                    border: "1px solid #F66"
                },
                "&.MuiInputBase-root.Mui-disabled": {
                    border: 0,
                },
                "&.MuiInputBase-root.Mui-focused": {
                    border: "2px solid #39848A",
                },
                "&.MuiInputBase-root.Mui-error.Mui-focused": {
                    border: "2px solid #F66",
                },
                "&.MuiInputBase-input": {
                    padding: "16px"
                },
                "&.MuiInputBase-root::before": {
                    borderBottom: 0,
                },
                "&.MuiInputBase-root:hover::before": {
                    borderBottom: 0,
                },
                "&.MuiInputBase-root.Mui-focused::after": {
                    borderBottom: 0,
                },
                "& .MuiFormLabel-root": {
                    fontSize: '16px',
                },
                "&.MuiInputBase-root.MuiFilledInput-root": {
                    paddingLeft: '16px',
                },
                "& .MuiInputAdornment-root": {
                    marginRight: 0,
                },
                "& .MuiInputAdornment-root p": {
                    color: "#FAFAFA",
                },
            },
        }}
        InputLabelProps={{
            sx: {
                "&.MuiInputLabel-root": {
                    color: "#FAFAFA",
                    fontSize: "16px",
                    fontFamily: "Open Sans,sans-serif",
                    maxWidth: "100%",
                    left: 5
                },
                "&.MuiInputLabel-shrink": {
                    fontSize: "8px",
                    color: "#39848A",
                    transform: "translate(12px, 7px) scale(1)"
                },
                "&.MuiInputLabel-shrink.Mui-error.Mui-focused": {
                    color: "#F66",
                }
            }
        }}
      />
    </div>
  );
}

export default BalanceInput;
