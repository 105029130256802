import "./testimonials.css";
import { Box, Container, Typography } from "@mui/material";
import TestimonialsCarousel from "../TestimonialsCarousel/TestimonialsCarousel";
import { TestimonialsCard } from "../TestimonialsCard/TestimonialsCard";
import { useGetThemesQuery } from "../../api/apiThemes";
import StarIcon from "@mui/icons-material/Star";

interface TestimonialProps {
  isMobile: boolean;
  isTablet: boolean;
}

function Testimonials({ isMobile, isTablet }: TestimonialProps) {
  const testimonials = useGetThemesQuery({ themeType: "testimonials" });

  const reviewTitle = testimonials.currentData?.title;
  const reviewSubtitle = testimonials.currentData?.subtitle;
  const testimonialsCardData = testimonials.currentData?.themeData;

  const fiveStars = Array.from({ length: 5 }, (_, index) => (
    <div key={index} className="fiveStars">
      <StarIcon
        key={index}
        viewBox={isMobile || isTablet ? "0 3 24 24" : "0 3 26 26"}
        style={{
          fontSize: `${isMobile || isTablet ? "2.677rem" : "3.313rem"}`,
        }}
        color={"primary"}
      />
    </div>
  ));

  const testimonialsCards =
    isMobile || isTablet ? (
      <TestimonialsCarousel
        items={testimonialsCardData}
        isMobile={isMobile}
        isTablet={isTablet}
      />
    ) : (
      testimonialsCardData?.map((testimonial: any, index: number) => (
        <TestimonialsCard
          content={testimonial.content}
          name={testimonial.name}
          key={index}
        />
      ))
    );

  return (
    <Container>
      <Box>
        <Typography
          className="reviewTitle"
          variant="h1"
          textAlign="center"
          sx={{ textWrap: "wrap", whiteSpace: "normal", wordBreak: "normal" }}
        >
          {reviewTitle}
        </Typography>
      </Box>
      <Box>
        <Typography
          className="reviewSubtitle"
          variant="h3"
          textAlign={"center"}
          sx={{ textWrap: "wrap", whiteSpace: "normal", wordBreak: "normal" }}
        >
          {reviewSubtitle}
        </Typography>
      </Box>
      <Box
        className="fiveStarsBox"
        textAlign="center"
        sx={{
          textWrap: "wrap",
          whiteSpace: "normal",
          wordBreak: "normal",
        }}
      >
        {fiveStars}
      </Box>
      <Box display="flex" flexWrap="wrap" gap={2} justifyContent={"center"}>
        {testimonialsCards}
      </Box>
    </Container>
  );
}

export default Testimonials;
