import { ReactNode } from "react";
import "./button.css";

// use in Create Account Flow

// prop type:
// black-btn --> #303030 background, #FAFAFA text
// white-btn --> #FAFAFA background, #303030 text
// prop style: for inline style

// dark theme
// primary-btn --> #303030 background, #FAFAFA text
// secondary-btn ---> #303030 background, #FAFAFA text

type ButtonProp = {
  children: ReactNode
  onClick?: () => void
  type?: string
  style?: Object,
  disabled?: boolean
}

function Button({ children, onClick, type = "", style = {}, disabled = false }: ButtonProp) {
  return (
    <>
      <button
        className={disabled ? ["btn", type, 'disabled'].join(" ") : ["btn", type].join(" ")}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
          {children}
      </button>
    </>
  );
}

export default Button;
