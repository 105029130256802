import "./testimonialsCarousel.css";
import { useEffect, useState } from "react";
import { Box, Button, Slide, Stack } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { TestimonialsCard } from "../TestimonialsCard/TestimonialsCard";

export default function TestimonialsCarousel({
  items,
  isMobile,
  isTablet,
}: any) {
  const [currentPage, setCurrentPage] = useState(0);
  const [slideDirection, setSlideDirection] = useState<
    "right" | "left" | undefined
  >("right");

  const handleNextPage = () => {
    setSlideDirection("left");
    setCurrentPage(
      (prevPage) => (prevPage + 1) % Math.ceil(items.length / cardsPerPage),
    );
  };

  const handlePrevPage = () => {
    setSlideDirection("right");
    setCurrentPage((prevPage) =>
      prevPage === 0
        ? Math.ceil(items.length / cardsPerPage) - 1
        : prevPage - 1,
    );
  };

  let cardsPerPage: number = items?.length || 0;
  if (isMobile) {
    cardsPerPage = 1;
  } else if (isTablet) {
    cardsPerPage = 2;
  }
  return (
    <Box className="boxCarousel">
      <Box
        sx={{
          display: "flex",
          justifyItems: "center",
        }}
      >
        <Button className="testimonials_btn" onClick={handlePrevPage}>
          <div
            className="round-button"
            style={{
              color: "black",
            }}
          >
            <NavigateBeforeIcon style={{ color: "white" }} />
          </div>
        </Button>
      </Box>
      <Box
        display={"flex"}
        flexWrap="wrap"
        justifyContent={"center"}
        sx={{ width: isMobile ? "200px" : "550px", height: "100%" }}
      >
        {items?.map((item: any, index: number) => (
          <Box
            key={`${item}-${index}`}
            sx={{
              display: currentPage === index ? "block" : "none",
            }}
          >
            <Slide direction={slideDirection} in={currentPage === index}>
              <Stack
                spacing={2}
                direction="row"
                alignContent="center"
                justifyContent="center"
                sx={{ width: "100%", height: "100%" }}
              >
                {items
                  ?.slice(
                    index * cardsPerPage,
                    index * cardsPerPage + cardsPerPage,
                  )
                  .map((item: any, index: number) => (
                    <Box key={index} gap={4}>
                      <TestimonialsCard
                        content={item.content}
                        name={item.name}
                      />
                    </Box>
                  ))}
              </Stack>
            </Slide>
          </Box>
        ))}
      </Box>
      <Button className="testimonials_btn" onClick={handleNextPage}>
        <div className="round-button" style={{ color: "black" }}>
          <NavigateNextIcon style={{ color: "white" }} />
        </div>
      </Button>
    </Box>
  );
}
