import React from "react";
import SignupHeader from "./SignupHeader/SignupHeader";

function Wrapper({ children }: any) {
  return (
    <div className="mainContainer">
      <SignupHeader />
      <div className="container">{children}</div>
    </div>
  );
}

export default Wrapper;
