import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  userData: null,
  showDisconnectNotification: true,
  errorDisconnect: false,
  firstEnter: false,
};

export const sliceUserNotification = createSlice({
  name: "userNotifications",
  initialState: initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      state.userData = payload;
    },
    setUserNotifications: (state, { payload }) => {
      if (state.userData?.notifications) {
        state.userData.notifications = payload;
      }
    },
    setShowReconnectNotification: (state, { payload }) => {
      state.showDisconnectNotification = payload;
    },
    setReconnectError: (state, { payload }) => {
      state.errorDisconnect = payload;
    },

    setFirstEnter: (state, { payload }) => {
      state.firstEnter = payload;
    },
  },
});
export const {
  setUserData,
  setUserNotifications,
  setShowReconnectNotification,
  setReconnectError,
  setFirstEnter,
} = sliceUserNotification.actions;
export default sliceUserNotification.reducer;
