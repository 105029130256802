import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export function useOpenTabsCounter() {
  const [allowRewrite, setAllowRewrite] = useState(true);
  const [tabsBeforeUnload, setTabsBeforeUnload] = useState<string | null>(null);

  let currentTabId = sessionStorage.getItem("tabId");
  if (typeof currentTabId !== "string" && allowRewrite) {
    const newTabId = uuidv4();
    sessionStorage.setItem("tabId", newTabId);
    currentTabId = newTabId;
  }

  let tabsOpenStringified = localStorage.getItem("tabsOpen");
  if (typeof tabsOpenStringified !== "string" && allowRewrite) {
    const stringifiedTabsArray = JSON.stringify([currentTabId]);
    localStorage.setItem("tabsOpen", stringifiedTabsArray);
    tabsOpenStringified = stringifiedTabsArray;
  }
  let tabsOpen = JSON.parse(tabsOpenStringified ?? "[]");

  if (
    Array.isArray(tabsOpen) &&
    !tabsOpen.includes(currentTabId) &&
    allowRewrite
  ) {
    const newOpenTabsArray = [...tabsOpen, currentTabId];
    localStorage.setItem("tabsOpen", JSON.stringify(newOpenTabsArray));
    tabsOpen = newOpenTabsArray;
  }

  window.onunload = function (e) {
    sessionStorage.removeItem("tabId");

    const newTabsOpenStringified = localStorage.getItem("tabsOpen");
    const newTabsOpen = JSON.parse(newTabsOpenStringified ?? "[]");
    const newTabsOpenWithoutCurrent = newTabsOpen.filter(
      (tabId: string) => tabId !== currentTabId,
    );
    setTabsBeforeUnload(newTabsOpen);
    localStorage.setItem("tabsOpen", JSON.stringify(newTabsOpenWithoutCurrent));
    setAllowRewrite(false);
  };

  const getOpenTabsCount = () => {
    let tabsOpen = tabsBeforeUnload;

    if (!tabsBeforeUnload) {
      const stringifiedTabs = localStorage.getItem("tabsOpen");
      tabsOpen = JSON.parse(stringifiedTabs ?? "[]");
    }
    return tabsOpen?.length;
  };

  return [getOpenTabsCount];
}

// export function useOpenTabsCounter() {
//   const [allowRewrite, setAllowRewrite] = useState(true);
//   const [countBeforeUnload, setCountBeforeUnload] = useState<string | null>(
//     null,
//   );

//   // define increment counter part
//   const tabsOpen = localStorage.getItem("tabsOpen");

//   if (allowRewrite) {
//     if (typeof tabsOpen !== "string" || +tabsOpen < 1) {
//       localStorage.setItem("tabsOpen", "1");
//     } else {
//       localStorage.setItem("tabsOpen", `${+tabsOpen + +1}`);
//     }
//     setAllowRewrite(false);
//   }

//   // define decrement counter part
//   window.onunload = function (e) {
//     const newTabCount = localStorage.getItem("tabsOpen");
//     if (typeof newTabCount === "string") {
//       setCountBeforeUnload(newTabCount);
//       localStorage.setItem("tabsOpen", `${+newTabCount - +1}`);
//     }
//   };

//   const getOpenTabsCount = () => {
//     const tabsOpen = countBeforeUnload ?? localStorage.getItem("tabsOpen");
//     return Number(tabsOpen);
//   };

//   return [getOpenTabsCount];
// }
