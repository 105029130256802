import { useSelector } from "react-redux";
import { StepWizardChildProps } from "react-step-wizard";

import Button from "../../elements/Button/Button";
import { RootState } from "../../redux/store";


import success_automation from "../../assets/success_automation.png";

// import "./automationFeedback.css";
import { useNavigate } from "react-router-dom";
import HeaderMenu from "../../elements/HeaderMenu/HeaderMenu";

function Feedback() {
  const navigation = useNavigate();

  const checkingAccount: any = useSelector<RootState>(
    (state) => state.userAutomation.checkingAccount,
  );
  const balance: any = useSelector<RootState>(
    (state) => state.userAutomation.maxValue,
  );
  const checkingAvailableBalance = parseFloat(
    Number(checkingAccount?.availableBalance).toFixed(2),
  );
  const fixedBalance = parseFloat(Number(balance).toFixed(2));

  const onBackHandler = () => {};

  const onNextHandler = () => {
    navigation("/automations");
  };

  const balanceStatus = checkingAvailableBalance - fixedBalance;
  const CheckingAccDeficitLess = (
    <>
      <div className="feedback__subtitle">
        You currently have ${checkingAvailableBalance} in your checking account.
        Based on the fixed balance that you chose, there is a deficit of $
        {(fixedBalance - checkingAvailableBalance).toFixed(2)} in your checking
        account.
      </div>
      <div className="feedback__subtitle-short">
        If this deficit exceeds $250, we'll automatically transfer funds from
        your savings back into your checking, to reach your fixed balance.
      </div>
    </>
  );

  const CheckingAccDeficit = (
    <>
      <div className="feedback__subtitle">
        You currently have ${checkingAvailableBalance} in your checking account.
        Based on the fixed balance that you chose, there is a deficit of $
        {(fixedBalance - checkingAvailableBalance).toFixed(2)} in your checking
        account.
      </div>
      <div className="feedback__subtitle-short">
        If this deficit exceeds $250, we'll automatically transfer funds from
        your savings to replenish it.
      </div>
    </>
  );
  const CheckingAccExcessLess = (
    <>
      <div className="feedback__subtitle">
        You currently have ${checkingAvailableBalance} in your checking account.
        Based on the fixed balance that you chose, there is an excess of $
        {(checkingAvailableBalance - fixedBalance).toFixed(2)} in your checking
        account.
      </div>
      <div className="feedback__subtitle-short">
        When this excess amount reaches $250 or more, we'll automatically
        transfer it into your savings.
      </div>
    </>
  );

  const CheckingAccExcess = (
    <>
      <div className="feedback__subtitle">
        You currently have ${checkingAvailableBalance} in your checking account.
        Based on the fixed balance that you chose, there is an excess of $
        {(checkingAvailableBalance - fixedBalance).toFixed(2)} in your checking
        account.
      </div>

      <div className="feedback__subtitle-short">
        Once you complete onboarding, we’ll automatically transfer this amount
        into your savings.
      </div>
    </>
  );

  return (
    <>
    <HeaderMenu />
      <div className="container">
        <div className="page__infoContainer">
          <div className="page__title">
            Great job, your account is ready for automated transfers.
          </div>
          <div className="success_automation_icon">
            <img src={success_automation} alt="success automation icon" />
          </div>
          {balanceStatus <= -250 && CheckingAccDeficit}
          {balanceStatus > -250 && balanceStatus < 0 && CheckingAccDeficitLess}
          {balanceStatus >= 0 && balanceStatus <= 250 && CheckingAccExcessLess}
          {balanceStatus > 250 && CheckingAccExcess}
        </div>
        <button className="addAutomation" onClick={() => navigation('/add-automation')}>
          <span>+</span> Add Another Automation
        </button>
        <Button onClick={onNextHandler} type={"primary-btn"}>
          Next
        </Button>
      </div>
    </>
  );
}

export default Feedback;
