import { Box, Button, Container, Grid, Typography } from "@mui/material";
import arrowIcon from "../../assets/arrowIcon.svg";
import classNames from "classnames";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Answer, FaqData, SectionData } from "./Faq";
interface SectionProps {
  id: string;
  index: number;
  handleClick: (index: number) => void;
  section: boolean[];
  faq: SectionData;
  answer: string | JSX.Element;
}

function FaqQuestionsSection() {
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash && titleRef.current) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash]);

  const data: FaqData[] = [
    {
      general: [
        {
          question: "What does Cache do? ",
          answer: [
            {
              text: `Cache is a financial wellness tool that helps you enhance your savings with minimal effort. We do so by automatically transferring funds between your checking and savings accounts in a manner that maximizes your savings and the interest that you earn. The transfers are carried out automatically to maintain the balance in your checking account at the fixed balance that you set:`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `When you have excess funds beyond your fixed balance, Cache initiates a transfer from your checking account to your savings account.`,
              type: "bullet",
              paragraph: "2",
            },
            {
              text: `When your funds fall below your fixed balance, Cache initiates a transfer from your savings account to your checking account.`,
              type: "bullet",
              paragraph: "2",
            },
            {
              text: `This ensures that you maximize your savings, while being able to cover all of your regular expenses, and frees you from routinely spending time and effort to manage your accounts. Ready to try?`,
              type: "string",
              paragraph: "3",
            },
            {
              text: `Start here.`,
              type: "link",
              link: "/signup",
              paragraph: "3",
            },
            {
              text: `Cache is constantly adding more automations and features to enhance your financial wellness and save you time and money.`,
              type: "string",
              paragraph: "4",
            },
          ],
        },
        {
          question: "How do I open a Cache account? ",
          answer: [
            {
              text: `Opening a Cache account is simple and takes minutes to complete. During our easy onboarding process, you'll be asked to enter a few details (such as name, e-mail, address, etc) and to set up an automation that will allow you to save more money effortlessly. To set up your automation, you will connect the bank accounts of your choice securely, and then determine your fixed balance, that is, the balance you want to keep in your checking account at all times.`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `We take it from there. Cache ensures your fixed balance is maintained by automatically moving money between your checking and savings accounts. This helps you maximize both the amount of money that you set aside in savings and the interest that you earn on it, while ensuring that you meet your regular expenses.`,
              type: "string",
              paragraph: "2",
            },
          ],
        },
        {
          question: "Are there any fees to use Cache? ",
          answer: `Cache is completely free for the first 10,000 people who sign up. After that, there may be a small fee to use Cache. Any fees will be presented in advance clearly and transparently.`,
        },
        {
          question: "Who can open a Cache account?",
          answer:
            "Cache is intended for US residents above the age of 18 with accounts at US banks and financial institutions.",
        },
        {
          question: "Is Cache a bank account?",
          answer:
            "Cache is not a bank account. Cache offers a tool that automates transfers between your existing checking and savings accounts, per your preferences. In order to use Cache, you will need to have, or open, both a checking account and a savings account at a US financial institution of your choice. The checking account and savings account do not need to be held at the same institution.",
        },
        {
          question: "Is my information safe with Cache?",
          answer: [
            {
              text: `Cache prioritizes the security and privacy of your personal and financial information by implementing industry-standard technological, organizational, administrative and physical safeguards crafted specifically to ensure the confidentiality and integrity of your information.`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `It's important to note that when you set up an automation, you establish a secure connection between Cache and your bank. Cache does not have access to, and in any case does not store, any of the login details required by your bank. Furthermore, as an additional layer of protection, Cache's platform was built to ensure that it can only facilitate transfers between accounts that belong to you. We are not authorized, and technically cannot, initiate transfers to any external accounts.`,
              type: "string",
              paragraph: "2",
            },
            {
              text: `Read our full Terms of Service`,
              type: "string",
              paragraph: "3",
            },
            {
              text: `here`,
              type: "link",
              link: "/terms-of-service",
              paragraph: "3",
            },
            {
              text: `and our full Privacy Policy`,
              type: "string",
              paragraph: "3",
            },
            {
              text: `here.`,
              type: "link",
              link: "/privacy-policy",
              paragraph: "3",
            },
          ],
        },
      ],
    },
    {
      bankingAndTransactions: [
        {
          question: "Do I need to open a new bank account to use Cache?",
          answer:
            "No. Cache securely connects to your checking and savings accounts at the existing financial institutions that you use. We work with over 11,000 financial institutions. You do not need to open new bank accounts to use Cache, and Cache itself is not a bank account.",
        },
        {
          question: "Does Cache have a minimum transfer amount?",
          answer: `Cache's minimum transfer amount is $250. This means that we'll automatically initiate a transfer from checking to savings when your checking account balance exceeds the fixed balance that you set by at least $250, and we'll automatically initiate a transfer from savings to checking when your checking account balance dips below the fixed balance by at least $250.`,
        },

        {
          question: "Does Cache provide overdraft protection?",
          answer:
            "Cache does not provide overdraft protection. However, Cache's two-way financial automation may serve a similar purpose by keeping the balance in your checking account constant at the 'fixed balance' that you determine. When the balance in your checking account exceeds the 'fixed balance', excess funds are transferred to your savings account to maximize the interest that you earn, and when the balance in your checking account falls below the 'fixed balance', the necessary funds are transferred back from your savings account, ensuring that you're able to cover regular expenses. For this reason, we recommend that you set your fixed balance to more-or-less equal your monthly expenses.",
        },
        {
          question: "Which bank accounts can I connect to Cache?",
          answer:
            "Cache utilizes Plaid, a leading provider of bank connectivity solutions, to securely connect to over 11,000 financial institutions. These encompass the vast majority of banks and credit unions operating in the United States, including all major banks.",
        },
        {
          question:
            "My bank account has disconnected from Cache. What should I do?",
          answer: [
            {
              text: `Cache relies on Plaid, a leading provider of bank connectivity solutions, to securely connect to over 11,000 financial institutions. Though these connections are reliable and safe, they may disconnect from time to time for a number of reasons, including technical difficulties, bank network maintenance, and a change in credentials (e.g. you change your bank username/password, or enable multi-factor authentication).`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `If your bank account is disconnected, your automation will pause and we will notify you immediately. Follow the steps in your account dashboard or settings to reconnect.`,
              type: "string",
              paragraph: "2",
            },
            {
              text: `If you are experiencing persistent disconnection issues, please e-mail our customer support team at`,
              type: "string",
              paragraph: "3",
            },
            {
              text: `support@mycache.ai`,
              type: "link",
              paragraph: "3",
              link: "mailto:support@mycache.ai",
            },
            { text: `for assistance.`, type: "string", paragraph: "1" },
          ],
        },
        {
          question: "How does Cache transfer funds between my accounts?",
          answer:
            "Cache carries out industry-standard ACH (Automated Clearing House) transfers to move funds between your checking and savings accounts electronically. ACH transfers are only available between bank accounts within the United States.",
        },
        {
          question: "I'm unable to connect my bank account. What should I do?",
          answer: [
            {
              text: `From time to time, you may experience challenges in connecting your bank accounts to Cache. In most cases, we're able to resolve the issue quickly. Please e-mail our customer support team at`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `support@mycache.ai`,
              type: "link",
              link: "mailto:support@mycache.ai",
              paragraph: "1",
            },
            { text: `for assistance.`, type: "string", paragraph: "1" },
          ],
        },
      ],
    },
    {
      miscellaneous: [
        {
          question: `How can I make the most of my Cache automation?`,
          answer: [
            {
              text: `Cache is a powerful tool for boosting your savings with zero effort and no risk. This in itself is already a major step towards financial security and prosperity. We recommend taking things a step further by combining Cache with a high yield savings account. A high-yield savings account is the same as a regular savings account, except that it earns a much higher interest rate. Cache works with 11,000 financial institutions, and you can easily connect your existing high-yield savings account to Cache.`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `If you do not have a high-yield savings account, or want to open another one, we have partnered with`,
              type: "string",
              paragraph: "2",
            },
            {
              text: `Laurel Road`,
              type: "bold",
              paragraph: "2",
            },
            {
              text: `, the digital arm of KeyBank (a top 25 US bank), to bring you a special offer: a high-yield savings account that pays a 5% Annual Percentage Yield (APY) on your savings with no minimum, no tiers, and no fees - that's over 11x the average APY offered by traditional savings accounts.`,
              type: "string",
              paragraph: "2",
            },
            {
              text: `Click here`,
              type: "link",
              link: "https://www.laurelroad.com/partnerships/cache/?utm_source=cache&utm_medium=partner&utm_campaign=cachehys",
              paragraph: "2",
            },
            {
              text: `to learn more and open a Cache + Laurel high yield savings account.`,
              type: "string",
              paragraph: "2",
            },
          ],
        },
        {
          question: `How can I connect my Laurel Road High Yield Savings account to Cache?`,
          answer: [
            {
              text: `In the`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `Laurel Road online banking portal`,
              type: "link",
              link: "https://laurelroad.key.com/ibxolb/login/index.html",
              paragraph: "1",
            },
            {
              text: `, look for`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `“Third-Party Access”`,
              type: "bold",
              paragraph: "1",
            },
            {
              text: `under your`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `User Profile`,
              type: "bold",
              paragraph: "1",
            },
            {
              text: `settings and turn the toggle to "On". Note that this feature is currently available only on the web and is not available through the mobile app.`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `After that, head back to your Cache account and follow the instructions for connecting a high-yield savings account.`,
              type: "string",
              paragraph: "2",
            },
          ],
        },
        {
          question: `I want to spread the word about Cache! Do you have an affiliate program, referral program, or partnership program?`,
          answer: [
            {
              text: `We're grateful you're excited about Cache, and we're always looking for ways to grow.`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `Cache has partnered with numerous organizations and individuals interested in giving their networks access to financial tools. Please e-mail`,
              type: "string",
              paragraph: "2",
            },
            {
              text: `team@mycache.ai`,
              type: "link",
              link: "mailto:team@mycache.ai",
              paragraph: "2",
            },
            {
              text: `to tell us about your needs, and we'll be happy to partner on a campaign, event, or program.`,
              type: "string",
              paragraph: "2",
            },
          ],
        },
        {
          question: `Does Cache also do [insert wishlist here]?`,
          answer: [
            {
              text: `We're delighted with our users' enthusiastic response to our first automation module.`,
              type: "string",
              paragraph: "1",
            },
            {
              text: `Our team is hard at work fulfilling Cache's ultimate vision of building a fully-automated financial platform for households, including tools to improve saving, spending, credit card management, budgeting, loan repayments, bill pay, tax planning, and much more. Want to tell us what we should launch next? E-mail us at `,
              type: "string",
              paragraph: "2",
            },
            {
              text: `team@mycache.ai.`,
              type: "link",
              link: "mailto:team@mycache.ai",
              paragraph: "2",
            },
          ],
        },
      ],
    },
  ];
  const [generalOpenQuestions, setGeneralOpenQuestions] = useState(
    Array.from({ length: data[0]?.general?.length || 1 }, () => false),
  );
  const [bankingAndTxOpenQuestions, setBankingAndTxOpenQuestions] = useState(
    Array.from(
      { length: data[1]?.bankingAndTransactions?.length || 1 },
      () => false,
    ),
  );
  const [miscellaneousOpenQuestions, setMiscellaneousOpenQuestions] = useState(
    Array.from({ length: data[2]?.miscellaneous?.length || 1 }, () => false),
  );

  const handleClickGeneralQuestion = (index: number) => {
    setGeneralOpenQuestions((prev) =>
      prev.map((item, id) => {
        return id === index ? !item : item;
      }),
    );
  };

  const handleClickBankingQuestion = (index: number) => {
    setBankingAndTxOpenQuestions((prev) =>
      prev.map((item, id) => {
        return id === index ? !item : item;
      }),
    );
  };

  const handleClickMiscellaneousQuestion = (index: number) => {
    setMiscellaneousOpenQuestions((prev) =>
      prev.map((item, id) => {
        return id === index ? !item : item;
      }),
    );
  };

  const getAnswerElements = (answerArr: Answer[]) => {
    let paragraph = "1";
    return answerArr.map((item, index: number) => {
      const isNewParagraph = Number(item.paragraph) > Number(paragraph);
      paragraph = isNewParagraph ? item.paragraph : paragraph;
      return (
        <Fragment key={index}>
          {isNewParagraph &&
            index > 0 &&
            answerArr[index - 1].type !== "bullet" && <br />}
          {index > 0 && !item.text.startsWith(",") && " "}
          {item.type === "link" && item.link ? (
            <Link className="answer_text_link" key={item.text} to={item.link}>
              {item.text}
            </Link>
          ) : item.type === "bullet" ? (
            <li className="answer_bullet">
              <span className={"answer_text"}>{item.text}</span>
            </li>
          ) : item.type === "bold" ? (
            <b className={classNames("answer_text", "answer_text_bold")}>
              {item.text}
            </b>
          ) : (
            <span className="answer_text" key={item.text}>
              {item.text}
            </span>
          )}
        </Fragment>
      );
    });
  };

  const generalFAQs = data[0].general?.map(
    (faq: SectionData, index: number) => {
      const answer =
        typeof faq.answer === "object" ? (
          <Typography
            className="answer_text"
            key={`${index.toString()}-answer-element`}
          >
            {getAnswerElements(faq.answer)}
          </Typography>
        ) : (
          <Typography
            className="answer_text"
            key={`${index.toString()}-answer`}
          >
            {faq.answer}
          </Typography>
        );
      return (
        <Section
          key={`${index}-general`}
          id={`${index}-general`}
          index={index}
          section={generalOpenQuestions}
          answer={answer}
          faq={faq}
          handleClick={handleClickGeneralQuestion}
        />
      );
    },
  );

  const miscellaneousFAQs = data[2].miscellaneous?.map(
    (faq: SectionData, index: number) => {
      const answer =
        typeof faq.answer === "object" ? (
          <Typography
            className="answer_text"
            key={`${index.toString()}-answer-element`}
          >
            {getAnswerElements(faq.answer)}
          </Typography>
        ) : (
          <Typography
            className="answer_text"
            key={`${index.toString()}-answer`}
          >
            {faq.answer}
          </Typography>
        );
      return (
        <Section
          key={`${index}-miscellaneous`}
          id={`${index}-miscellaneous`}
          index={index}
          section={miscellaneousOpenQuestions}
          answer={answer}
          faq={faq}
          handleClick={handleClickMiscellaneousQuestion}
        />
      );
    },
  );

  const bankingAndTransactionsFAQs = data[1].bankingAndTransactions?.map(
    (faq: any, index: number) => {
      const answer =
        typeof faq.answer === "object" ? (
          <Typography
            className="answer_text"
            key={`${index.toString()}-answer-element`}
          >
            {getAnswerElements(faq.answer)}
          </Typography>
        ) : (
          <Typography
            className="answer_text"
            key={`${index.toString()}-answer`}
          >
            {faq.answer}
          </Typography>
        );
      return (
        <Section
          key={`${index}-banking`}
          id={`${index}-banking`}
          index={index}
          section={bankingAndTxOpenQuestions}
          answer={answer}
          faq={faq}
          handleClick={handleClickBankingQuestion}
        />
      );
    },
  );

  return (
    <Box>
      <Container>
        <Grid id={"1-container"} container>
          <Grid id={"2-item"} item>
            <Typography
              ref={titleRef}
              className="title_section_FAQ"
              variant="h1"
              id="general"
            >
              General
            </Typography>
          </Grid>
        </Grid>
        <Box className="question_and_answer_section">{generalFAQs}</Box>

        <Box className="box_section_FAQ">
          <Grid id={"2-container"} container>
            <Grid id={"1-item"} item>
              <Typography
                ref={titleRef}
                className="title_section_FAQ"
                variant="h1"
                id="banking-transactions"
              >
                Banking & Transactions
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className="question_and_answer_section">
          {bankingAndTransactionsFAQs}
        </Box>

        <Box className="box_section_FAQ">
          <Grid id={"2-container"} container>
            <Grid id={"1-item"} item>
              <Typography
                ref={titleRef}
                className="title_section_FAQ"
                variant="h1"
                id="miscellaneous"
              >
                Miscellaneous
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box className="question_and_answer_section">{miscellaneousFAQs}</Box>
      </Container>
    </Box>
  );
}

export default FaqQuestionsSection;

function Section({
  id,
  index,
  handleClick,
  section,
  faq,
  answer,
}: SectionProps) {
  return (
    <Grid key={`${id}-container-1`} container>
      <Grid key={`${id}-item-1`} item>
        <Box key={`${id}-questionRow`} display={"flex"}>
          <Button
            className="containerBox"
            sx={{ padding: 0, minWidth: "50px", pointerEvents: "auto" }}
            onClick={() => handleClick(index)}
          >
            <img
              src={arrowIcon}
              alt="Arrow"
              className={classNames(!section[index] && "rotatedIcon", "icon")}
            />
            <Typography className="questionFaq" key={`${id}-question`}>
              {faq.question}
            </Typography>
          </Button>
        </Box>

        {section[index] && <Box key={`${id}-answer`}>{answer}</Box>}
      </Grid>
    </Grid>
  );
}
