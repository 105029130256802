import { CircularProgress } from "@mui/material";

const AccountLoadingCard = () => {
  return (
    <button
      style={{ cursor: "default" }}
      className={"accountCard accountCard-disabled"}
      disabled={true}
    >
      <CircularProgress />
    </button>
  );
};

export default AccountLoadingCard;
