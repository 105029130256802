import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { styled } from '@mui/material/styles';

const DateBirthField = styled((props: any) => {
  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateField
            InputProps={{
                disableUnderline: true
            }}
            style={{
                height: 52,
            }}
            {...props}
        />
      </LocalizationProvider>
  );
})(({ theme }) => ({
    '& .MuiFilledInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#303030',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        border: '1px solid #000000',
        '& .MuiInputBase-input': {
            paddingLeft: 16,
            paddingTop: 21
        },
        '&:hover': {
            backgroundColor: '#303030',
        },
        '&.Mui-focused': {
            backgroundColor: '#303030',
            border: '1px solid #39848a'
        },
        '&.Mui-error': {
            backgroundColor: '#303030',
            border: '1px solid #F66'
        },
    },
    '& .MuiInputLabel-root': {
        color: '#FAFAFA',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        left: 4,
        '&.MuiInputLabel-shrink': {
            fontSize: '8px',
            transform: 'translate(12px, 7px) scale(1)'
        },
        '&.Mui-focused': {
            color: '#FAFAFA',
        },
        '&.Mui-error': {
            color: '#FAFAFA',
        },
    },
}));

export default DateBirthField;
