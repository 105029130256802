import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { StepWizardChildProps } from "react-step-wizard";

import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import Button from "../../../elements/Button/Button";
import { RootState } from "../../../redux/store";
// @ts-ignore

import success_automation from "../../../assets/success_automation.svg";

import "./automationFeedback.css";
import Wrapper from "../../../elements/Wrapper";
import {useMixpanel} from "../../../helpers/mixpanel";

const CheckingAccDeficit = ({balanceStatus}:any) => {
    const mixpanel = useMixpanel();
    useEffect(() => {
      mixpanel('Automation_set_deficit', {'Queued balance sum': balanceStatus});
    }, []);

    return (
        <div className="feedback__subtitle-container">
            <div className="feedback__subtitle">
                Your checking account balance is{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(Number(Math.abs(balanceStatus).toFixed(2)))}{" "}
                lower than the fixed balance that you defined.
            </div>
            <div className="feedback__subtitle-short">
                Once you wrap up onboarding, we’ll transfer funds from your savings to
                restore your fixed balance.
            </div>
        </div>
    )
};

const CheckingAccExcess = ({balanceStatus}:any) => {
    const mixpanel = useMixpanel();
    useEffect(() => {
      mixpanel('Automation_set_excess', {'Queued balance sum': balanceStatus});
    }, []);

    return (
        <div className="feedback__subtitle-container">
            <div className="feedback__subtitle">
                Based on the fixed balance that you defined, there is an excess of{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(Number(Math.abs(balanceStatus).toFixed(2)))}{" "}
                in your checking account that can be transferred to your savings.
            </div>
            <div className="feedback__subtitle-short">
                Once you wrap up onboarding, we’ll take care of that for you.
            </div>
        </div>
    )
};

const EqualAccountValues = () => {
    const mixpanel = useMixpanel();
    useEffect(() => {
      mixpanel('Automation_set_even');
    }, []);

    return (
        <div className="feedback__subtitle-container">
            <div className="feedback__subtitle">
                Your checking account balance is right where you want it to be.
            </div>
            <div className="feedback__subtitle-short">
                Once you wrap up onboarding, we’ll continuously monitor the balance in
                your checking account. If it goes over the fixed balance, we'll
                automatically move the excess to savings. Rest assured, if it ever falls
                below your fixed balance, we’ll automatically restore it using funds
                from your savings.
            </div>
        </div>
    )
};

const AutomationFeedback: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  previousStep,
}) => {
  const mixpanel = useMixpanel();
  const checkingAccount: any = useSelector<RootState>(
    (state) => state.userAutomation.checkingAccount,
  );
  const balance: any = useSelector<RootState>(
    (state) => state.userAutomation.maxValue,
  );
  const checkingAvailableBalance = parseFloat(
    Number(checkingAccount?.availableBalance).toFixed(2),
  );
  const fixedBalance = parseFloat(Number(balance).toFixed(2));

  const onBackHandler = () => {
    previousStep?.();
  };

  const onNextHandler = () => {
    nextStep?.();
    mixpanel('KYC_started');
  };
  if (currentStep !== 6) {
    return null;
  }

  const balanceStatus = checkingAvailableBalance - fixedBalance;
  const hiddenFieldValue = balanceStatus < 0 ? "balanceDeficit" : (
      balanceStatus > 0 ? "balanceExcess" : "balanceEqual"
  );

  return (
    <Wrapper>
      <SignupNavbar
        step={currentStep}
        handler={onBackHandler}
        showBackButton={true}
      />
      <div className="page__infoContainer">
        {balanceStatus > 0 ? (
          <div className="page__title">
            Your automation is set! <br></br>You’re minutes from saving.
          </div>
        ) : (
          <div className="page__title">
            Congratulations! <br></br>Your automation is set.
          </div>
        )}
        <div className="success_automation_icon">
          <img src={success_automation} alt="success automation icon" />
        </div>
        {balanceStatus < 0 && <CheckingAccDeficit balanceStatus={balanceStatus}/>}
        {balanceStatus > 0 && <CheckingAccExcess balanceStatus={balanceStatus}/>}
        {balanceStatus === 0 && <EqualAccountValues/>}
      </div>
      <div className="button__holder">
        <Button onClick={onNextHandler} type={"primary-btn"}>
          Next
        </Button>
      </div>
      <input type="hidden" id="abandonedOnboardingStage" value={hiddenFieldValue} />
    </Wrapper>
  );
};

export default AutomationFeedback;
