import "./stepper.css";
import done_icon from "../../assets/done_step.svg";

type StepperProps = {
  step: number;
};
function Stepper({ step }: StepperProps) {
  const stepActiveDonenumber = {
    backgroundColor: "#39848A",
    color: "#EEEEEE",
  };
  const stepActiveDonetitle = {
    fontWeight: "700",
    color: "#39848A",
  };

  const stepDeviderActive = {
    color: "#eeeeee",
    letterSpacing: "-5px",
  };

  const doneTitle = {
    color: "#707070",
  };

  return (
    <div className="stepper">
      <div className="stepper__list">
        <div className="stepper__list-item">
          <div
            className={`stepper__list-item-number ${
              step >= 2 ? "step-done" : ""
            }`}
          >
            {step >= 2 ? <img src={done_icon} alt="done icon" /> : "1"}
          </div>
          <div
            className="stepper__list-item-title"
            style={step == 1 ? stepActiveDonetitle : doneTitle}
          >
            Sign Up
          </div>
        </div>
        <div
          className="stepDevider"
          style={step >= 2 ? stepDeviderActive : undefined}
        >
          __________________________________
        </div>
        <div className="stepper__list-item">
          <div
            className={`stepper__list-item-number ${
              step >= 3 ? "step-done" : ""
            }`}
            style={step >= 2 ? stepActiveDonenumber : undefined}
          >
            {step >= 3 ? <img src={done_icon} alt="done icon" /> : "2"}
          </div>
          <div
            className="stepper__list-item-title"
            style={step == 2 ? stepActiveDonetitle : doneTitle}
          >
            Set Up
          </div>
        </div>
        <div
          className="stepDevider"
          style={step >= 3 ? stepDeviderActive : undefined}
        >
          ___________________________________
        </div>
        <div className="stepper__list-item">
          <div
            className="stepper__list-item-number"
            style={step >= 3 ? stepActiveDonenumber : undefined}
          >
            3
          </div>
          <div
            className="stepper__list-item-title"
            style={step >= 3 ? stepActiveDonetitle : undefined}
          >
            Wrap Up
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stepper;
