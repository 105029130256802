import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

const AuthRouter = ({ children }: any) => {
    const context = useContext(AuthContext);
    if (context.user) {
        return <Navigate to="/automations" replace />;
    }
    return children;
};

export default AuthRouter;