import "./helpLink.css";
import { Link } from "react-router-dom";
import { useMixpanelPublic } from "../../helpers/mixpanel";
// @ts-ignore

declare global {
  interface Window {
    Tawk_API: any;
  }
}

function HelpLink() {
  const mixpanel = useMixpanelPublic();

  const handleClick = () => {
    window.Tawk_API = window.Tawk_API || {};
    if (typeof window.Tawk_API?.toggle !== undefined) {
      window.Tawk_API.toggle();
    }
    mixpanel("Clicked_Help");
  };

  return (
    <>
      <Link className="help__link" to="#" onClick={handleClick}>
        Help
      </Link>
    </>
  );
}

export default HelpLink;
