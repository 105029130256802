import { api } from "./api";

const apiSignUp = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (body) => ({
        url: "/login",
        method: "POST",
        body: body,
      }),
    }),
    signUp: build.mutation({
      query: (body) => ({
        url: "/signup",
        method: "POST",
        body: body,
      }),
    }),
    getUserRegistrationStep: build.query({
      query: ({ idToken }) => ({
        url: `/user/get-registrations-step`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    userExist: build.mutation({
      query: (body) => ({
        url: "/user/exist",
        method: "POST",
        body: body,
      }),
    }),

    saveUserPersonaInfo: build.mutation({
      query: ({ tkn, body }) => ({
        url: `/user/create`,
        method: "POST",
        body: body,
        headers: {
          Authorization: "Bearer " + tkn,
        },
      }),
    }),
    userEdit: build.mutation({
      query: ({ tkn, body }) => ({
        url: `/user/edit`,
        method: "PATCH",
        body: body,
        headers: {
          Authorization: "Bearer " + tkn,
        },
      }),
    }),

    retryVerificationUser: build.mutation({
      query: ({ tkn, body }) => ({
        url: `/user/retry-verification`,
        method: "PUT",
        body: body,
        headers: {
          Authorization: "Bearer " + tkn,
        },
      }),
    }),

    getUserInfo: build.query({
      query: ({ idToken, id }) => ({
        url: `/userInfo/${id}`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),

    updateUserSettings: build.mutation({
      query: ({ idToken, body }) => ({
        url: `/user/edit`,
        method: "PATCH",
        body: body,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),

    updateUserEmail: build.mutation({
      query: ({ idToken, body }) => ({
        url: `/user/edit`,
        method: "PATCH",
        body: body,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),

    disconnectUser: build.query({
      query: ({ idToken, answerList, message }) => ({
        url: "/user/disable",
        method: "PATCH",
        body: { poll: answerList, message },
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),

    sendVerificationEmail: build.mutation({
      query: ({ idToken }) => ({
        url: `/user/send-verify-email`,
        method: "POST",
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    verifyCode: build.mutation({
      query: ({ idToken, body }) => ({
        url: `/user/verify-code`,
        method: "POST",
        body: body,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    sendResetPwEmail: build.mutation({
      query: ({ email }) => ({
        url: `/user/send-reset-pw-email`,
        method: "POST",
        body: { email },
      }),
    }),
    sendEmailInfo: build.mutation({
      query: ({ idToken, body }) => ({
        url: "/system-event/send",
        method: "POST",
        body: body,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useLazyGetUserRegistrationStepQuery,
  useUserExistMutation,
  useSaveUserPersonaInfoMutation,
  useLazyGetUserInfoQuery,
  useUserEditMutation,
  useUpdateUserSettingsMutation,
  useUpdateUserEmailMutation,
  useRetryVerificationUserMutation,
  useLazyDisconnectUserQuery,
  useSendVerificationEmailMutation,
  useVerifyCodeMutation,
  useSendEmailInfoMutation,
  useSendResetPwEmailMutation,
} = apiSignUp;
