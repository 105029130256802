import React, { useEffect, useRef, useState } from "react";
import "./address.css";
import searchIcon from "./../../assets/search.svg";
import { Link } from "react-router-dom";
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import { Tooltip } from "@mui/material";
import TextInput from "../../elements/Input/TextInput";
import SelectInput from "../../elements/SelectInput/SelectInput";
import { statesList } from "../../helpers/statesList";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export const DEFAULT_DISABLED_INPUTS = {
  city: false,
  zipCode: false,
  state: false,
};

function Address ({
    showFormAlways = false,
    isStreet = false,
    setIsStreet = () => {},
    formError,
    formData,
    setFormData,
    resetErrorOnChange,
    showFormCallBack
}: any) {

  const [disabledInputs, setDisabledInputs] = useState(DEFAULT_DISABLED_INPUTS);
  const [showForm, setShowForm] = useState<boolean>(!!formData.address || showFormAlways);
  const wrapperRef = useRef(null);
  const authInfo: any = useSelector<RootState>((state) => state.auth);

  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: ["us"] },
      types: ["address"],
    },
  });
  const numbersRegex = /^\d+$/;
  const addressRegex = /^(?![ -.&,_'":?!/])(?!.*[- &_'":]$)(?!.*[-.#@&,:?!/]{2})[a-zA-Z0-9- .#@&,_'":.?!/]+$/;
  const cityRegex = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;

  useEffect(() => {
      setFormData((prevState: any) => {
          return {
              ...prevState,
              address: formData.address || ''
          }
      });
  }, []);

  useEffect(() => {
      if (showFormCallBack) {
          showFormCallBack(showForm);
      }
  }, [showForm])

    useEffect(() => {
        if (!isStreet) {
            setDisabledInputs({
                zipCode: !!authInfo.zipCode,
                state: !!authInfo.state,
                city: !!authInfo.city,
            });
        }
    }, [authInfo]);

  const useOutsideAlerter = (ref:any) => {
      useEffect(() => {
          function handleClickOutside(event:any) {
              if (ref.current && !ref.current.contains(event.target)) {
                  clearSuggestions();
              }
          }

          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const handleSelect = ({ description }:any) => () => {
    clearSuggestions();

    getGeocode({ address: description })
        .then(results => {
            const result = results[0];
            resetErrorOnChange();
            let address = "";
            let zipCode = "";
            let state = "";
            let city = "";
            let city2 = "";
            for (const component of result
                .address_components as google.maps.GeocoderAddressComponent[]) {
              const componentType = component.types[0];
              switch (componentType) {
                case "street_number": {
                  address = `${component.long_name} ${address}`;
                  break;
                }
                case "route": {
                  address += component.short_name;
                  break;
                }
                case "postal_code": {
                  zipCode = component.short_name;
                  break;
                }
                case "administrative_area_level_1": {
                  state = component.short_name;
                  city2 = component.long_name;
                  break;
                }
                case "locality": {
                  city = component.long_name;
                  break;
                }
              }
            }

            setFormData((prevState: any) => {
                return {
                    ...prevState,
                    address: address || '',
                    zipCode: zipCode || '',
                    state: state || '',
                    city: city ? city : city2,
                }
            });
            setValue(address, false);
            setDisabledInputs({
                zipCode: !!zipCode,
                state: !!state,
                city: !!city || !!city2,
            });
            setShowForm(true);
        })
        .catch(error => {console.log('Error: ', error)
    });
  };

  const renderSuggestions = () =>
      data.map(suggestion => {
        const {
          id,
          structured_formatting: { main_text, secondary_text }
        }:any = suggestion;
        return (
            <li
                key={id}
                onClick={handleSelect(suggestion)}
                className="suggestion-item"
            >
              {main_text} {secondary_text}
            </li>
        );
      });

  const setManualAddress = () => {
    setShowForm(true);
    setIsStreet(!isStreet);
    clearSuggestions();
    setFormData((prevState: any) => {
      return {
          ...prevState,
          address: "",
          apartment: "",
          zipCode: "",
          state: "",
          city: "",
      }
    });
    resetErrorOnChange();
    setDisabledInputs(DEFAULT_DISABLED_INPUTS);
  }

  return (
    <div className="address">
      <div className="inputHolder">
        <div className={`customInput__holder`}>
          <TextInput
            fullWidth
            label="Home address"
            name="userAddress"
            error={formError.address}
            value={formData.address}
            onChange={e => {
                const value = e.target.value;
                // if (!value || addressRegex.test(value)) {
                    if(!isStreet) {
                        if (!value && !showFormAlways) {
                            setShowForm(false);
                        }
                        setValue(value);
                    }
                    setFormData((prevState: any) => {
                        return {
                            ...prevState, address: e.target.value
                        }
                    });
                // }
                resetErrorOnChange();
            }}
          />
        </div>
        {!isStreet ?
            <img src={searchIcon} alt="searchIcon" className="addressSearchIcon"/>
            :
            <Tooltip
                title='Back to dropdown menu'
                arrow
                placement="left"
                enterTouchDelay={0}
                componentsProps={{
                    tooltip: {
                        sx: {
                            textAlign: 'left !important',
                            width: "auto",
                            color: "#39848a"
                        },
                    },
                }}
            >
                <YoutubeSearchedForIcon className="backToAddressSearchIcon" onClick={setManualAddress} sx={{color: "#EEEEEE"}}/>
            </Tooltip>
        }
        {status === 'OK' && <ul ref={wrapperRef} className="autocomplete-dropdown-container">
          {renderSuggestions()}
          <li className="suggestion-item">Don’t see your address? <Link to="#" onClick={setManualAddress}>Add manually.</Link></li>
        </ul>}
      </div>
      {showForm && (
          <>
            <div className={`customInput__holder`}>
              <TextInput
                fullWidth
                label="Apartment, suite or building number"
                placeholder="Optional"
                name="userApartment"
                error={formError.apartment}
                value={formData.apartment}
                {...!isStreet && {
                    InputLabelProps: {
                        shrink: true
                    }
                }}
                onChange={e => {
                    setFormData((prevState: any) => {
                        return {
                            ...prevState, apartment: e.target.value
                        }
                    });
                    resetErrorOnChange();
                }}
              />
            </div>
            <div className="location-citi-zip">
              <div className="location-citi">
                <div className={`customInput__holder`}>
                  <TextInput
                    fullWidth
                    disabled={disabledInputs.city}
                    label="City"
                    name="userCity"
                    error={formError.city}
                    value={formData.city}
                    inputProps={
                        { readOnly: disabledInputs.city }
                    }
                    onChange={e => {
                        const value = e.target.value;
                        if (!value || cityRegex.test(value)) {
                            setFormData((prevState: any) => {
                                return {
                                    ...prevState, city: e.target.value
                                }
                            });
                        }
                        resetErrorOnChange();
                    }}
                  />
                </div>
              </div>
              <div className="location-zip">
                <div className={`customInput__holder`}>
                  <TextInput
                    fullWidth
                    disabled={disabledInputs.zipCode}
                    label="ZIP Code"
                    name="userZipCode"
                    error={formError.zipCode}
                    value={formData.zipCode}
                    inputProps={
                      { readOnly: disabledInputs.zipCode }
                    }
                    onChange={e => {
                        const value = e.target.value;
                        if (!value || numbersRegex.test(value)) {
                            setFormData((prevState: any) => {
                                return {
                                    ...prevState, zipCode: e.target.value
                                }
                            });
                        }
                        resetErrorOnChange();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={`customInput__holder`}>
              <SelectInput
                label="State"
                options={statesList}
                disabled={disabledInputs.state}
                error={formError.state}
                value={formData.state}
                onChange={(e: any) => {
                    const value = e.target.value;
                    setFormData((prevState: any) => {
                        return {
                            ...prevState, state: value
                        }
                    });
                    resetErrorOnChange();
                }}
              />
            </div>
          </>
      )}
    </div>
  );
}

export default Address;
