import { Box, Card, CardContent, Typography } from "@mui/material";
import "./testimonialsCard.css";

export function TestimonialsCard({ content, name }: any) {
  return (
    <Card className="testimonial_card">
      <CardContent>
        <Box className="box_content_card">
          <Typography className="contentCard">{content}</Typography>
        </Box>
        <Box display="flex" justifyContent="end">
          <Typography fontWeight={"bold"} fontSize={"12px"} color="black">
            {name}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
