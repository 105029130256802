import SubHeader from "../SubHeader/SubHeader";
import Header from "../../elements/Header";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useMixpanelPublic } from "../../helpers/mixpanel";
import Footer from "../../elements/Footer";
import "./faq.css";
import FaqQuestionsSection from "./FaqQuestionSection";

export type FaqData = {
  general?: SectionData[];
  bankingAndTransactions?: SectionData[];
  miscellaneous?: SectionData[];
};

export type SectionData = {
  question: string;
  answer: Answer[] | string;
};

export type Answer = {
  text: string;
  type: string;
  paragraph: string;
  link?: string;
};

const Faq = () => {
  const mixpanelPublic = useMixpanelPublic();

  const handleContactUs = () => {
    window.Tawk_API = window.Tawk_API || {};
    if (typeof window.Tawk_API?.toggle !== undefined) {
      window.Tawk_API.toggle();
    }
    mixpanelPublic("Clicked_ContactUs");
  };

  return (
    <div className="customBody">
      <Header position="sticky" />
      <SubHeader
        title_1="Frequently Asked"
        title_2=" Questions"
        subtitle={
          <Box className="faq_subtitle">
            Make the most of Cache with helpful answers and tutorials.
            <br />
            Can't find the answer to your question?
            <Link className={"link"} to="#" onClick={handleContactUs}>
              {" "}
              Contact us &gt;
            </Link>
          </Box>
        }
      />
      <Box className="whiteBox">
        <FaqQuestionsSection />
      </Box>
      <Footer />
    </div>
  );
};
export default Faq;
