import Stepper from "../Stepper/Stepper";
import BackButton from "../BackButton/BackButton";
import "./signupNavbar.css";

function stepModify(step: number) {
  if (step >= 1 && step <= 4) {
    return 1;
  } else if (step >= 5 && step <= 6) {
    return 2;
  } else if (step >= 6 && step <= 12) {
    return 3;
  }
  return 1;
}

function SignupNavbar({
  step = 1,
  handler = () => {},
  showBackButton = true,
}: any) {
  const onBackHandler = () => {
    handler();
  };

  return (
    <nav className="signupNavbar">
      <div className="signupNavbar__control">
        <div className="backBtn__holder">
          {showBackButton && <BackButton onBack={onBackHandler} />}
        </div>
        <Stepper step={stepModify(step)} />
      </div>
    </nav>
  );
}

export default SignupNavbar;
