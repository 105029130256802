import { useEffect, useState } from "react";
import { applyActionCode, getAuth } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import {useMixpanel} from "../../../helpers/mixpanel";
// @ts-ignore

const Verify = () => {
  const mixpanel = useMixpanel();
  const navigation = useNavigate();
  const auth = getAuth();
  
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const mode = query.get("mode") || "";
  const oobCode = query.get("oobCode") || "";

  useEffect(() => {
    if (mode === "verifyEmail") {
      applyActionCode(auth, oobCode)
        .then((resp) => {
          mixpanel('Verified_email');
          navigation("/verify-account");
          // Email address has been verified.

          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        })
        .catch((error) => {
          console.log("Error in applyActionCode Verify.tsx: ", error?.message);
          navigation("/");
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
        });
      navigation("/verify-account");
    } else if (mode === "resetPassword") {
      navigation("/confirm-password?oobCode=" + oobCode);
    } else {
      navigation("/");
    }
  }, []);

  return <div></div>;
};

export default Verify;
