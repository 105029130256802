import {Box, Container, Grid, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import SimpleWrapper from "../../elements/SimpleWrapper";

function PrivacyPolicy() {
    return (
        <SimpleWrapper>
            <Container sx={{ px: '0!important' }}>
                <Grid container marginTop='30px' className="article">
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h2">Cache – Privacy Policy</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign="justify">
                        <p>
                            In order to ensure transparency and give you more control over your personal information, this
                            privacy policy (<b>“Privacy Policy”</b>) governs how we, Cache Inc. (<b>“Cache”</b>, <b>“we”</b>, <b>“our”</b> or <b>“us”</b>) use,
                            collect and store personal information that we collect or receive from or about you (“you”)
                            in connection with <Link to="https://www.mycache.ai/">mycache.ai</Link> (the <b>"Site"</b>) and
                            the services provided therein (together with the
                            Site, the <b>“Services”</b>):
                        </p>
                        <p>
                            We greatly respect your privacy and make every effort to keep all of your information secure and
                            private. Please read this Privacy Policy carefully, so that you can fully understand our practices
                            in relation to your personal data.
                        </p>
                        <p>
                            By using the Services, you consent to the use of your information as set forth in this Privacy
                            Policy. This Privacy Policy may be updated from time to time and we, therefore, ask that you
                            check back periodically to review the latest version of this Privacy Policy.  If we implement
                            material changes in the way that we use your information, in a manner that is different from
                            that stated at the time of collection, we will notify you by posting a notice on our Site or
                            by other means and take any additional steps as required by applicable law.
                        </p>

                        <ol style={{paddingLeft: '15px'}}>
                            <li>
                                <b>THE INFORMATION THAT WE COLLECT AND WHY WE COLLECT IT</b>
                                <p>
                                    <ul>
                                        <li style={{ listStyleType: "lower-roman" }}>
                                            <u><b>We Process the Following Personal Information:</b></u>
                                            <ol>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>Information You Provide Directly to Us.</b> We collect the following information
                                                    you provide directly to us, including, without limitation:  full name,
                                                    email address, home address, birth date, last four digits or full nine digit
                                                    social security number (SSN), as applicable, as well as additional information
                                                    that you may submit from time to time when you browse our Site and use our Services.
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>Information from Other Sources.</b> Cache may also obtain information about you,
                                                    including financial information, from other sources, including, but not limited
                                                    to, publicly or commercially available data sources, third-party data platforms,
                                                    partners and service providers, such as Plaid and Dwolla, and
                                                    financial institutions, such as banks.
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>Automatic Data Collection.</b> We may automatically collect certain information
                                                    through your use of the Services, such as your Internet protocol (IP) address,
                                                    cookie identifiers and other device identifiers that are automatically
                                                    assigned to your device, browser type and language, geo-location information,
                                                    hardware type, operating system, internet service provider and other information
                                                    about actions taken through the use of the Services.
                                                </li>
                                            </ol>
                                        </li>
                                        <li style={{ listStyleType: "lower-roman" }}>
                                            <u><b>We process information for the following purposes: </b></u>
                                            <ol>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>To provide you with the Services.</b> Cache will analyze and use your
                                                    information to provide you with the Services.
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>For administrative purposes.</b> Cache may use your information (i) to
                                                    respond to your questions, comments, and other requests for customer support,
                                                    or information, including information about potential or future services;
                                                    (ii) for internal quality control purposes; (iii) to establish and maintain
                                                    a business relationship with you; (iv) to prevent potentially prohibited or
                                                    illegal activities and (v) to generally administer the Services.
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>To market  products and services to you.</b> Cache may use your information
                                                    to market the Services to you, by way of (i) notifying you about offers and
                                                    services that may be of interest to you; (ii) sending advertisements and offers;
                                                    (iii) conducting market research; (iv) developing and marketing new products
                                                    and services, and to measure interest in these products and services; (v)
                                                    other purposes disclosed at the time you provide your information; and (vi)
                                                    as you otherwise consent.
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>For surveys.</b> Cache may use your information to contact you to
                                                    participate in surveys.
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>Security purposes.</b> Cache may use your information for detecting,
                                                    taking steps to prevent, and prosecuting fraud or other illegal activity;
                                                    for identifying and repairing errors; for conducting audits; and for security
                                                    purposes. Your information may also be used to comply with applicable laws and
                                                    with investigations performed by the relevant authorities, for law enforcement
                                                    purposes, and/or to exercise or defend legal claims.
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>De-identified and aggregated information use.</b> De-identified and
                                                    aggregated information is not personal information, and in certain cases, we
                                                    may or will anonymize or de-identify your information and further use it for
                                                    internal and external purposes, including, without limitation, to analyze
                                                    and improve the Services (including through the use of artificial intelligence),
                                                    for research purposes and for any other legally permissible purposes. We may use
                                                    this anonymous or de-identified information and/or disclose it to third parties
                                                    without any restrictions (for example, in order to improve the Services and
                                                    enhance your experience and/or to develop new product features and/or to
                                                    improve existing offerings).
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>Cookies and similar technologies.</b> We, as well as third parties that
                                                    provide content, advertising, or other functionality as part of the Services,
                                                    may use cookies, pixel tags, local storage, and other technologies
                                                    (“Technologies”) to automatically collect information through the Services.
                                                    We use Technologies that are essentially small data files placed on your
                                                    device that allow us to record certain pieces of information whenever you
                                                    visit or interact with the Services. If you would like to opt out of the
                                                    Technologies we employ on the Services, you may do so by blocking, deleting,
                                                    or disabling them as your browser or device permits.
                                                </li>
                                                <li style={{ listStyleType: "lower-alpha" }}>
                                                    <b>Other uses.</b> Cache may use your information for other purposes that
                                                    are clearly disclosed to you at the time you provide personal information or
                                                    with your consent.
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <b>HOW WE PROTECT AND RETAIN YOUR PERSONAL INFORMATION </b>
                                <p>
                                    <ul style={{paddingLeft: 0}}>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>2.1.</Box>
                                                <Box>
                                                    <u>Security</u>. We have implemented and maintain technical, organizational and security
                                                    measures designed to protect your information. However, please note that we cannot
                                                    guarantee that the information will not be compromised as a result of unauthorized
                                                    penetration to our servers. As the security of information depends in part on
                                                    the security of the computer, device or network you use to communicate with
                                                    us and the security you use to protect your user IDs and passwords, please
                                                    make sure to take appropriate measures to protect this information.
                                                </Box>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>2.2.</Box>
                                                <Box>
                                                    <u>Retention of your information</u>. Your information will be stored until we
                                                    delete our records, following our decision or policy to proactively delete it,
                                                    or if you send a valid deletion request to <a href='mailto:privacy@mycache.ai'>privacy@mycache.ai.</a> Please note that
                                                    in some circumstances we may store your information for longer periods of time,
                                                    for example (i) where we are required to do so in accordance with legal,
                                                    regulatory, tax or accounting requirements, or (ii) for us to have an accurate
                                                    record of your dealings with us in the event of any complaints or challenges,
                                                    and/or (iii) if we reasonably believe there is a prospect of litigation relating
                                                    to your information or dealings.
                                                </Box>
                                            </Box>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <b>HOW WE SHARE YOUR PERSONAL INFORMATION </b>
                                <p>
                                    We may share your information as follows:
                                    <ul style={{paddingLeft: 0}}>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>3.1.</Box>
                                                <Box>
                                                    We may share you information with our partners, affiliated companies and
                                                    third-party service providers, as well as with other third parties for
                                                    the purposes outlined above, including, without limitation:
                                                </Box>
                                            </Box>
                                            <ul>
                                                <li>
                                                    <Box display="flex">
                                                        <Box paddingRight={1} paddingLeft={1}>3.1.1</Box>
                                                        <Box>
                                                            With cloud service providers for hosting purposes;
                                                        </Box>
                                                    </Box>
                                                </li>
                                                <li>
                                                    <Box display="flex">
                                                        <Box paddingRight={1} paddingLeft={1}>3.1.2</Box>
                                                        <Box>
                                                            With websites and web content creation platforms in order to help
                                                            us manage our Site;
                                                        </Box>
                                                    </Box>
                                                </li>
                                                <li>
                                                    <Box display="flex">
                                                        <Box paddingRight={1} paddingLeft={1}>3.1.3</Box>
                                                        <Box>
                                                            With email, marketing, CRM, and other similar tool providers;
                                                        </Box>
                                                    </Box>
                                                </li>
                                                <li>
                                                    <Box display="flex">
                                                        <Box paddingRight={1} paddingLeft={1}>3.1.4</Box>
                                                        <Box>
                                                            With analytic companies, in order to help us understand and analyze
                                                            information we collect in accordance with this Privacy Policy; and
                                                        </Box>
                                                    </Box>
                                                </li>
                                                <li>
                                                    <Box display="flex">
                                                        <Box paddingRight={1} paddingLeft={1}>3.1.5</Box>
                                                        <Box>
                                                            With partners and third-party service providers that are required to
                                                            provide the Services. Specifically, it is noted that Cache uses
                                                            the following third-party service providers:
                                                        </Box>
                                                    </Box>
                                                </li>
                                                <li>
                                                    <Box display="flex" marginTop={1}>
                                                        <Box paddingRight={1} paddingLeft={6}>3.1.5.1</Box>
                                                        <Box>
                                                            Plaid Inc. ("<b>Plaid</b>") to gather your personal and
                                                            financial information from the financial institutions
                                                            that you connect to the Services. By using the Services, you
                                                            grant Cache and Plaid the right, power, and authority to act on
                                                            your behalf in order to access and transmit your personal and
                                                            financial information from your relevant financial institution.
                                                            You agree to your personal and financial information being transferred,
                                                            stored, and processed by Plaid in accordance with
                                                            the Plaid <Link to='https://plaid.com/legal/#end-user-privacy-policy'>end user privacy policy;</Link> and
                                                        </Box>
                                                    </Box>
                                                </li>
                                                <li>
                                                    <Box display="flex">
                                                        <Box paddingRight={1} paddingLeft={6}>3.1.5.2</Box>
                                                        <Box>
                                                            Dwolla, Inc. (“<b>Dwolla</b>”) to facilitate the automated transfers
                                                            that you define as part of the  Services. By using the Services,
                                                            you grant Cache the right, power, and authority to collect and
                                                            share with Dwolla your personal and financial information. You
                                                            agree to your personal and financial information being transferred,
                                                            stored, and processed by Dwolla in accordance
                                                            with the Dwolla <Link to='https://www.dwolla.com/legal/privacy/'>privacy policy.</Link>
                                                        </Box>
                                                    </Box>
                                                </li>
                                                <li>
                                                    <Box display="flex">
                                                        <Box paddingRight={1} paddingLeft={6}>3.1.5.3</Box>
                                                        <Box>
                                                            With additional partners, affiliates and third parties that
                                                            we work with to provide the Services.
                                                        </Box>
                                                    </Box>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>3.2.</Box>
                                                <Box>
                                                    We may share your information, to the extent necessary, with regulators,
                                                    courts, banks or competent authorities, to comply with applicable laws,
                                                    regulations and rules (including, without limitation, federal, state
                                                    or local laws), and with requests of law enforcement, regulatory and
                                                    other governmental agencies, or if required by a court order. We may
                                                    further use and share your information as required by internal compliance
                                                    procedures and in order to protect the safety, security, and integrity
                                                    of Cache, our Services, customers, employees, property, and the public.
                                                </Box>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>3.3.</Box>
                                                <Box>
                                                    If, in the future, we sell or transfer, or consider to sell or transfer,
                                                    some or all of our business, shares or assets to a third party, we will
                                                    disclose your information to such third party (whether actual or potential)
                                                    in connection with the foregoing events (including, without limitation, our
                                                    current or potential investors). In the event that we are acquired by, or
                                                    merged with, a third party entity, or in the event of bankruptcy or a
                                                    comparable event, we reserve the right to transfer, disclose or assign
                                                    your information in connection with the foregoing events.
                                                </Box>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>3.4.</Box>
                                                <Box>
                                                    Where you have otherwise provided your consent to us for
                                                    sharing or transferring your information.
                                                </Box>
                                            </Box>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <b>YOUR PRIVACY RIGHTS</b>
                                <p>
                                    <ul style={{paddingLeft: 0}}>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>4.1.</Box>
                                                <Box>
                                                    <b>Rights:</b> You can exercise your rights by contacting us
                                                    at <a href='mailto:privacy@mycache.ai'>privacy@mycache.ai.</a> Subject
                                                    to legal and other permissible
                                                    considerations, we will make every reasonable effort to honor your
                                                    request promptly in accordance with applicable law or inform you if
                                                    we need further information in order to fulfill your request. Before
                                                    processing and/or honoring your request, we may ask you for additional
                                                    information to confirm or verify your identity and for security purposes.
                                                    We reserve the right to charge a fee where permitted by law, for instance
                                                    if your request is manifestly unfounded or excessive. In the event that
                                                    your request would adversely affect the rights and freedoms of others
                                                    (for example, would impact the duty of confidentiality we owe to others)
                                                    or if we are legally entitled to deal with your request in a different
                                                    way than requested, we will address your request to the maximum extent
                                                    possible, all in accordance with applicable law.
                                                </Box>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>4.2.</Box>
                                                <Box>
                                                    <b>Marketing emails – opt-out:</b> If you receive an unwanted marketing email
                                                    from us, you can use the unsubscribe link found at the bottom of the
                                                    email to opt out of receiving future emails, and we will process your
                                                    request within a reasonable time after receipt. You may further
                                                    opt-out from marketing emails in the settings section of the Services.
                                                </Box>
                                            </Box>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>USE BY CHILDREN.</b> We do not offer the Services to children and, therefore, we do not
                                    knowingly collect information from, and/or about children under the age of 18. If you are under
                                    the age of 18, do not provide any information to us. In the event that we become aware
                                    that you provide information in violation of applicable privacy laws, we reserve the
                                    right to delete it. If you believe that we might have any such information, please
                                    contact us at <a href='mailto:privacy@mycache.ai'>privacy@mycache.ai.</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>INTERACTION WITH THIRD PARTY PRODUCTS AND SERVICES.</b> Some of the Services may use
                                    third-party products or services that are not owned or controlled by us, as described
                                    in Section 3 above (each, a <b>“Third Party Service”</b>). We are not responsible for the
                                    privacy practices or the content of such Third Party Services. Please be aware that
                                    Third Party Services can collect information from you. Accordingly, we encourage you
                                    to read the terms and conditions and privacy policies of each Third Party Service.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>ANALYTIC TOOLS/COOKIES.</b> As described under Section 3, we may use analytic tools,
                                    cookies, pixels and tracking technologies to collect information about use of the Site
                                    and the Services. We reserve the right to add new analytic tools, cookies, pixels and
                                    tracking technologies. Please read this privacy policy periodically to be updated
                                    on any new technologies or tools used.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>SPECIFIC PROVISIONS APPLICABLE UNDER CALIFORNIA PRIVACY LAW</b>
                                </p>
                                <p>
                                    <ul style={{paddingLeft: 0}}>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>8.1.</Box>
                                                <Box>
                                                    <b><u>California Privacy Rights</u></b>: California Civil Code Section 1798.83
                                                    permits our customers who are California residents to
                                                    request certain information regarding our disclosure of
                                                    information to third parties for their direct marketing
                                                    purposes. To make such a request, please send an email
                                                    to <a href='mailto:privacy@mycache.ai'>privacy@mycache.ai.</a> Please note that we are only
                                                    required to respond to one request per customer each year.
                                                </Box>
                                            </Box>
                                        </li>
                                        <li>
                                            <Box display="flex">
                                                <Box paddingRight={1} paddingLeft={1}>8.2.</Box>
                                                <Box>
                                                    <b><u>Our California Do Not Track Notice</u></b>: Do Not Track
                                                    (<b>“DNT”</b>) is a privacy preference that users can set in
                                                    certain web browsers. Please note that we do not respond
                                                    to or honor DNT signals or similar mechanisms transmitted
                                                    by web browsers.
                                                </Box>
                                            </Box>
                                        </li>
                                    </ul>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>CONTACT US.</b>  If you have any questions, concerns or complaints regarding
                                    our compliance with this notice and the data protection laws, or if you wish
                                    to exercise your rights, as described under Section 4.1 above, please
                                    contact us at <a href='mailto:privacy@mycache.ai'>privacy@mycache.ai.</a>
                                </p>
                            </li>
                        </ol>

                    </Grid>
                </Grid>
            </Container>
        </SimpleWrapper>
    );
}

export default PrivacyPolicy;
