import { useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getFormattedTransferDate } from "../../helpers/transfersUtils";
import empty_notification from "../../assets/noNotificatios_icon.svg";
import btn_close from "../../assets/btn_close.svg";
import {
  useMarkAllNotificationReadMutation,
  useMarkNotificationExpiredMutation,
} from "../../api/apiDashboard";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "./Notifications.css";
import PlaidAuth from "../../pages/Plaid/PlaidAuth";
import notification from "../ToastNotifications/ToastNotifications";
import { resetDisabledAutomation } from "../../redux/sliceUserAutomation";
import {
  setReconnectError,
  setUserNotifications,
} from "../../redux/sliceNotifications";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import { AuthContext } from "../../context/AuthContext";
import {useMixpanel} from "../../helpers/mixpanel";
// @ts-ignore

type NotificationsProp = {
  id: string | undefined;
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLDivElement | null;
};

const NOTIFICATION_CODES = {
  UNKNOWN_NOTIFICATION: "NC_000",
  TRANSFER_INITIATED: "NC_001",
  TRANSFER_FAILED: "NC_002",
  TRANSFER_COMPLETED: "NC_003",
  RELINK_PLAID_ITEM: "NC_004",
};

const Notifications = ({
  id,
  open,
  handleClose,
  anchorEl,
}: NotificationsProp) => {
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanel();
  const userNotifications: any = useSelector<RootState>(
    (state) => state.userNotifications.userData.notifications,
  );
  const checkingAccounts: any = useSelector<RootState>(
    (state) => state.accounts.checkingAccountList,
  );
  const savingsAccounts: any = useSelector<RootState>(
    (state) => state.accounts.savingsAccountList,
  );
  // const [userNotification, setUserNotification] = useState([]);
  const [markNotification, resultMarkNotification] =
    useMarkNotificationExpiredMutation();
  const [makeAllNotificationRead] = useMarkAllNotificationReadMutation();

  const mixPanelNotificationClick = (notification: any) => {
    const serviceData = notification.serviceData;
    if (serviceData) {
      if (serviceData.direction == 0 || serviceData.direction == 1) {
        mixpanel("Notification_clicked", {
          type: getNotificationEventType(
            notification.notificationCode,
            serviceData.direction,
          ),
          "from bank": getBankName(serviceData).from,
          "to bank": getBankName(serviceData).to,
          amount: serviceData.amount,
          date: serviceData.createdAt,
          "transaction ID": serviceData.transactionId,
        });
      }
    }
  };

  const getNotificationEventType = (
    notificationCode: string,
    direction: number,
  ) => {
    if (notificationCode == NOTIFICATION_CODES.TRANSFER_INITIATED) {
      return direction === 0 ? "tr2checking_initiated" : "tr2savings_initiated";
    } else if (notificationCode == NOTIFICATION_CODES.TRANSFER_FAILED) {
      return direction === 0 ? "tr2checking_failed" : "tr2savings_failed";
    } else if (notificationCode == NOTIFICATION_CODES.TRANSFER_COMPLETED) {
      return direction === 0 ? "tr2checking_completed" : "tr2savings_completed";
    } else if (notificationCode == NOTIFICATION_CODES.RELINK_PLAID_ITEM) {
      return "acct_disconnected";
    } else {
      return "Unknown";
    }
  };

  const getBankName = (serviceData: any) => {
    const result = {
      from: "",
      to: "",
    };
    if (serviceData.direction == 0) {
      //from savingsAccount to checkingAccount
      savingsAccounts.map((item: any) => {
        if (item.id == serviceData.savingAccountId) {
          result.from = item.institution.name;
        }
      });
      checkingAccounts.map((item: any) => {
        if (item.id == serviceData.checkingAccountId) {
          result.to = item.institution.name;
        }
      });
    } else if (serviceData.direction == 1) {
      //from checkingAccount to savingsAccount
      checkingAccounts.map((item: any) => {
        if (item.id == serviceData.checkingAccountId) {
          result.from = item.institution.name;
        }
      });
      savingsAccounts.map((item: any) => {
        if (item.id == serviceData.savingAccountId) {
          result.to = item.institution.name;
        }
      });
    }
    return result;
  };

  useEffect(() => {
    userNotifications.map((notification: any) => {
      const serviceData = notification.serviceData;
      if (serviceData) {
        if (serviceData.direction == 0 || serviceData.direction == 1) {
          mixpanel("Notification_triggered", {
            type: getNotificationEventType(
              notification.notificationCode,
              serviceData.direction,
            ),
            "from bank": getBankName(serviceData).from,
            "to bank": getBankName(serviceData).to,
            amount: serviceData.amount,
            date: serviceData.createdAt,
            "transaction ID": serviceData.transactionId,
          });
        }
      }
    });
  }, [userNotifications]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const markAllNotificationsReadCB = async () => {
      try {
        if (!Array.isArray(userNotifications)) {
          return;
        }

        const isAnyUnreadNotifications = !!userNotifications.some(
          (notification) =>
            notification?.isRead === false &&
            notification?.notificationCode !== "NC_004",
        );

        if (!isAnyUnreadNotifications) {
          return;
        }

        const { user } = context;
        if (!user) {
          return;
        }
        const token = await user.getIdToken();

        //set all notification read in redux (only reconnection flow notification exclusion)
        const readNotification = userNotifications.map((notification: any) => {
          if (notification.notificationCode === "NC_004") {
            return notification;
          } else {
            return { ...notification, isRead: true };
          }
        });
        dispatch(setUserNotifications(readNotification));

        //do same on backend
        await makeAllNotificationRead({ idToken: token });
      } catch (error) {
        console.log(
          "Error in markAllNotificationsReadCB Notifications.ts:",
          error,
        );
      }
    };

    markAllNotificationsReadCB();
  }, [open]);

  const reloadPage = () => {
    notification({
      message: "Reconnection successful. Automation resumed.",
      type: "success",
    });
    dispatch(resetDisabledAutomation());
    dispatch(setReconnectError(false));
    handleClose();
    navigation("/automations");
  };

  const sortedNotifications = [...userNotifications];
  sortedNotifications?.sort(
    (a: any, b: any) =>
      new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime(),
  );

  const notificationLink = (userNotification: any) => {
    if (
      userNotification.length === 0 ||
      userNotification.notificationCode ===
        NOTIFICATION_CODES.UNKNOWN_NOTIFICATION
    ) {
      return <></>;
    }
    if (
      userNotification.notificationCode ===
        NOTIFICATION_CODES.TRANSFER_INITIATED ||
      userNotification.notificationCode ===
        NOTIFICATION_CODES.TRANSFER_COMPLETED
    ) {
      return (
        <Link
          className="notifications__latest"
          to="/transfers"
          onClick={() => {
            handleClose();
            mixPanelNotificationClick(userNotification);
          }}
        >
          See latest transfers
        </Link>
      );
    }
    if (
      userNotification.notificationCode === NOTIFICATION_CODES.TRANSFER_FAILED
    ) {
      return (
        <Link
          className="notifications__latest"
          to="#"
          onClick={(e) => {
            e.preventDefault();
            mixPanelNotificationClick(userNotification);
            window.location = "mailto:support@mycache.ai" as string & Location;
          }}
        >
          Resolve
        </Link>
      );
    }
    if (
      userNotification.notificationCode === NOTIFICATION_CODES.RELINK_PLAID_ITEM
    ) {
      return (
        <PlaidAuth
          accessToken={userNotification?.serviceData?.accessToken}
          reloadPage={reloadPage}
          notifsCloseCallback={handleClose}
        >
          <p
            className="reconnectNotification"
            onClick={() => {
              mixpanel("Clicked_Reconnect_Now", {
                "Origination point": "notifications",
              });
            }}
          >
            Reconnect to Resume
          </p>
        </PlaidAuth>
      );
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: -10,
        horizontal: 532,
      }}
      slotProps={{
        paper: {
          style: {
            background: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        },
      }}
    >
      <Box
        className="notifications__arrow"
        sx={{
          position: "relative",
          mt: "10px",
          "&::before": {
            backgroundColor: "#303030",
            borderLeft: "1px solid #707070",
            borderTop: "1px solid #707070",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -6,
            transform: "rotate(45deg)",
            right: "123px",
          },
        }}
      />
      <div className="notifications__block">
        <button className="notifications__closeBtn" onClick={handleClose}>
          <img src={btn_close} alt="Close Icon" />
        </button>
        <div className={`notifications ${sortedNotifications.length === 0 ? 'empty' : ''}`}>
          <div className="notifications__header">
            <div className="notifications__header__title">Notifications</div>
          </div>
          <div className="notif_block">
            {sortedNotifications.length !== 0 ? (
              sortedNotifications.map((notification: any) => (
                <>
                  <div className="notification__infoBlock">
                    <div className="notifications__header__top">
                      <div className="notification__info__text">
                        {notification.message}
                      </div>
                      <div className="notification__info__date">
                        {getFormattedTransferDate(notification.createdAt)}
                      </div>
                    </div>
                    {notificationLink(notification)}
                  </div>
                  <hr className="notification__line" />
                </>
              ))
            ) : (
              <div className="notifications__empty">
                <p className="notifications__empty-title">
                  You’re all caught up!
                </p>
                <div>
                  <img src={empty_notification} alt="Notification Icon" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default Notifications;
