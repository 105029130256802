// Use notification function:
// notification({message: 'Accounts added successfully!', type: 'error'})
// first param - notification text
// second param - icon type ('success' or 'error')

import toast from "react-hot-toast";
import { FcHighPriority } from "react-icons/fc";
import success_icon from '../../assets/success_icon.svg'
import disconnect_icon from "../../assets/disconnect_icon.svg"
import delete_icon from "../../assets/delete_icon.svg"
import { ReactNode } from "react";
import './toastNotifications.css'

interface notification {
  message: string
  type: string
};
const notification = ({ message = "", type = "success" }: notification) => {
  const succefull = <img src={success_icon} alt="Success Icon" />;
  const error = <FcHighPriority fontSize={25} />;
  const disconnect = <img src={disconnect_icon} style={{width:'25px'}} alt="Disconnect Icon" />;
  const deleteIcon = <img src={delete_icon} alt="Delete Icon" />;
  const icon: { [key: string]: ReactNode } = {
    success: succefull,
    error: error,
    disconnect: disconnect,
    delete: deleteIcon
  };

  toast.custom(
    <div className="toast">
      <div className="toast__icon">{icon[type]}</div>
      <div className="toast__text">{message}</div>
    </div>
  );
};

export default notification;

