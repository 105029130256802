import { api } from "./api";

const apiPlaid = api.injectEndpoints({
  endpoints: (build) => ({
    getDiagramData: build.query({
      query: ({ idToken, period }: { idToken: string; period: string }) => ({
        url: `/automations/diagram?period=${period}`,
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    getAutomationsData: build.query({
      query: ({ idToken }: { idToken: string }) => ({
        url: "/automations/extend",
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    getAllTransactions: build.query({
      query: ({ idToken }: { idToken: string }) => ({
        url: "/automations/transfers",
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    markNotificationExpired: build.mutation({
      query: ({ idToken, idNotification }) => ({
        url: `/notification/${idNotification}/expire`,
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    markAllNotificationRead: build.mutation({
      query: ({ idToken }) => ({
        url: `/notification/read-all`,
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
    getBalanceWidgetData: build.query({
      query: ({ idToken }) => ({
        url: "/automations/balances-widget",
        headers: {
          Authorization: "Bearer " + idToken,
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useLazyGetDiagramDataQuery,
  useLazyGetAutomationsDataQuery,
  useGetAllTransactionsQuery,
  useLazyGetAllTransactionsQuery,
  useMarkNotificationExpiredMutation,
  useMarkAllNotificationReadMutation,
  useLazyGetBalanceWidgetDataQuery,
} = apiPlaid;

// router.patch("/notification/:id/read", makeNotificationRead)
// /notification/all/read
