import { useEffect, useState } from "react";
import getBankLogo from "../../helpers/getBankIcon";
import "./bankCard.css";
import { Tooltip } from "@mui/material";
function BankCard({
  account,
  onSelectAccount,
  currentAutomation,
  isEnabledAutomationMap,
  updateIsEnabledAutomation,
}: any) {
  const { mask, isUsedInAutomation, institution, id } = account;
  const bankName = institution?.name;
  const bankLogo = getBankLogo(institution?.logo, institution?.id);
  const tooltipCss = {
    tooltip: {
      sx: {
        textAlign: "left",
        color: "#EEEEEE",
        fontWeight: 400,
        width: "288px",
        lineHeight: "16px",
        border: "1px solid #000",
      },
    },
    arrow: {
      sx: {
        "&:before": {
          border: "1px solid #000",
        },
      },
    },
  };

  useEffect(() => {
    if (isUsedInAutomation) {
      let enabled = false;
      if (
        [
          currentAutomation?.checkingAccountId,
          currentAutomation?.savingAccountId,
        ].includes(id)
      ) {
        enabled = true;
      }
      updateIsEnabledAutomation(account.id, enabled);
    } else {
      updateIsEnabledAutomation(account.id, true);
    }
    if (!currentAutomation) {
      updateIsEnabledAutomation(account.id, !isUsedInAutomation);
    }
  }, []);
  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      componentsProps={tooltipCss}
      enterTouchDelay={0}
      title={
        isEnabledAutomationMap.get(account.id)
          ? ""
          : "This account is already associated with an active automation. Please select another account."
      }
      arrow
    >
      <div
        className={`bankCard ${
          isEnabledAutomationMap.get(account.id) ? "" : "bankCard-used"
        }`}
        onClick={() => {
          if (isEnabledAutomationMap.get(account.id)) {
            onSelectAccount(account);
          }
        }}
        style={{
          cursor: isEnabledAutomationMap.get(account.id)
            ? "pointer"
            : "not-allowed",
        }}
      >
        <div className="bankCard__info">
          <div className="bankCard__bankName">{bankName}</div>
          <div className="bankCard__number">{mask}</div>
        </div>
        <div className="bankCard__bankLogo">
          <img src={bankLogo} alt="Bank Logo" />
        </div>
      </div>
    </Tooltip>
  );
}

export default BankCard;
