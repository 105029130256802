import { api } from "./api";

const apiMixpanel = api.injectEndpoints({
  endpoints: (build) => ({
    sendMixpanelPublicEvent: build.mutation({
      query: (mixpanelData) => {
        const body = {
          ...mixpanelData,
          fullStorySessionUrl: null,
          referrer: window.document.referrer,
          urlPath: window.location?.href,
        };

        if (window.FS?.getCurrentSessionURL) {
          body.fullStorySessionUrl = window.FS?.getCurrentSessionURL();
        }
        return {
          url: `/mixpanel/send-event/logged-out`, // ToDo: Add correct url here
          method: "POST",
          body,
        };
      },
    }),
    sendMixpanelEvent: build.mutation({
      query: ({ tkn, body: mixpanelData }) => {
        const body = {
          ...mixpanelData,
          fullStorySessionUrl: null,
          referrer: window.document.referrer,
          urlPath: window.location?.href,
        };
        if (window.FS?.getCurrentSessionURL) {
          body.fullStorySessionUrl = window.FS?.getCurrentSessionURL();
        }
        return {
          url: `/mixpanel/send-event/logged-in`,
          method: "POST",
          body,
          headers: {
            Authorization: "Bearer " + tkn,
          },
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useSendMixpanelPublicEventMutation,
  useSendMixpanelEventMutation,
} = apiMixpanel;
