import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changeFirstName, changeLastName } from "../../../redux/sliceAuth";
import { StepWizardChildProps } from "react-step-wizard";
// @ts-ignore
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import Button from "../../../elements/Button/Button";
import "./signUpName.css";
import Wrapper from "../../../elements/Wrapper";
import TextInput from "../../../elements/Input/TextInput";
import { useEffect, useState } from "react";
import { useMixpanelPublic } from "../../../helpers/mixpanel";

declare global {
  interface Window {
    Tawk_API: any;
  }
}
const SignUpUserName: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  previousStep,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mixpanel = useMixpanelPublic();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [formError, setFormError] = useState({
    firstName: false,
    lastName: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const nameRegex = /^(?![\uD800-\uDFFF].*$)[a-zA-Z-' ]+[a-zA-Z']?$/;
  const isNameValid = (name: string) => {
    return name && nameRegex.test(name) && name.length <= 40;
  };
  const mobileScreenSize = window.screen.width < 768;

  useEffect(() => {
    if (mobileScreenSize) {
      window.Tawk_API.hideWidget();
    }
  }, [mobileScreenSize]);

  useEffect(() => {
    window.Tawk_API.hideWidget();
  }, []);

  const resetErrorOnChange = () => {
    if (errorMsg) {
      setFormError({
        firstName: false,
        lastName: false,
      });
      setErrorMsg("");
    }
  };

  const onNextHandler = async () => {
    try {
      if (!isNameValid(firstName) && !isNameValid(lastName)) {
        setFormError({
          firstName: true,
          lastName: true,
        });
        setErrorMsg("Please enter your full name.");
      } else if (!isNameValid(firstName)) {
        setFormError({
          firstName: true,
          lastName: false,
        });
        setErrorMsg("Please enter your full name.");
      } else if (!isNameValid(lastName)) {
        setFormError({
          firstName: false,
          lastName: true,
        });
        setErrorMsg("Please enter your full name.");
      } else {
        setErrorMsg("");

        dispatch(changeFirstName(firstName.replace(/\s+$/, "")));
        dispatch(changeLastName(lastName.replace(/\s+$/, "")));
        nextStep?.();
        mixpanel("Entered_name", {
          "First name": firstName.replace(/\s+$/, ""),
          "Last name": lastName.replace(/\s+$/, ""),
        });
      }
    } catch (error) {
      console.error("Error in onNextHandler SignUpName.tsx: ", error);
    }
  };


  const onBackHandler = () => {
    navigate(-1);
  };

  if (currentStep !== 1) {
    return null;
  }

  return (
    <Wrapper>
      <SignupNavbar
        step={currentStep}
        handler={onBackHandler}
        showBackButton={false}
      />
      <div className="page__infoContainer sign-up-name-container">
        <div className="page__title">Welcome to Cache!</div>
        <div className="page__subtitle">
          Please enter your name as it appears on your government issued ID.
        </div>
        <div className="input__holder">
          <div className={`customInput__holder`}>
            <TextInput
              fullWidth
              label="First Name"
              name="firstName"
              error={formError.firstName}
              value={firstName}
              onChange={(e) => {
                +setFirstName(e.target.value);
                resetErrorOnChange();
              }}
            />
          </div>
          <div className={`customInput__holder`}>
            <TextInput
              fullWidth
              label="Last Name"
              name="lastName"
              error={formError.lastName}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                resetErrorOnChange();
              }}
            />
          </div>
        </div>
        {errorMsg && (
          <div className="userName__errorMassage error__message">
            {errorMsg}
          </div>
        )}
      </div>
      <div className="haveAcc">
        Already have an account? <Link to="/login">Log in.</Link>
      </div>
      <div className="conditions">
        By continuing, you agree to Cache's
        <Link target="_blank" to="/terms-of-service">
          {" "}
          terms of service
        </Link>{" "}
        and
        <Link target="_blank" to="/privacy-policy">
          {" "}
          privacy policy.
        </Link>
      </div>
      <div className="button__holder">
        <Button onClick={() => onNextHandler()} type={"primary-btn"}>
          Next
        </Button>
      </div>
      <input type="hidden" id="abandonedOnboardingStage" value={"name"} />
    </Wrapper>
  );
};

export default SignUpUserName;
