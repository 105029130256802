import Lottie from "lottie-react";
import animation_scan from "../../assets/document_scan.json";
import "./animationScan.css";

function AnimationScan() {
    return (
        <div className="verifyAccount__infocontainer">
            <div className="verifyAcc__icon">
                <Lottie animationData={animation_scan} loop={true} />
            </div>
            <p className="verifyAcc__subtitle">
                Thank you. Your email has been verified.
            </p>
            <p className="verifyAcc__subtitle">
                We’re now verifying the rest of your information.
            </p>
        </div>
    )
};

export default AnimationScan;