import { useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import successIcon from "../../assets/passsword_successIcon.svg";
import errorIcon from "../../assets/passsword_errorIcon.svg";
import "./passwordInput.css";
import { IconButton, InputAdornment } from "@mui/material";
import { AiOutlineEye } from "react-icons/ai";
import { FiEyeOff } from "react-icons/fi";

const PasswordField = styled((props: TextFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <TextField
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                disableUnderline: true,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            edge="end"
                        >
                            {showPassword ? <AiOutlineEye color="#EEEEEE" /> : <FiEyeOff color="#EEEEEE" />}
                        </IconButton>
                    </InputAdornment>
                )
            } as Partial<OutlinedInputProps>}
            {...props}
        />
    )
})(({ theme }) => ({
    '& .MuiFilledInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#303030',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        border: '1px solid #303030',
        '& .MuiInputBase-input': {
            paddingLeft: 16,
            paddingBottom: 16
        },
        '&:hover': {
            backgroundColor: '#303030',
        },
        '&.Mui-focused': {
            backgroundColor: '#303030',
            border: '1px solid #39848a'
        },
        '&.Mui-disabled': {
            backgroundColor: '#303030'
        },
        '&.Mui-disabled input': {
            color: '#707070'
        },
        '&.Mui-error': {
            backgroundColor: '#303030',
            border: '1px solid #F66'
        },
        '& .MuiInputAdornment-root': {
            '& button': {
                marginRight: 0,
                backgroundColor: 'initial',

            },
        },
        '&.Mui-error .MuiInputAdornment-root': {
            '& button svg': {
                color: '#F66 !important'
            },
        },
        '&.Mui-disabled .MuiInputAdornment-root': {
            '& button svg': {
                color: '#707070 !important'
            },
        }
    },
    '& .MuiInputLabel-root': {
        color: '#eeeeee',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        left: 4,
        '&.MuiInputLabel-shrink': {
            fontSize: '8px',
            transform: 'translate(12px, 7px) scale(1)'
        },
        '&.Mui-focused': {
            color: '#eeeeee',
        },
        '&.Mui-error': {
            color: '#eeeeee',
        },
        '&.Mui-disabled': {
            color: '#707070'
        },
    },
}));

function PasswordInput({ label, name, inputProps, autoComplete, disabled, userPassword, onChange, setPasswordValid, passwordError, showBottomValidation=true, styles={} }: any) {
  const digitRegex = /\d/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  return (
    <>
        <PasswordField
            fullWidth
            label={label || "Password"}
            variant="filled"
            name={name || "userPassword"}
            error={passwordError}
            autoComplete={autoComplete}
            value={userPassword}
            inputProps={inputProps}
            disabled={disabled}
            onChange={onChange}
            style={{
                height: 52,
                ...styles
            }}
        />
      {showBottomValidation && userPassword && (
        <PasswordStrengthBar
          password={userPassword}
          className="strengBar"
          minLength={0}
          // shortScoreWord="minimal lenght 8"
          barColors={["#303030", "#39848A", "#39848A", "#39848A", "#39848A"]}
          scoreWords={["Very weak", "Weak", "Good", "Strong", "Very Strong"]}
          onChangeScore={(e) => {
            if (
              userPassword?.length >= 12 &&
              uppercaseRegex.test(userPassword) &&
              lowercaseRegex.test(userPassword) &&
              digitRegex.test(userPassword) &&
              specialCharRegex.test(userPassword)
            ) {
              setPasswordValid(true);
            } else {
              setPasswordValid(false);
            }
          }}
        />
      )}
      {showBottomValidation && userPassword && (
        <>
          <div className="password__conditions-title">
            Your password must contain at least:
          </div>
          <div className="password__condition-item">
            {!userPassword?.length || userPassword?.length < 12 ? (
              <img src={errorIcon} alt="error" />
            ) : (
              <img src={successIcon} alt="success" />
            )}

            <div className="condition-description">12 characters</div>
          </div>
          <div className="password__condition-item">
            {uppercaseRegex.test(userPassword) ? (
              <img src={successIcon} alt="success" />
            ) : (
              <img src={errorIcon} alt="error" />
            )}
            <div className="condition-description">1 upper case letter</div>
          </div>
          <div className="password__condition-item">
            {lowercaseRegex.test(userPassword) ? (
              <img src={successIcon} alt="success" />
            ) : (
              <img src={errorIcon} alt="error" />
            )}
            <div className="condition-description">1 lower case letter</div>
          </div>
          <div className="password__condition-item">
            {digitRegex.test(userPassword) ? (
              <img src={successIcon} alt="success" />
            ) : (
              <img src={errorIcon} alt="error" />
            )}

            <div className="condition-description">1 number</div>
          </div>
          <div className="password__condition-item">
            {specialCharRegex.test(userPassword) ? (
              <img src={successIcon} alt="success" />
            ) : (
              <img src={errorIcon} alt="error" />
            )}
            <div className="condition-description">1 special character</div>
          </div>
        </>
      )}
    </>
  );
}

export default PasswordInput;
