import "./CustomCheckbox.css";

function CustomCheckbox({ label, onChange, checked }: any) {
  return (
    <label className="question">
      <input type="checkbox" className="questionCheckbox__input" onChange={onChange} checked={checked} value={typeof label === "string" ? label : ""} />
      <span className="checkbox__custom"></span>
      {label}
    </label>
  );
}

export default CustomCheckbox;
