import React, { useContext, useEffect, useState } from "react";
import {
  useSendMixpanelPublicEventMutation,
  useSendMixpanelEventMutation,
} from "../api/apiMixpanel";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { AuthContext } from "../context/AuthContext";
import { PLAID_LINK_NAMES, PLAID_LINK_TEXT } from "./constants";

export function useMixpanelPublic() {
  const [mixpanelPublic, mixpanelPublicResult] =
    useSendMixpanelPublicEventMutation();

  const sessionId = useSelector<RootState>(
    (state) => state.appState.sessionId,
  ) as string;

  const sendEventPublic = async (event: string, mixpanelData?: any) => {
    await mixpanelPublic({
      event,
      mixpanelData,
      sessionId,
    });
  };
  return sendEventPublic;
}

export function useMixpanel() {
  const [mixpanel, mixpanelResult] = useSendMixpanelEventMutation();

  const context = useContext(AuthContext);

  const sessionId = useSelector<RootState>(
    (state) => state.appState.sessionId,
  ) as string;

  const sendEvent = async (event: string, mixpanelData?: any, tkn?: string) => {
    let firebaseToken = tkn;

    if (!firebaseToken) {
      const { user } = context;

      if (!user) {
        return;
      }

      firebaseToken = await user.getIdToken();
    }

    if(PLAID_LINK_NAMES.includes(event)){
      event = PLAID_LINK_TEXT + event
    }

    await mixpanel({
      tkn: firebaseToken,
      body: {
        event,
        mixpanelData,
        sessionId,
      },
    });
  };
  return sendEvent;
}
