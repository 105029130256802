import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { api, apiReducer } from "../api/api";
import auth from "./sliceAuth";
import accounts from "./sliceAccounts";
import userAutomation from "./sliceUserAutomation";
import userNotifications from "./sliceNotifications";
import appState from "./sliceAppState";

export const store = configureStore({
  reducer: combineReducers({
    api: apiReducer,
    auth,
    accounts,
    userAutomation,
    userNotifications,
    appState,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
