import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import beer from "../../assets/c_beer.svg"; //<80
import coffee from "../../assets/c_coffe.svg"; //80-500
import grab from "../../assets/c_grab.svg"; //500-1000
import rest from "../../assets/c_rest.svg"; //1000-5000
import mickey from "../../assets/c_mickey.svg"; //5000-10000
import trip from "../../assets/c_trip.svg"; //10000-20000
import car from "../../assets/c_car.svg"; //20000+

import "./saveMoneyResult.css";

const SaveMoneyResult = ({ result }: any) => {
  const resultInt = parseInt(result);

  let data = {
    text: `Treat a good friend to a few drinks. With the time you’ll save by using Cache, you could even spend the whole day together.`,
    img: beer,
  };

  if (resultInt > 80 && resultInt <= 500) {
    data.text = `Use the money you save with Cache to buy coffee for ${Math.round(
      resultInt / 20,
    )} of your friends, and use the time you saved to spend ${Math.round(
      570 / (resultInt / 20),
    )} minutes catching up with each.`;
    data.img = coffee;
  }

  if (resultInt > 500 && resultInt <= 1000) {
    data.text = `Grab ${Math.round(
      resultInt / 250,
    )} tickets to Taylor Swift’s hyped Eras Tour. Then, spend half a day making friendship bracelets.`;
    data.img = grab;
  }

  if (resultInt > 1000 && resultInt <= 5000) {
    data.text =
      "Whisk your family to a sunny weekend getaway in Mexico. Your round-trip flight time should be more than covered by the time you’ll save with Cache.";
    data.img = rest;
  }

  if (resultInt > 5000 && resultInt <= 10000) {
    data.text =
      "Spend a week with your family at Disney World! And since you saved all this time by using Cache, why not stay an extra day?";
    data.img = mickey;
  }

  if (resultInt > 10000 && resultInt <= 20000) {
    data.text =
      "Paris, London, or Rome? You should save enough to visit them all. Then, you can use the time you saved with Cache to recover from your jet lag.";
    data.img = trip;
  }

  if (resultInt > 20000) {
    data.text =
      "Treat yourself to a new car, and use the time you saved with Cache to enjoy a scenic drive and a well-deserved day trip.";
    data.img = car;
  }

  return (
    <Box className="smr">
      <Typography variant="h5" className="smr__title" color="primary">
        Using Cache, you’ll save about ${resultInt.toLocaleString("en-US")} and
        ~9.5 hours in the coming{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          year.
          <Tooltip
            classes={{ tooltip: "saveMoneyCalculation" }}
            title="This calculation is for illustration purposes only and was made assuming an average tax rate of 25%."
            arrow
            enterTouchDelay={0}
            componentsProps={{
              tooltip: {
                sx: {
                  textAlign: "left !important",
                  maxWidth: "308px",
                },
              },
            }}
          >
            <InfoOutlinedIcon className="smr__infoIcon" />
          </Tooltip>
        </span>
      </Typography>
      <p className="smr__text">{data.text}</p>
      <img className="smr__img" src={data.img} alt="img" />
    </Box>
  );
};

export default SaveMoneyResult;
