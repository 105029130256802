import Button from "../../../elements/Button/Button";
import PasswordInput from "../../../elements/PasswordInput/PasswordInput";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  getAuth,
  signOut,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSaveUserPersonaInfoMutation } from "../../../api/apiAuth";
import { RootState } from "../../../redux/store";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import notification from "../../../elements/ToastNotifications/ToastNotifications";
import { StepWizardChildProps } from "react-step-wizard";
// @ts-ignore

import "./signUpPassword.css";
import Wrapper from "../../../elements/Wrapper";
import { useNavigate } from "react-router-dom";
import { useMixpanel } from "../../../helpers/mixpanel";

const SignUpPassword: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  previousStep,
}) => {
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const auth = getAuth();
  const mixpanel = useMixpanel();
  const [passwordError, setPasswordError] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [saveUserPersonalInfo, saveUserPersonaInfoResult] =
    useSaveUserPersonaInfoMutation();
  const { email, firstName, lastName } = authInfo;
  const navigation = useNavigate();

  const resetErrorOnChange = () => {
    setPasswordError(false);
  };
  const mobileScreenSize = window.screen.width < 768;
  useEffect(() => {
    if (mobileScreenSize) {
      window.Tawk_API.hideWidget();
    }
  }, [mobileScreenSize]);

  const onNextHandler = async () => {
    if (authInfo.email && userPassword && passwordValid) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          authInfo.email,
          userPassword,
        );

        const user = userCredential.user;
        if (!user) {
          return;
        }

        await updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        });
        const tkn = await user?.getIdToken();
        const body = {
          email,
          firstName,
          lastName,
          uid: auth.currentUser?.uid,
        };

        window.FS.setUserVars({
          displayName: `${firstName} ${lastName}`,
          email: email,
        });

        try {
          const res = (await saveUserPersonalInfo({ tkn, body })) as any;
          if (res?.data?.status !== 201) {
            throw new Error("User not created in DB, status not 201");
          }
        } catch (error) {
          console.log("Error in SignUpPassword SignUpPassword.tsx: ", error);
          user.delete();
          throw new Error("User not created in DB, request error");
        }

        nextStep?.();
        mixpanel(
          "Signed_up",
          {
            isCreateUser: true,
            firstName,
            lastName,
            email,
          },
          tkn,
        );
      } catch (error) {
        console.log("Error in SignUpPassword SignUpPassword.tsx: ", error);
        signOut(auth).then(() => {
          navigation("/login");
        });
      }
    } else {
      setPasswordError(true);
    }
  };

  const onBackHandler = () => {
    previousStep?.();
    setPasswordError(false);
  };

  if (currentStep !== 3) {
    return null;
  }

  return (
    <Wrapper>
      <SignupNavbar
        step={currentStep}
        handler={onBackHandler}
        showBackButton={true}
      />
      <div className="page__infoContainer sign-up-password-container">
        <div className="page__title">Next, create your password.</div>
        <div className="page__subtitle">
          For your security, please use a combination of letters, numbers and
          symbols, and at least 12 characters.
        </div>
        <div className="passwordInput">
          <PasswordInput
            userPassword={userPassword}
            onChange={(e: any) => {
              setUserPassword(e.target.value);
              resetErrorOnChange();
            }}
            setPasswordValid={setPasswordValid}
            passwordError={passwordError}
          />
        </div>

        {passwordError && !userPassword && (
          <div className="passwod__errorMessage error__message">
            Please create a password.
          </div>
        )}
      </div>
      <div className="button__holder">
        <Button
          onClick={onNextHandler}
          type={"primary-btn"}
          style={{ opacity: 1 }}
          disabled={saveUserPersonaInfoResult.isLoading}
        >
          Next
        </Button>
      </div>
      <input type="hidden" id="abandonedOnboardingStage" value={"password"} />
    </Wrapper>
  );
};

export default SignUpPassword;
