import { api } from "./api";

const apiThemes = api.injectEndpoints({
  endpoints: (build) => ({
    getThemes: build.query({
        query: ({ themeType }: { themeType: string }) => ({
        params: {theme: themeType},
        url: "/themes"
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetThemesQuery
} = apiThemes;