import { Box } from "@mui/material";
import Marquee from "react-fast-marquee";
import classNames from "classnames";
import LogoStrip from "../../assets/logo-strip.svg";
import "./logos-slider.css";

const LogosSlider = ({ title }: any) => {
  return (
    <>
      <Box className="greyBox">
        <div className="block-title" color="black">
          {title}
        </div>
        <Marquee
          play={true}
          pauseOnHover={true}
          direction="left"
          speed={50}
          loop={0}
        >
          <div className={classNames("block", "keyLogo")}>
            <img src={LogoStrip} />
          </div>
          <div className={classNames("block", "keyLogo")}>
            <img src={LogoStrip} />
          </div>
        </Marquee>
      </Box>
    </>
  );
};

export default LogosSlider;
