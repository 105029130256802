import { styled } from '@mui/material/styles';
import { MenuItem, TextField } from "@mui/material";

const Arrow = (props: any) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
        <path d="M6.33414 6.95001C6.2008 6.95001 6.0758 6.92917 5.95914 6.88751C5.84247 6.84584 5.73414 6.77501 5.63414 6.67501L1.00914 2.05001C0.825804 1.86667 0.738304 1.63751 0.746637 1.36251C0.754971 1.08751 0.850804 0.85834 1.03414 0.675006C1.21747 0.491673 1.4508 0.400006 1.73414 0.400006C2.01747 0.400006 2.2508 0.491673 2.43414 0.675006L6.33414 4.57501L10.2591 0.650006C10.4425 0.466673 10.6716 0.379173 10.9466 0.387506C11.2216 0.39584 11.4508 0.491673 11.6341 0.675006C11.8175 0.85834 11.9091 1.09167 11.9091 1.37501C11.9091 1.65834 11.8175 1.89167 11.6341 2.07501L7.03414 6.67501C6.93414 6.77501 6.8258 6.84584 6.70914 6.88751C6.59247 6.92917 6.46747 6.95001 6.33414 6.95001Z"/>
    </svg>
);

const SelectInput = styled(({label, options, style={}, ...rest}: any) => {
    return (
        <TextField
            label="State"
            variant="filled"
            fullWidth
            InputProps={{
                disableUnderline: true,
            }}
            select
            SelectProps={{ IconComponent: (props) => <Arrow {...props} /> }}
            style={{
                height: 52,
                ...style
            }}
            {...rest}
        >
            {
                options.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                ))
            }
        </TextField>
    )
})(({ theme }) => ({
    '& .MuiFilledInput-root': {
        overflow: 'hidden',
        borderRadius: 8,
        backgroundColor: '#303030',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        border: '1px solid #303030',
        '& .MuiInputBase-input': {
            paddingLeft: 16,
            paddingBottom: 16
        },
        '&:hover': {
            backgroundColor: '#303030',
        },
        '&.Mui-focused': {
            backgroundColor: '#303030',
            border: '1px solid #39848a'
        },
        '& .MuiFilledInput-input:focus': {
            backgroundColor: '#303030',
        },
        '&.Mui-disabled': {
            backgroundColor: '#303030'
        },
        '&.Mui-disabled .Mui-disabled': {
            color: '#707070',
            '-webkit-text-fill-color': 'initial'
        },
        '&.Mui-error': {
            backgroundColor: '#303030',
            border: '1px solid #F66'
        },
        '& input::placeholder': {
            opacity: 1,
            color: '#FAFAFA'
        },
        '& .MuiSelect-icon': {
            top: 'initial',
            right: '16px',
            fill: '#EEEEEE'
        },
        '&.Mui-disabled .MuiSelect-icon': {
            fill: '#707070'
        }
    },
    '& .MuiInputLabel-root': {
        color: '#eeeeee',
        fontSize: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        left: 4,
        '&.MuiInputLabel-shrink': {
            fontSize: '8px',
            transform: 'translate(12px, 7px) scale(1)'
        },
        '&.Mui-focused': {
            color: '#eeeeee',
        },
        '&.Mui-error': {
            color: '#eeeeee',
        },
        '&.Mui-disabled': {
            color: '#707070'
        },
    },
}));

export default SelectInput;
