import Button from "../Button/Button";
import info_icon from "../../assets/info_icon.svg";
import "./NotificationBar.css";
import PlaidAuth from "../../pages/Plaid/PlaidAuth";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notification from "../../elements/ToastNotifications/ToastNotifications";
import { resetDisabledAutomation } from "../../redux/sliceUserAutomation";
import { useEffect, useState } from "react";
import Loader from "../Loader/Loader";
import { setReconnectError } from "../../redux/sliceNotifications";
import {useMixpanel} from "../../helpers/mixpanel";
// @ts-ignore

function NotificationBar() {
  const [disAccToken, setDisAccToken] = useState("");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const [isReconnectLoading, setIsReronnectLoading] = useState(false);

  const disabledAutomation: any = useSelector<RootState>(
    (state) => state?.userAutomation?.disabledAutomation,
  );

  useEffect(() => {
    if (disabledAutomation[0]?.checkingAccount?.status === "reconnection_need") {
      setDisAccToken(disabledAutomation[0]?.checkingAccount?.accessToken);
    }
    if (disabledAutomation[0]?.savingAccount?.status === "reconnection_need") {
      setDisAccToken(disabledAutomation[0]?.savingAccount?.accessToken);
    }
  }, [disabledAutomation]);

  const reloadPage = () => {
    notification({
      message: "Reconnection successful. Automation resumed.",
      type: "success",
    });
    dispatch(resetDisabledAutomation());
    dispatch(setReconnectError(false));
    navigation("/automations");
  };

  return (
    <div
      className={
        disabledAutomation.length === 0
          ? "notificationBar hideBar"
          : "notificationBar"
      }
    >
      <div className="notificationBar__container">
        <div className="notificationBar__info">
          <div className="notificationBar__info-icon">
            <img src={info_icon} alt="Info Icon" />
          </div>
          <p className="notificationBar__info-text text__content">
            Automation paused due to disconnected account.
          </p>
        </div>
        <div className="notificationBar__button">
          <PlaidAuth
            accessToken={disAccToken}
            setIsLoading={setIsReronnectLoading}
            reloadPage={reloadPage}
          >
            <Button
              type="warning-btn"
              onClick={() => {
                mixpanel('Clicked_Reconnect_Now', {'Origination point': 'notifications'})
              }}
            >
              Reconnect Now
            </Button>
          </PlaidAuth>
        </div>
        <Loader loading={isReconnectLoading} />
      </div>
    </div>
  );
}

export default NotificationBar;
