import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeEmail } from "../../../redux/sliceAuth";
import { RootState } from "../../../redux/store";
import {
  useUserEditMutation,
  useUserExistMutation,
} from "../../../api/apiAuth";
import Button from "../../../elements/Button/Button";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import { StepWizardChildProps } from "react-step-wizard";
// @ts-ignore
import "./signUpEmail.css";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updateEmail,
} from "firebase/auth";
import Wrapper from "../../../elements/Wrapper";
import AuthPopUp from "../../../elements/Firebase/AuthPopUp";
import TextInput from "../../../elements/Input/TextInput";
import { useMixpanelPublic } from "../../../helpers/mixpanel";

const SignUpEmail: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  previousStep,
}) => {
  const [isCheck, setIsCheck] = useState(false);
  const userData: any = useSelector<RootState>((state) => state.auth);
  const [userExist] = useUserExistMutation();
  const [editUser, editUserResult] = useUserEditMutation();
  const [authPopUp, setAuthPopUp] = useState(false);
  const dispatch = useDispatch();
  const mixpanel = useMixpanelPublic();
  const auth = getAuth();

  const [userEmail, setUserEmail] = useState("");
  const [formError, setFormError] = useState({
    email: false,
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isEmailValid = (email: string) => {
    return email && emailRegex.test(email) && email.length <= 40;
  };
  const resetErrorOnChange = () => {
    if (errorMsg) {
      setFormError({
        email: false,
      });
      setErrorMsg("");
      setShowLogin(false);
    }
  };
  const mobileScreenSize = window.screen.width < 768;
  useEffect(() => {
    if (mobileScreenSize) {
      window.Tawk_API.hideWidget();
    }
  }, [mobileScreenSize]);

  useEffect(() => {
    if (currentStep == 11) {
      setUserEmail(userData?.email);
    }
  }, [currentStep]);

  const reAuthUser = (password: string) => {
    const currentUser: any = auth?.currentUser;
    const email = currentUser?.email || userData?.email;
    const credential = EmailAuthProvider.credential(email, password);
    return reauthenticateWithCredential(currentUser, credential);
  };

  const setNewEmail = () => {
    const user: any = auth?.currentUser;
    auth.currentUser?.getIdToken().then(async (tkn) => {
      const body = {
        email: userEmail,
      };

      editUser({ tkn, body })
        .then((res: any) => {
          if (res.error) {
            console.log("Error in editUser SignUpEmail.tsx: ", res.error);
            mixpanel("Error", { message: res.error });
          } else {
            dispatch(changeEmail(userEmail));
            previousStep?.();
            mixpanel("Changed_email", { Email: userEmail });
          }
        })
        .catch((error) => {
          if (error.code == "auth/requires-recent-login") {
            setAuthPopUp(true);
          }
          console.log("Sign up: Error in updateEmail: ", error?.message);
          mixpanel("Error", { message: error?.message });
        });
    });
  };

  const onNextHandler = () => {
    if (!isEmailValid(userEmail)) {
      setFormError({
        email: true,
      });
      if (userEmail && !emailRegex.test(userEmail)) {
        setErrorMsg("Invalid email address. Please try again.");
      } else {
        setErrorMsg("Please enter your email.");
      }
    } else {
      setIsCheck(true);
      userExist({
        email: userEmail,
      })
        .then((res: any) => {
          if (res?.data?.status === 200) {
            setIsCheck(false);
            if (currentStep == 11) {
              setNewEmail();
            }

            if (currentStep == 2) {
              dispatch(changeEmail(userEmail));
              setFormError({
                email: false,
              });
              setErrorMsg("");
              nextStep?.();
              mixpanel("Entered_email", { Email: userEmail });
            }
          } else if (res?.error) {
            if (currentStep == 11) {
              setIsCheck(false);
              setFormError({
                email: true,
              });
              setErrorMsg("It seems you already have an account with us.");
              setShowLogin(true);
              // previousStep?.();
            }
            if (currentStep == 2) {
              setIsCheck(false);
              setFormError({
                email: true,
              });
              setErrorMsg("It seems you already have an account with us.");
              setShowLogin(true);
            }
          }
        })
        .catch((error) => {
          console.log("Error in userExist SignUpEmail.tsx: ", error?.message);
          mixpanel("Error", { message: error?.message });
        });
    }
  };

  const onBackHandler = () => {
    previousStep?.();
  };

  if (currentStep !== 2 && currentStep !== 11) {
    return null;
  }

  return (
    <Wrapper>
      <SignupNavbar
        step={currentStep}
        handler={onBackHandler}
        showBackButton={true}
      />
      <div className="page__infoContainer sign-up-email-container">
        {currentStep == 2 ? (
          <div className="page__title">
            {`Nice to meet you, ${userData.firstName}.`} <br></br>Please add
            your email.
          </div>
        ) : (
          <div className="page__title smaller-size">Enter your new email.</div>
        )}
        <div className="page__subtitle smaller-size">
          You’ll use this to log into your Cache account.
        </div>
        <div className="input__holder">
          <div className={`customInput__holder`}>
            <TextInput
              fullWidth
              label="Email"
              name="signUpEmail"
              error={formError.email}
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
                resetErrorOnChange();
              }}
            />
          </div>
        </div>
        {errorMsg || showLogin ? (
          <div className="email__errorMassage error__message">
            {errorMsg}
            {showLogin ? (
              <div className="login__link">
                <Link to="/login">Log in.</Link>
              </div>
            ) : null}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="button__holder">
        <Button
          onClick={() => onNextHandler()}
          type={"primary-btn"}
          disabled={isCheck}
          style={{ opacity: 1 }}
        >
          Next
        </Button>
      </div>
      {authPopUp ? (
        <AuthPopUp
          open={authPopUp}
          handleClose={() => setAuthPopUp(false)}
          reAuthUser={reAuthUser}
          callBack={setNewEmail}
        />
      ) : null}
      <input
        type="hidden"
        id="abandonedOnboardingStage"
        value={currentStep == 2 ? "email" : "changeEmail"}
      />
    </Wrapper>
  );
};

export default SignUpEmail;
