import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl =
  process.env.REACT_APP_BACKEND_API_URL || "http://localhost:9000";
//const baseUrl = "http://localhost:9000"; // TODO: change

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: () => ({}),
});

export const apiReducer = api.reducer;
export const apiMiddleware = api.middleware;
