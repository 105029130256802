import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StepWizardChildProps } from "react-step-wizard";
import SignupNavbar from "../../../elements/SignupNavbar/SignupNavbar";
import Button from "../../../elements/Button/Button";
import "./addAutomationInstruction.css";
import { useContext, useEffect, useState } from "react";
import { removeAccounts } from "../../../redux/sliceAccounts";
import Wrapper from "../../../elements/Wrapper";
// @ts-ignore
import { useUserEditMutation } from "../../../api/apiAuth";
import { AuthContext } from "../../../context/AuthContext";
import CustomCheckbox from "../../../elements/CustomCheckbox/CustomCheckbox";
import { RootState } from "../../../redux/store";
import { useAuthMutation } from "../../../api/apiPlaid";
import { useMixpanel } from "../../../helpers/mixpanel";

const AddAutomationInstruction: React.FC<Partial<StepWizardChildProps>> = ({
  currentStep,
  nextStep,
  previousStep,
}) => {
  const authInfo: any = useSelector<RootState>((state) => state.auth);
  const context = useContext(AuthContext);
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const [editUser, editUserResult] = useUserEditMutation();
  const [error, setError] = useState(false);
  const [checked, setChecked] = useState(authInfo.confirmedCachePolicy);
  const [plaidAuth, plaidAuthResult] = useAuthMutation();

  useEffect(() => {
    if (currentStep == 4) {
      dispatch(removeAccounts());
    }
  }, [currentStep]);

  if (currentStep !== 4) {
    return null;
  }

  const onNextHandler = () => {
    if (!checked) {
      setError(true);
    } else {
      const { user } = context;
      user
        ?.getIdToken()
        .then(async (tkn) => {
          const body = {
            confirmedCachePolicy: true,
          };
          await editUser({ tkn, body }).then((res: any) => {
            if (res.error) {
              console.log(
                "Error in editUser AddAutomationInstruction.tsx: ",
                res.error,
              );
            } else {
              mixpanel("Agreed_to_terms", { Consented: true }, tkn);
              nextStep?.();
            }
          });
          //----TEMPORARY FOR PLAID AUTH REQUEST TESTING!!!------//
          // const metadata = {
          //   institution: { institution_id: "test" },
          //   accounts: [{ name: "test", mask: "0000" }],
          // };
          // const publicToken = "testPublicToken";
          //
          //          await plaidAuth({
          //            publicToken,
          //            metadata,
          //            firebaseTkn: tkn,
          //          });
          //          plaidAuthResult.reset();
          //          console.log("TESTING /PLAID/AUTH HAVE BEEN SENT!");
          //----------------------------------------------------//
        })
        .catch((error) => {
          console.log("Error in getIdToken automation instructions: ", error);
        });
    }
  };

  return (
    <Wrapper>
      <SignupNavbar
        step={currentStep}
        handler={() => { }}
        showBackButton={false}
      />
      <div className="page__infoContainer automation_instruction">
        <div className="page__title">
          You’re signed up! <br />
          Now, let’s set up your automation.
        </div>
        <div className="page__subtitle">
          <p>
            Set up is simple and secure. Once your automation is set, Cache will
            automatically move funds to savings, maximizing interest earned.
          </p>
          <p>
            And if your checking account ever dips below your comfort zone,
            Cache will automatically transfer money back from savings.
          </p>
        </div>
      </div>
      <div className="instructionCheckbox__holder">
        <CustomCheckbox
          checked={checked}
          label={
            error ? (
              <span className="automation__conditions checkError">
                By setting up an automation, you agree to Cache’s terms of
                service and privacy policy, as well as to Dwolla’s terms of
                service and privacy policy.
              </span>
            ) : (
              <span className="automation__conditions">
                By setting up an automation, you agree to Cache’s
                <Link target="_blank" to="/terms-of-service">
                  {" "}
                  terms of service
                </Link>{" "}
                and
                <Link target="_blank" to="/privacy-policy">
                  {" "}
                  privacy policy,
                </Link>{" "}
                as well as to Dwolla’s
                <Link to="https://www.dwolla.com/legal/tos/" target="_blank">
                  {" "}
                  terms of service{" "}
                </Link>{" "}
                and
                <Link
                  to="https://www.dwolla.com/legal/privacy/"
                  target="_blank"
                >
                  {" "}
                  privacy policy.
                </Link>
              </span>
            )
          }
          onChange={(e: any) => {
            setChecked(e.target.checked);
            setError(false);
          }}
        />
      </div>
      <div className="button__holder">
        <Button
          disabled={editUserResult.isLoading}
          onClick={() => onNextHandler()}
          type={"primary-btn"}
        >
          Next
        </Button>
      </div>
      <input type="hidden" id="abandonedOnboardingStage" value={"consent"} />
    </Wrapper>
  );
};

export default AddAutomationInstruction;
