import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import AccountCard from "../AccountCard/AccountCard";
import Button from "../Button/Button";
import { Dialog, DialogContent } from "@mui/material";
// @ts-ignore
import PlaidAuth from "../../pages/Plaid/PlaidAuth";
import notification from "../ToastNotifications/ToastNotifications";
import { resetDisabledAutomation } from "../../redux/sliceUserAutomation";
import { useNavigate } from "react-router-dom";
import { setReconnectError } from "../../redux/sliceNotifications";
import arrows_red from "../../assets/arrows_red_vertical.png";
import close_btn from "../../assets/btn_close.svg";

import "./ReconnectAccountModal.css";
import {useMixpanel} from "../../helpers/mixpanel";

function ReconnectAccountModal({ onHide, open }: { onHide: () => void, open: boolean }) {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const mixpanel = useMixpanel();

  const disabledAutomation: any = useSelector<RootState>(
    (state) => state.userAutomation.disabledAutomation,
  );

  const isReconnectAcountError: any = useSelector<RootState>(
    (state) => state.userNotifications.errorDisconnect,
  );

  const isDisconnectSavingsAccount =
    disabledAutomation[0]?.savingAccount?.status === "enabled" ? false : true;
  const isDisconnectCheckingAccount =
    disabledAutomation[0]?.checkingAccount?.status === "enabled" ? false : true;
  const disconnectedAccountAccessToken = isDisconnectSavingsAccount
    ? disabledAutomation[0]?.savingAccount?.accessToken
    : disabledAutomation[0]?.checkingAccount?.accessToken;

  const reloadPage = () => {
    notification({
      message: "Reconnection successful. Automation resumed.",
      type: "success",
    });
    dispatch(resetDisabledAutomation());
    onHide();
    navigation("/automations");
  };

  const reconnect = (
    <>
      <button className="closeButton" onClick={onHide}>
        <img src={close_btn} alt="Close Button" />
      </button>
      <DialogContent>
        <p className="reconnectModal__content-title title">
          Reconnect bank account.
        </p>
        <p className="reconnectModal__content-subtitle info__subtitle">
          Bank accounts may disconnect from time to time due to technical reasons,
          causing automations to pause.
        </p>
        <p className="reconnectModal__content-subtitle info__subtitle">
          Please reconnect the highlighted bank accounts to resume your
          automations.
        </p>
        <div className="reconnectCard__holder">
          <AccountCard
              item={disabledAutomation[0]?.checkingAccount}
              type={"Checking Account"}
              disconnected={isDisconnectCheckingAccount}
              reconnectionFlow={true}
          />
          <div className="arrows__icon">
            <img src={arrows_red} alt="" />
          </div>
          <AccountCard
              item={disabledAutomation[0]?.savingAccount}
              type={"Savings Account"}
              disconnected={isDisconnectSavingsAccount}
              reconnectionFlow={true}
          />
        </div>
        <div className="reconnectButton_holder">
          <PlaidAuth
              accessToken={disconnectedAccountAccessToken}
              reloadPage={reloadPage}
          >
            <Button
                type={"primary-btn"}
                onClick={() => {
                  mixpanel("Clicked_Reconnect_Now", {
                    "Origination point": "pop up",
                  });
                }}
            >
              Reconnect Now
            </Button>
          </PlaidAuth>
        </div>
      </DialogContent>
    </>
  );

  const errorReconnect = (
    <>
      <button
        className="closeButton"
        onClick={() => {
          onHide();
          dispatch(setReconnectError(false));
        }}
      >
        <img src={close_btn} alt="Close Button" />
      </button>
      <DialogContent>
        <p className="reconnectModal__content-title title">
          Reconnection unsuccessful.
        </p>
        <p className="reconnectModal__content-subtitle info__subtitle">
          The reconnection attempt has failed. Please try again.
        </p>
        <p className="reconnectModal__content-subtitle info__subtitle">
          The following automation will remain paused until you reconnect.
        </p>
        <div className="reconnectCard__holder">
          <AccountCard
              item={disabledAutomation[0]?.checkingAccount}
              type={"Checking Account"}
              disconnected={isDisconnectCheckingAccount}
              reconnectionFlow={true}
          />
          <div className="arrows__icon">
            <img src={arrows_red} alt="" />
          </div>
          <AccountCard
              item={disabledAutomation[0]?.savingAccount}
              type={"Savings Account"}
              disconnected={isDisconnectSavingsAccount}
              reconnectionFlow={true}
          />
        </div>
        <div className="reconnectButton_holder">
          <PlaidAuth
              accessToken={disconnectedAccountAccessToken}
              reloadPage={reloadPage}
          >
            <Button
                type={"black-btn deleteBtn"}
                style={{
                  color: "var(--grey30))",
                  backgroundColor: "var( --primary-color)",
                  margin: 0,
                }}
            >
              Try Again
            </Button>
          </PlaidAuth>
        </div>
      </DialogContent>
    </>
  );

  return (
      <Dialog
          onClose={() => onHide()}
          open={open}
          classes={{
              root: "reconnect-account-dialog",
              paper: "reconnect-account-dialog-paper",
          }}
          scroll="paper"
      >
        {isReconnectAcountError ? errorReconnect : reconnect}
      </Dialog>
  );
}

export default ReconnectAccountModal;
