import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetUserAccountsQuery,
  useModifyAutomationMutation,
  usePostAutomationMutation,
} from "../../api/apiPlaid";
import {
  setSavingsAccountList,
  setCheckingAccountList,
} from "../../redux/sliceAccounts";
import { RootState } from "../../redux/store";
import PlaidAuth from "../Plaid/PlaidAuth";
import notification from "../../elements/ToastNotifications/ToastNotifications";
import HeaderMenu from "../../elements/HeaderMenu/HeaderMenu";
import AccountCard from "../../elements/AccountCard/AccountCard";
import Button from "../../elements/Button/Button";
import BackButton from "../../elements/BackButton/BackButton";
import ConnectionButton from "../../elements/ConnectionButton/ConnectionButton";
import BalanceInput from "../../elements/BalanceInput/BalanceInput";
import arrows from "../../assets/arrows_vertical.png";
import "./dashboardAddAutomation.css";
import { AuthContext } from "../../context/AuthContext";
import { useMixpanel } from "../../helpers/mixpanel";
import { AccountType } from "../../helpers/types";
import AccountLoadingCard from "../../elements/AccountLoadingCard/AccountLoadingCard";
import { ModalAccountsList } from "../../elements/Modals/ModalAccountList";
import { getAccountSubType } from "../../helpers/account";
import { PlaidInstitution } from "react-plaid-link";

const DashboardAddAutomation = () => {
  const context = useContext(AuthContext);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const [getAccountsList, accountsListResult] = useLazyGetUserAccountsQuery();
  const [createAutomation, result] = usePostAutomationMutation();
  const [selectCheckingAcc, setSelectCheckingAcc] = useState<any>(null);
  const [selectSavingsAcc, setSelectSavingsAcc] = useState<any>(null);
  const [accountType, setAccountType] = useState({
    type: AccountType.CHECKING,
    clicked: false,
  });

  const [isCheckingWrong, setIsCheckingWrong] = useState<any>(null);
  const [isSavingsWrong, setIsSavingsWrong] = useState<any>(null);

  const [showCheckingAccountsModal, setShowCheckingAccountsModal] =
    useState(false);
  const [showSavingsAccountsModal, setShowSavingsAccountsModal] =
    useState(false);
  const [isPlaidAuthLoading, setIsPlaidAuthLoading] = useState(false);
  const [isPlaidAuthOpen, setIsPlaidAuthOpen] = useState(false);
  const [isCheckingBtnActive, setIsCheckingBtnActive] = useState(false);
  const [isSavingsBtnActive, setIsSavingsBtnActive] = useState(false);
  const [typeAcc, setTypeAcc] = useState("");
  const checkingAccounts: any = useSelector<RootState>(
    (state) => state.accounts.checkingAccountList,
  );
  const savingsAccounts: any = useSelector<RootState>(
    (state) => state.accounts.savingsAccountList,
  );
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accountLoadMsg, setAccountLoadMsg] = useState("");
  const [accountErrorType, setAccountErrorType] = useState("");
  const savingsCardRef = useRef(null);
  const [checkingBalance, setCheckingBalance] = useState<any>(null);
  const [savingsBalance, setSavingsBalance] = useState<any>(null);

  const accountLoadErrorCallback = (errorMsg: string, type: string) => {
    setAccountLoadMsg(errorMsg);
    setAccountErrorType(type);
    setIsPlaidAuthOpen(false);
  };

  // get all User Accounts(savings, checking)
  const getAccounts = async (accountToUpdate?: {
    [field: string]: any | null;
  }) => {
    try {
      const { user } = context;
      if (!user) {
        return;
      } else {
        await user.getIdToken().then(async (idToken: string) => {
          const result = await getAccountsList({ idToken });
          const accountsResult = result?.data?.data;
          if (accountsResult && accountToUpdate) {
            const extendedAccount = accountsResult.find(
              ({ plaidAccountId }: { plaidAccountId: string }) =>
                plaidAccountId === accountToUpdate.plaidAccountId,
            );
            if (extendedAccount.cacheType === AccountType.CHECKING) {
              setSelectCheckingAcc(extendedAccount);
              setCheckingBalance(extendedAccount.availableBalance);
            } else if (extendedAccount.cacheType == AccountType.SAVING) {
              setSelectSavingsAcc(extendedAccount);
              setSavingsBalance(extendedAccount.availableBalance);
            }
          }
        });
      }
    } catch (error) {
      console.log("error in getAccounts DashboardAddAutomation.tsx: ", error);
    }
  };

  const getAccountFromDbByLocalStorageId = async () => {
    const { user } = context;
    if (!user) {
      return;
    } else {
      await user.getIdToken().then(async (idToken: string) => {
        const result = await getAccountsList({ idToken });

        for (let res in result?.data?.data) {
          if (result?.data?.data[res]?.id === selectCheckingAcc?.id) {
            let checkingBalance = result?.data?.data[res].availableBalance;
            setCheckingBalance(JSON.parse(checkingBalance));
          }
          if (result?.data?.data[res]?.id === selectSavingsAcc?.id) {
            let savingsBalance = result?.data?.data[res].availableBalance;
            setSavingsBalance(JSON.parse(savingsBalance));
          }
        }
      });
    }
  };
  useEffect(() => {
    getAccountFromDbByLocalStorageId();
  }, []);

  const handleModalOpen = (type: string) => {
    document.body.classList.remove("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(true);
      mixpanel("Opened_Checking_List");
    } else if (type === AccountType.SAVING) {
      setShowSavingsAccountsModal(true);
      mixpanel("Opened_Savings_List");
    }
    setAccountLoadMsg("");
    setAccountErrorType("");
  };

  const handleModalClose = (type: string) => {
    document.body.classList.add("reset-mui-overflow");
    if (type === AccountType.CHECKING) {
      setShowCheckingAccountsModal(false);
    } else if (type === AccountType.SAVING) {
      setShowSavingsAccountsModal(false);
    }
  };

  const applyDefaultAccounts = async (accounts: any[]) => {
    try {
      if (accounts.length === 0) {
        return null;
      }

      if (typeAcc === AccountType.CHECKING) {
        handleModalClose(AccountType.CHECKING);

        const existsCheckingAccount = accounts.some(
          (account) => account.cacheType === AccountType.CHECKING,
        );

        if (!existsCheckingAccount) {
          setIsCheckingWrong(true);
        }

        const connectedCheckingAccounts = accounts?.filter(
          ({ cacheType }) => cacheType === AccountType.CHECKING,
        );

        if (connectedCheckingAccounts.length > 1) {
          setSelectCheckingAcc(null);
          await getAccounts();
          handleModalOpen(AccountType.CHECKING);
          return;
        }

        if (connectedCheckingAccounts.length === 1) {
          const defaultCheckingAccount = connectedCheckingAccounts[0];
          if (defaultCheckingAccount.cacheType === AccountType.CHECKING) {
            setSelectCheckingAcc(defaultCheckingAccount);
            mixpanel("Connected_checking_account", {
              Banks: defaultCheckingAccount,
            });
            getAccounts(defaultCheckingAccount);
            return;
          } else {
            // show error message for wealth front bank
            if (
              accountType.type == AccountType.CHECKING &&
              accountType.clicked
            ) {
              setIsCheckingWrong(true);
            }
          }
        }
        if (connectedCheckingAccounts.length === 0) {
          getAccounts();
          return;
        }
      } else if (
        typeAcc === "savings" ||
        accounts[0].cacheType === AccountType.SAVING
      ) {
        handleModalClose(AccountType.SAVING);

        const connectedSavingsAccounts = accounts?.filter(
          ({ cacheType }) => cacheType === AccountType.SAVING,
        );

        if (connectedSavingsAccounts.length > 1) {
          setSelectSavingsAcc(null);
          await getAccounts();
          handleModalOpen(AccountType.SAVING);
          return;
        }

        if (connectedSavingsAccounts.length === 1) {
          const defaultSavingsAccount = connectedSavingsAccounts[0];
          setSelectSavingsAcc(defaultSavingsAccount);
          mixpanel("Connected_savings_account", {
            Banks: defaultSavingsAccount,
          });
          getAccounts(defaultSavingsAccount);
          return;
        }
        if (connectedSavingsAccounts.length === 0) {
          getAccounts();
          return;
        }
      }
    } catch (error) {
      console.log("Error in applyDefaultAccounts AddAutomation.tsx: ", error);
    }
  };

  const verifyAccountsSubtype = (
    accounts: any[],
    institution: PlaidInstitution | null,
  ) => {
    if (!Array.isArray(accounts)) {
      return;
    }

    const isAccountTypeMatch = accounts.some((account) => {
      let cacheType = account.subtype;
      if (institution) {
        cacheType = getAccountSubType(
          account.subtype,
          institution?.institution_id,
        );
      }
      return cacheType === typeAcc;
    });

    if (isAccountTypeMatch) {
      return;
    }

    //banner that user didnt connect suitable account
    if (typeAcc === AccountType.CHECKING) {
      setSelectCheckingAcc(null);
      setIsCheckingWrong(true);
      setAccountErrorType(AccountType.CHECKING);
    }
    if (typeAcc === "savings") {
      setSelectSavingsAcc(null);
      setIsSavingsWrong(true);
      setAccountErrorType("savings");
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  //add accounts to redux state
  useEffect(() => {
    if (accountsListResult.data) {
      let checkingAccounts: Array<string> = [];
      let savingsAccounts: Array<string> = [];

      accountsListResult?.data?.data.forEach((item: any) => {
        if (item.cacheType === "savings") {
          savingsAccounts.push(item);
        } else if (item.cacheType === AccountType.CHECKING) {
          checkingAccounts.push(item);
        }
      });
      dispatch(setCheckingAccountList(checkingAccounts));
      dispatch(setSavingsAccountList(savingsAccounts));
    } else {
      return;
    }
  }, [accountsListResult]);

  useEffect(() => {
    if (!selectCheckingAcc || !savingsCardRef.current) {
      return;
    }

    (savingsCardRef.current as any).scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [selectCheckingAcc]);

  useEffect(() => {
    if (
      accountLoadMsg === "STRANGER_ACCOUNT" ||
      accountErrorType === "savings" ||
      accountErrorType === "checking"
    ) {
      handleModalClose(AccountType.CHECKING);
      handleModalClose(AccountType.SAVING);
    }
  }, [accountLoadMsg]);

  const onBackHandler = () => {
    if (!selectCheckingAcc && !selectCheckingAcc) {
      navigation(-1);
      setSelectCheckingAcc(null);
      setSelectSavingsAcc(null);
      setIsCheckingBtnActive(false);
      setIsSavingsBtnActive(false);
    }

    if (selectCheckingAcc && !selectSavingsAcc) {
      localStorage.removeItem("selectCheckingAcc");
      setIsCheckingBtnActive(true);
      setSelectCheckingAcc(null);
      setAccountType({ type: AccountType.CHECKING, clicked: false });
    }

    if (selectCheckingAcc && selectSavingsAcc) {
      localStorage.removeItem("selectSavingsAcc");
      setSelectSavingsAcc(null);
      setValue("");
      setAccountType({ type: AccountType.SAVING, clicked: false });
    }
  };

  const onChangeHandler = (e: any) => {
    const val = e.target.value;
    setValue(val);
    setError(false);
  };

  useEffect(() => {
    const storedSelectCheckingAcc = localStorage.getItem("selectCheckingAcc");
    if (storedSelectCheckingAcc) {
      setSelectCheckingAcc(JSON.parse(storedSelectCheckingAcc));
    }
  }, []);
  const updateSelectCheckingAcc = (newValue: any) => {
    setSelectCheckingAcc(newValue);
    localStorage.setItem("selectCheckingAcc", JSON.stringify(newValue));
  };
  useEffect(() => {
    const storedSelectSavingsAcc = localStorage.getItem("selectSavingsAcc");
    if (storedSelectSavingsAcc) {
      setSelectSavingsAcc(JSON.parse(storedSelectSavingsAcc));
    }
  }, []);

  const updateSelectSavingsAcc = (newValue: any) => {
    setSelectSavingsAcc(newValue);
    localStorage.setItem("selectSavingsAcc", JSON.stringify(newValue));
  };
  useEffect(() => {
    const storedSelectedCheckingBalance =
      localStorage.getItem("checkingBalance");
    if (storedSelectedCheckingBalance) {
      setCheckingBalance(JSON.parse(storedSelectedCheckingBalance));
    }
  }, []);
  const updateCheckingBalance = (newValue: any) => {
    setCheckingBalance(newValue);
    localStorage.setItem("checkingBalance", JSON.stringify(newValue));
  };
  useEffect(() => {
    const storedSelectedSavingsBalance = localStorage.getItem("savingsBalance");
    if (storedSelectedSavingsBalance) {
      setCheckingBalance(JSON.parse(storedSelectedSavingsBalance));
    }
  }, []);

  const updateSavingsBalance = (newValue: any) => {
    setSavingsBalance(newValue);
    localStorage.setItem("savingsBalance", JSON.stringify(newValue));
  };

  useEffect(() => {
    if (selectCheckingAcc?.id) {
      updateSelectCheckingAcc(selectCheckingAcc);
    }
    if (selectSavingsAcc?.id) {
      updateSelectSavingsAcc(selectSavingsAcc);
    }
  }, [selectCheckingAcc?.id, selectSavingsAcc?.id]);

  useEffect(() => {
    if (selectCheckingAcc?.availableBalance) {
      updateCheckingBalance(checkingBalance);
    }
    if (selectSavingsAcc?.availableBalance) {
      updateSavingsBalance(savingsBalance);
    }
  }, [selectCheckingAcc?.availableBalance, selectSavingsAcc?.availableBalance]);

  const onNextHandler = () => {
    localStorage.removeItem("selectCheckingAcc");
    localStorage.removeItem("selectSavingsAcc");
    if (!value) {
      setError(true);
      return;
    }
    const automationData = {
      checkingAccountId: selectCheckingAcc.id,
      checkingAccountPlaidId: selectCheckingAcc.plaidAccountId,
      savingAccountId: selectSavingsAcc.id,
      savingAccountPlaidId: selectSavingsAcc.plaidAccountId,
      fixedValue: +value,
    };
    const { user } = context;
    if (!user) {
      return;
    } else {
      setIsLoading(true);

      user.getIdToken().then(async (idToken: string) => {
        await createAutomation({ body: automationData, idToken }).then(
          (res: any) => {
            if (res?.data?.status == "SUCCESS") {
              notification({
                message: "Automation set successfully.",
                type: "success",
              });
              setSelectCheckingAcc(null);
              setSelectSavingsAcc(null);
              setValue("");
              navigation("/automations");
              // dispatch(setSavedAutomation(true));
            } else {
              setSelectCheckingAcc(null);
              setSelectSavingsAcc(null);
              setValue("");
              navigation("/automations");
            }
            setIsLoading(false);
          },
        );
      });
    }
  };

  const onPlaidAuthSuccess = (justConnectedAccounts: any[]) => {
    applyDefaultAccounts(justConnectedAccounts);
    setIsPlaidAuthOpen(false);
  };

  const onPlaidAuthExit = () => {
    setIsPlaidAuthOpen(false);
  };

  const SelectAccText = (
    <>
      <div className="page__title custom_mobile">
        Connect your preferred checking account.
      </div>
      <div className="page__subtitle automation__description">
        Cache automatically transfers excess funds from this account to your
        savings, and back when needed.
      </div>
    </>
  );

  const ConnectSavingsAccText = (
    <>
      <div className="page__title">Connect your preferred savings account.</div>
      <div className="page__subtitle automation__description">
        Cache automatically transfers excess funds from this account to your
        savings, and back when needed.
      </div>
    </>
  );

  const FixedBalanceText = (
    <>
      <div className="page__title">Set your fixed balance.</div>
      <div className="page__subtitle automation__description">
        Determine the balance that you’d like to maintain in your checking
        account. Cache will use it to automatically transfer funds from your
        checking to your savings and vice versa.
      </div>
    </>
  );

  const isAccountListResultLoading = accountsListResult.isFetching;

  const isCheckingCardClicked =
    accountType.type === AccountType.CHECKING && accountType.clicked;
  const isSavingCardClicked =
    accountType.type === AccountType.SAVING && accountType.clicked;

  return (
    <>
      <HeaderMenu />
      <div className="container">
        <div className="backButton">
          <BackButton onBack={onBackHandler} />
        </div>

        <div className="page__infoContainer">
          {!selectCheckingAcc && !selectSavingsAcc && SelectAccText}
          {selectCheckingAcc && !selectSavingsAcc && ConnectSavingsAccText}
          {selectCheckingAcc && selectSavingsAcc && FixedBalanceText}

          <div className="automation__container">
            <div className="connectionButton__holder">
              <div className="automations__checkingCards">
                {isAccountListResultLoading || isPlaidAuthLoading ? (
                  <AccountLoadingCard />
                ) : selectCheckingAcc ? (
                  <AccountCard
                    active={isCheckingBtnActive}
                    errorCard={
                      accountErrorType === AccountType.CHECKING &&
                      isCheckingCardClicked
                    }
                    item={selectCheckingAcc}
                    type="Checking account"
                    selectHandler={() => {
                      handleModalOpen(AccountType.CHECKING);
                      setTypeAcc(AccountType.CHECKING);
                    }}
                  />
                ) : (
                  <ConnectionButton
                    accountType={"Checking Account"}
                    isActive={true}
                    errorCard={
                      accountErrorType === AccountType.CHECKING &&
                      isCheckingCardClicked
                    }
                    isWrongChoice={isCheckingWrong}
                    onClickHandler={() => {
                      setAccountLoadMsg("");
                      setAccountErrorType("");
                      setAccountType({
                        type: AccountType.CHECKING,
                        clicked: true,
                      });
                      setIsCheckingWrong(false);
                      setTypeAcc(AccountType.CHECKING);
                      if (checkingAccounts.length === 0) {
                        setIsPlaidAuthOpen(true);
                      } else {
                        handleModalOpen(AccountType.CHECKING);
                      }
                    }}
                  />
                )}
                {isCheckingWrong && isCheckingCardClicked && (
                  <div className="wrong__choise-hide text__content">
                    The account that you selected is not a checking account.
                    Please try again. If necessary, connect to another
                    institution.
                  </div>
                )}
                {accountLoadMsg === "STRANGER_ACCOUNT" &&
                  accountErrorType === AccountType.CHECKING &&
                  isCheckingCardClicked && (
                    <div className="userName__errorMassage error__message account-exist mobile-error">
                      The selected account is already associated with another
                      Cache user. Please select a different account to continue.
                    </div>
                  )}
                <div className="balanceInput__holder">
                  <BalanceInput
                    error={error}
                    isdisabled={!(selectCheckingAcc && selectSavingsAcc)}
                    value={value}
                    onChangeHandler={onChangeHandler}
                  />
                </div>
                {error && (
                  <div className="userName__errorMassage error__message">
                    Please set your fixed balance.
                  </div>
                )}
              </div>

              <div className="arrows__icon">
                <img src={arrows} alt="arrows icon" />
              </div>

              <div className="automations__savingsCards" ref={savingsCardRef}>
                {isAccountListResultLoading || isPlaidAuthLoading ? (
                  <AccountLoadingCard />
                ) : selectSavingsAcc ? (
                  <>
                    <AccountCard
                      active={isSavingsBtnActive}
                      errorCard={
                        accountErrorType === "savings" && isSavingCardClicked
                      }
                      item={selectSavingsAcc}
                      type="Savings account"
                      selectHandler={() => {
                        setIsSavingsWrong(false);
                        handleModalOpen(AccountType.SAVING);
                        setTypeAcc("savings");
                      }}
                    />
                  </>
                ) : (
                  <ConnectionButton
                    accountType={"Savings Account"}
                    isActive={selectCheckingAcc}
                    errorCard={
                      accountErrorType === "savings" && isSavingCardClicked
                    }
                    isWrongChoice={false}
                    onClickHandler={() => {
                      setAccountType({
                        type: AccountType.SAVING,
                        clicked: true,
                      });
                      setAccountLoadMsg("");
                      setAccountErrorType("");
                      setIsSavingsWrong(false);
                      setTypeAcc("savings");
                      if (savingsAccounts.length === 0) {
                        setIsPlaidAuthOpen(true);
                      } else {
                        handleModalOpen(AccountType.SAVING);
                      }
                    }}
                  />
                )}
                {isSavingsWrong && isSavingCardClicked && (
                  <div className="wrong__choise-hide text__content">
                    The account that you selected is not a checking account.
                    Please try again. If necessary, connect to another
                    institution.
                  </div>
                )}
                {accountLoadMsg === "STRANGER_ACCOUNT" &&
                  accountErrorType === "savings" &&
                  isSavingCardClicked && (
                    <div className="userName__errorMassage error__message account-exist mobile-error">
                      The selected account is already associated with another
                      Cache user. Please select a different account to continue.
                    </div>
                  )}
              </div>
            </div>
            {isCheckingWrong && isCheckingCardClicked && (
              <div className="wrong__choise text__content">
                The account that you selected is not a checking account. Please
                try again. If necessary, connect to another institution.
              </div>
            )}
            {isSavingsWrong && isSavingCardClicked && (
              <div className="wrong__choise text__content">
                The account that you selected is not a savings account. Please
                try again. If necessary, connect to another institution.
              </div>
            )}
            {accountLoadMsg === "STRANGER_ACCOUNT" &&
              [AccountType.CHECKING, "savings"].includes(accountErrorType) && (
                <div className="userName__errorMassage error__message account-exist desktop-error">
                  The selected account is already associated with another Cache
                  user. Please select a different account to continue.
                </div>
              )}
          </div>
        </div>

        {selectCheckingAcc && selectSavingsAcc && (
          <div className="btn-holder">
            <Button
              disabled={isLoading}
              onClick={onNextHandler}
              type={"primary-btn"}
            >
              Set Automation
            </Button>
          </div>
        )}

        {showCheckingAccountsModal && (
          <ModalAccountsList
            title="Checking Account"
            accountsList={checkingAccounts}
            open={showCheckingAccountsModal && !isAccountListResultLoading}
            relationType={AccountType.CHECKING}
            onClose={() => {
              handleModalClose(AccountType.CHECKING);
              setIsCheckingBtnActive(false);
              setIsSavingsBtnActive(true);
            }}
            setAccount={setSelectCheckingAcc}
            onPlaidAuthSuccess={onPlaidAuthSuccess}
            setIsPlaidAuthLoading={setIsPlaidAuthLoading}
            verifyAccountsSubtype={verifyAccountsSubtype}
          />
        )}
        {showSavingsAccountsModal && (
          <ModalAccountsList
            title="Savings Account"
            accountsList={savingsAccounts}
            open={showSavingsAccountsModal && !isAccountListResultLoading}
            relationType="savings"
            onClose={() => {
              handleModalClose(AccountType.SAVING);
              setIsSavingsBtnActive(false);
            }}
            setAccount={setSelectSavingsAcc}
            onPlaidAuthSuccess={onPlaidAuthSuccess}
            setIsPlaidAuthLoading={setIsPlaidAuthLoading}
            verifyAccountsSubtype={verifyAccountsSubtype}
          />
        )}
      </div>
      <PlaidAuth
        isOpen={isPlaidAuthOpen}
        success={onPlaidAuthSuccess}
        onExit={onPlaidAuthExit}
        withoutMarkup={true}
        setIsLoading={setIsPlaidAuthLoading}
        accountLoadErrorCallback={accountLoadErrorCallback}
        preConnectedAccountsCB={verifyAccountsSubtype}
        relationType={typeAcc}
      />
    </>
  );
};

export default DashboardAddAutomation;
